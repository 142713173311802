import { DOCUMENT_URLS, USERS_URLS } from '../../utils/api-urls';
import { BaseQueryObject } from '../../models/ApiTypes';
import { FileInfo } from '../../models/FileUtils';
import { createEmptyRequest, AjaxService, IAPIRequest, IAPIResponse } from '../internal/ajax-service';

const DocumentController = {
    getPdf: async (spudletterId: string, callback?: (response: any) => void) => {
        let request: IAPIRequest = createEmptyRequest();
        request.url = DOCUMENT_URLS.MAINFILE_PREVIEW.replace('{spudletterId}', spudletterId);
        request.method = 'GET';
        let response: IAPIResponse = await AjaxService.call(request);
        if (callback) {
            callback(response);
        }
        return response;
    },
    uploadAttachments: async (wellCode: string, files: File[], callback?: (response: IAPIResponse) => void) => {
        let formData = new FormData();
        formData.append('wellCode', wellCode);
        for (let i = 0; i < files.length; i++) {
            formData.append('document_' + i, files[i], files[i].name);
        }

        let request: IAPIRequest = createEmptyRequest();
        request.url = DOCUMENT_URLS.ADD_ATTACHMENT.replace('{wellCode}', wellCode);
        request.method = 'POST';
        request.payload = formData;
        request.ignoreDefaultHeaders = true;
        request.dontStringify = true;

        let response: IAPIResponse = await AjaxService.call(request);
        if (callback) {
            callback(response);
        }
        return response;
    },

    deleteAttachment: async (wellCode: string, fileId: string, callback?: (response: IAPIResponse) => void) => {
        let request: IAPIRequest = createEmptyRequest();
        request.method = 'DELETE';
        request.url = DOCUMENT_URLS.ATTACHMENT.replace('{wellCode}', wellCode).replace('{attachmentId}', fileId);
        let response: IAPIResponse = await AjaxService.call(request);
        if (callback) {
            callback(response);
        }
        return response;
    },
};

export default DocumentController;

import { Checkbox, Stack } from '@fluentui/react';
import { useState } from 'react';
import { DetailState } from '../../../app/routes/detail';
import ModalDialog from '../../../components/modal-dialog/modal-dialog';
import { WorkflowSigner } from '../../../models/SpudLetterDto';
import { CommonModalButtons } from '../../../utils/ModalUtils';
import { SignerWithLabel } from './signer-with-label';

type ApprovalWorkflowModalModalProps = {
    detailState: DetailState;
    explorationManager: WorkflowSigner;
    explorationManagerDeputy: WorkflowSigner;
    projectManager: WorkflowSigner;
    projectManagerDeputy: WorkflowSigner;
    enableModal: boolean;
    closeModal: () => void;
    send: () => void;
};

export const ApprovalWorkflowModal = ({
    detailState,
    explorationManager,
    explorationManagerDeputy,
    projectManager,
    projectManagerDeputy,
    enableModal,
    closeModal,
    send,
}: ApprovalWorkflowModalModalProps) => {
    const [isChecked, setIsChecked] = useState(false);

    let message =
        detailState.ownership === 'E'
            ? 'The Exploration Manager will have to review the Spud Letter'
            : 'The Approver will have to review the Spud Letter';

    let checkbox = (
        <Checkbox
            label="I confirm all available attachments have been uploaded"
            onChange={(_event, checked) => {
                setIsChecked(checked);
            }}
            styles={{ root: { paddingTop: '1rem' } }}
        />
    );

    let content =
        detailState.ownership === 'E' ? (
            <Stack>
                <SignerWithLabel label={'Exploration Manager'} signer={explorationManager} />
                <SignerWithLabel label={'Exploration Manager Deputy'} signer={explorationManagerDeputy} />
                {checkbox}
            </Stack>
        ) : (
            <Stack>
                <SignerWithLabel label={'Project Manager'} signer={projectManager} />
                <SignerWithLabel label={'Project manager Deputy'} signer={projectManagerDeputy} />
                {checkbox}
            </Stack>
        );

    const buttons = CommonModalButtons.confirmCancelButtons(send, closeModal, !isChecked);
    const onAbort = closeModal;

    return (
        <ModalDialog
            modalTitle="Send Spud Letter for Approval"
            modalMessage={message}
            modalInnerComponent={content}
            enableModal={enableModal}
            modalButtons={buttons}
            onAbort={onAbort}
        />
    );
};

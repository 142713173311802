/**
 * enum for the Status of the Spud Letter
 */
export enum SpudLetterStatus {
    Draft,
    Review,
    ReviewedDraft,
    Pending,
    Signed,
}

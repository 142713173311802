import { api } from '@/lib/api-client';
import { useQuery } from '@tanstack/react-query';
import { QueryConfig } from '../../../lib/react-query';
import { EmailLog } from '../types/email-log';

export const getEmailLogs = async (): Promise<EmailLog[]> => {
    return api.get('/logs/email');
};

export const getEmailLogsQueryOptions = () => {
    return {
        queryKey: ['email-logs'],
        queryFn: () => getEmailLogs(),
    };
};

type UseEmailLogsOptions = {
    queryConfig?: QueryConfig<typeof getEmailLogsQueryOptions>;
};

export const useEmailLogs = ({ queryConfig }: UseEmailLogsOptions = {}) => {
    return useQuery({
        ...getEmailLogsQueryOptions(),
        ...queryConfig,
    });
};

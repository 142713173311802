import { GenericActionType, SET_INPUT_INFOS } from './generic-action';
import { DEFAULT_GENERIC_STATE, GenericState } from './generic-state';

export const genericReducer = (
    state: GenericState = DEFAULT_GENERIC_STATE,
    action: GenericActionType,
): GenericState => {
    switch (action.type) {
        case SET_INPUT_INFOS:
            return {
                ...state,
                inputInfos: action.payload,
            };
        default:
            return state;
    }
};

import { MailRecipient } from '../../models/MailRecipient';
import { RECIPIENT_URLS } from '../../utils/api-urls';
import { AjaxService, createEmptyRequest, IAPIRequest, IAPIResponse } from '../internal/ajax-service';

const RecipientController = {
    getRecipients: async (callback?: (response: any) => void) => {
        let request: IAPIRequest = createEmptyRequest();
        request.url = RECIPIENT_URLS.GET_RECIPIENTS;
        let response: IAPIResponse = await AjaxService.call(request);
        if (callback) {
            callback(response);
        }
        return response;
    },
    getCountryOwnershipRecipients: async (
        countryCode: string,
        ownership: string,
        callback?: (response: any) => void,
    ) => {
        let request: IAPIRequest = createEmptyRequest();
        request.url = RECIPIENT_URLS.GET_COUNTRY_OWNERSHIP_RECIPIENTS.replace('{countryCode}', countryCode).replace(
            '{ownership}',
            ownership,
        );
        let response: IAPIResponse = await AjaxService.call(request);
        if (callback) {
            callback(response);
        }
        return response;
    },
    insertRecipient: async (recipient: MailRecipient, callback?: (response: any) => void) => {
        let request: IAPIRequest = createEmptyRequest();
        request.url = RECIPIENT_URLS.RECIPIENT;
        request.method = 'POST';
        request.payload = recipient;
        let response: IAPIResponse = await AjaxService.call(request);
        if (callback) {
            callback(response);
        }
        return response;
    },
    deleteRecipient: async (recipientId: string, callback?: (response: any) => void) => {
        let request: IAPIRequest = createEmptyRequest();
        request.url = RECIPIENT_URLS.DELETE_RECIPIENT.replace('{recipientId}', recipientId);
        request.method = 'DELETE';
        let response: IAPIResponse = await AjaxService.call(request);
        if (callback) {
            callback(response);
        }
        return response;
    },
    updateRecipient: async (recipient: MailRecipient, callback?: (response: any) => void) => {
        let request: IAPIRequest = createEmptyRequest();
        request.url = RECIPIENT_URLS.RECIPIENT;
        request.method = 'PUT';
        request.payload = recipient;
        let response: IAPIResponse = await AjaxService.call(request);
        if (callback) {
            callback(response);
        }
        return response;
    },
};

export default RecipientController;

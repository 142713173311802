import { AuthenticationHelper, SharedState } from '@Eni/docware-fe-master';

export interface IAPIRequest {
    url: string;
    method: string | null;
    payload: any | null;
    extraHeaders: any | null;
    globalSpinner: boolean | null;
    ignoreDefaultHeaders: boolean | null;
    successMessage: string | null;
    silentLevel: number;
    dontStringify: boolean | null;
    spinnerLabel: string | null;
    skipResponseJsonParse: boolean;
    alternative_base_url: string | null;
}

export interface IAPIResponse {
    error: string | null;
    payload: any | null;
    raw: any | null;
}

export interface ICustomBehaviorCallbacks {
    getSessionToken: Function;
    preRequestScript: Function;
    errorDetectionFunction: Function;
    payloadOutFormat: Function;
    payloadInFormat: Function;
}

export interface IAPISettings {
    generalErrorText: string;
    spinnerColor: string;
    baseUrl: string;
    spinnerGlobalStyle: string;
    defaultSpinnerLabel: string;
    maxToastMessageDuration: number;
    autoLogoutTest: Function;
    defaultApiParams: any;
    defaultHeaders: any;
    requiredHeaders: () => any;
    customCallBacks: ICustomBehaviorCallbacks;
    developerMode: boolean;
    errorToastDuration: number;
    useExternalErrorToasts: boolean;
}

/**
 * ###############################################################################
 * ###############################################################################
 * ###############################################################################
 * ###############################################################################
 *
 * Generate request utility function
 *
 * ###############################################################################
 * ###############################################################################
 * ###############################################################################
 * ###############################################################################
 */

export const silentLevels = {
    NO_UI_INTERACTIONS: 0,
    ALLOW_TOASTS: 1,
    ALLOW_TOASTS_AND_SPINNER: 2,
};

export const createEmptyRequest = () => {
    let defaultRequest: IAPIRequest = {
        url: '',
        method: 'get',
        payload: null,
        extraHeaders: null,
        globalSpinner: true,
        ignoreDefaultHeaders: false,
        successMessage: null,
        silentLevel: silentLevels.ALLOW_TOASTS,
        dontStringify: false,
        spinnerLabel: null,
        skipResponseJsonParse: false,
        alternative_base_url: null,
    };

    return defaultRequest;
};

/**
 * ###############################################################################
 * ###############################################################################
 * ###############################################################################
 * ###############################################################################
 *
 * Generic settings:
 * The following code holds generic settings needed to setup the API system.
 *
 * ###############################################################################
 * ###############################################################################
 * ###############################################################################
 * ###############################################################################
 */
// some basic settings

var SUPPRESS_ALL_ERROR_TOASTS = process.env.REACT_APP_ALLOW_ERROR_TOASTS === 'true';

export const APISettings: IAPISettings = {
    developerMode: window.location.href.indexOf('localhost') !== -1,
    generalErrorText: 'Something went wrong.',
    spinnerColor: '#3b84aa',
    errorToastDuration: 15000, // duration in milliseconds of error toasts (use -1 to enable dynamic duration based on the message text length)
    baseUrl: process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : '.',
    spinnerGlobalStyle: 'solid-dual',
    defaultSpinnerLabel: 'Loading',
    maxToastMessageDuration: 5000,
    useExternalErrorToasts: true,
    autoLogoutTest: (apiResponse: IAPIResponse) => {
        if (apiResponse.error === null && apiResponse.raw?.status === 401) {
            SUPPRESS_ALL_ERROR_TOASTS = true;

            console.debug('[auth][autoLogoutTest] Trying to login');
            AuthenticationHelper.clearSession();

            AuthenticationHelper.startLoginRoutine();
        }
    },
    defaultApiParams: {
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'include', // include, *same-origin, omit
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    },
    requiredHeaders: () => {
        let headers: any = {};
        let u: any = localStorage.getItem('logged-user');
        if (u != null) {
            headers['user_id'] = JSON.parse(u).id;
        }

        let tokenH = SharedState.authStatus.value.accessToken;
        if (tokenH) {
            // headers["accesstoken"] = tokenH;
            headers['Authorization'] = 'Bearer ' + tokenH;
        }

        return headers;
    },
    defaultHeaders: {
        'Content-Type': 'application/json',
    },
    customCallBacks: {
        getSessionToken: () => {
            return null;
        },
        preRequestScript: (request: IAPIRequest) => {},
        errorDetectionFunction: (response: IAPIResponse) => {
            if (response.payload) {
                if (response.payload.status) {
                    if (response.payload.status >= 300) {
                        return response.payload.title + (response.payload.detail ? ': ' + response.payload.detail : '');
                    }
                }
            }
            return null;
        },
        payloadOutFormat: (payload: any) => {
            return payload;
        },
        payloadInFormat: (payload: any) => {
            return payload;
        },
    },
};

/**
 * ###############################################################################
 * ###############################################################################
 * ###############################################################################
 * ###############################################################################
 *
 * Core code:
 * The following code should not be edited and is valid in general for any project.
 *
 * ###############################################################################
 * ###############################################################################
 * ###############################################################################
 * ###############################################################################
 */

// exported methods
export const AjaxService = {
    toggleSpinner, // turn loaging spinner on off
    call, // call Api inside controlled api flow
    downloadBase64File, // download file
    downloadFileFromUrl, // download from link
    openToastSuccess, // open toast: green
    openToastWarning, // open toast: yellow
    openToastError, // open toast: red
    getPopUp, // open a popup
    getMobileToast, // open a small toast (similar to an android toast)
};

function downloadFileFromUrl(url: string) {
    let link = document.createElement('a');
    link.href = url;
    link.target = '_blank';
    link.click();
}

function downloadBase64File(fileName: string, base64: string) {
    let type = null;
    let splName = fileName.split('.');
    let extension = '.' + splName[splName.length - 1].toLowerCase();

    let contentTypes = [
        { ext: '.doc', cType: 'application/msword' },
        { ext: '.dot ', cType: 'application/msword' },
        {
            ext: '.docx',
            cType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        },
        {
            ext: '.dotx',
            cType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
        },
        { ext: '.docm', cType: 'application/vnd.ms-word.document.macroEnabled.12' },
        { ext: '.dotm', cType: 'application/vnd.ms-word.template.macroEnabled.12' },
        { ext: '.xls ', cType: 'application/vnd.ms-excel' },
        { ext: '.xlt ', cType: 'application/vnd.ms-excel' },
        { ext: '.xla ', cType: 'application/vnd.ms-excel' },
        {
            ext: '.xlsx',
            cType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        },
        {
            ext: '.xltx',
            cType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
        },
        { ext: '.xlsm', cType: 'application/vnd.ms-excel.sheet.macroEnabled.12' },
        {
            ext: '.xltm',
            cType: 'application/vnd.ms-excel.template.macroEnabled.12',
        },
        { ext: '.xlam', cType: 'application/vnd.ms-excel.addin.macroEnabled.12' },
        {
            ext: '.xlsb',
            cType: 'application/vnd.ms-excel.sheet.binary.macroEnabled.12',
        },
        { ext: '.ppt ', cType: 'application/vnd.ms-powerpoint' },
        { ext: '.pot ', cType: 'application/vnd.ms-powerpoint' },
        { ext: '.pps ', cType: 'application/vnd.ms-powerpoint' },
        { ext: '.ppa ', cType: 'application/vnd.ms-powerpoint' },
        {
            ext: '.pptx',
            cType: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        },
        {
            ext: '.potx',
            cType: 'application/vnd.openxmlformats-officedocument.presentationml.template',
        },
        {
            ext: '.ppsx',
            cType: 'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
        },
        {
            ext: '.ppam',
            cType: 'application/vnd.ms-powerpoint.addin.macroEnabled.12',
        },
        {
            ext: '.pptm',
            cType: 'application/vnd.ms-powerpoint.presentation.macroEnabled.12',
        },
        {
            ext: '.potm',
            cType: 'application/vnd.ms-powerpoint.template.macroEnabled.12',
        },
        {
            ext: '.ppsm',
            cType: 'application/vnd.ms-powerpoint.slideshow.macroEnabled.12',
        },
        { ext: '.mdb ', cType: 'application/vnd.ms-access' },
        { ext: '.pdf ', cType: 'application/pdf' },
        { ext: '.csv ', cType: 'text/csv' },
        { ext: '.mp4', cType: 'video/mp4' },
        { ext: '.ogg', cType: 'video/ogg' },
        { ext: '.webm', cType: 'video/webm' },
        { ext: '.png', cType: 'image/png' },
        { ext: '.bmp', cType: 'image/bmp' },
        { ext: '.jpg', cType: 'image/jpg' },
    ];

    for (let i = 0; i < contentTypes.length; i++) {
        let t = contentTypes[i];
        if (t.ext === extension) {
            type = t.cType;
            break;
        }
    }

    if (type == null) {
        type = 'application/octet-stream';
    }

    let base64ToArrayBuffer = function (base64: string) {
        let binaryString = window.atob(base64);
        let binaryLen = binaryString.length;
        let bytes = new Uint8Array(binaryLen);
        for (let i = 0; i < binaryLen; i++) {
            let ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }
        return bytes;
    };

    let saveByteArray = function (fileName: string, byte: Uint8Array, type: string) {
        let blob = new Blob([byte], { type: type });
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
    };

    let byteArray = base64ToArrayBuffer(base64);
    saveByteArray(fileName, byteArray, type);
}

async function call(apiRequest: IAPIRequest) {
    // is a post, so try get the AF Token
    // if (apiRequest.method.toLocaleLowerCase() === 'post') {
    //   let AFRequest: IAPIRequest = createEmptyRequest();
    //   AFRequest.url = '/AntiForgery/getToken';
    //   let response: IAPIResponse = await callInner(AFRequest);
    //   let AFToken = null;
    //   if (response.error === null) {
    //     AFToken = response.payload.token;
    //   }

    //   // add the token to the request as header
    //   if (AFToken) {
    //     if (!apiRequest.extraHeaders) {
    //       apiRequest.extraHeaders = {};
    //     }
    //     apiRequest.extraHeaders['af_token'] = AFToken;
    //   }
    // }

    return await callInner(apiRequest);
}

async function callInner(apiRequest: IAPIRequest) {
    if (APISettings.customCallBacks.preRequestScript(apiRequest)) {
        return {
            raw: null,
            payload: null,
            error: 'Pre request script blocked this call: ' + JSON.stringify(apiRequest),
        };
    }

    if (apiRequest.globalSpinner === true && apiRequest.silentLevel === silentLevels.ALLOW_TOASTS_AND_SPINNER) {
        toggleSpinner(true, apiRequest.spinnerLabel);
    }

    /** obtain full url */
    apiRequest.url =
        apiRequest.alternative_base_url === null
            ? APISettings.baseUrl + apiRequest.url
            : apiRequest.alternative_base_url + apiRequest.url;

    /** custom format payload (if necessary) */
    apiRequest.payload = APISettings.customCallBacks.payloadOutFormat(apiRequest.payload);

    let response = await doFetch(apiRequest);

    if (apiRequest.globalSpinner === true && apiRequest.silentLevel === silentLevels.ALLOW_TOASTS_AND_SPINNER) {
        toggleSpinner(false);
    }

    if (response != null) {
        if (response.raw.status >= 300 || response.raw.status < 200) {
            response['error'] = extractError(response);
        } else {
            response['error'] = APISettings.customCallBacks.errorDetectionFunction(response);
        }

        if (apiRequest.silentLevel !== silentLevels.NO_UI_INTERACTIONS) {
            if (response['error'] != null) {
                if (!SUPPRESS_ALL_ERROR_TOASTS) {
                    openToastError(response['error']);
                } else {
                    console.error(response['error']);
                }
            }

            if (response['error'] == null && apiRequest.successMessage) {
                openToastSuccess(apiRequest.successMessage);
            }
        }
    }

    return response;
}

async function doFetch(apiRequest: IAPIRequest) {
    // try get any session token (jwt, b2c, etc... )
    let token = APISettings.customCallBacks.getSessionToken();

    // Default params for fetch call
    const params = JSON.parse(JSON.stringify(APISettings.defaultApiParams));

    // set method (get is default )
    params['method'] = apiRequest.method ? apiRequest.method : 'get';

    // add custom headers
    params['headers'] = obtainHeaders(apiRequest);

    if (token != null) {
        params['headers']['Authorization'] = 'Bearer ' + token;
    }

    if (apiRequest.payload != null) {
        if (apiRequest.dontStringify === true) {
            params['body'] = apiRequest.payload;
        } else {
            params['body'] = JSON.stringify(apiRequest.payload); // body data type must match "Content-Type" header
        }
    }

    let response: any = null;

    try {
        response = await fetch(apiRequest.url, params);
    } catch (e: any) {
        response = { status: -1, message: e.message };
    }

    let value: any = null;
    let error: string | null = null;

    if ([204].includes(response.status) === false) {
        if (!apiRequest.skipResponseJsonParse) {
            try {
                value = await response.json();
            } catch (e: any) {
                console.error('Could not parse response', e.message);
            }
        } else {
            value = await response.text();
        }
    } else {
        error = 'Invalid status code';
    }

    let output: IAPIResponse = {
        raw: response,
        payload: value,
        error: error,
    };

    APISettings.autoLogoutTest(output);

    output.payload = APISettings.customCallBacks.payloadInFormat(output.payload);

    console.log('Api call result', apiRequest.url, output);

    return output;
}

function obtainHeaders(apiRequest: IAPIRequest) {
    let headers = { ...APISettings.defaultHeaders };

    if (apiRequest.ignoreDefaultHeaders === true) {
        headers = {};
    }

    //** required headers cannot be removed */
    headers = { ...headers, ...APISettings.requiredHeaders() };

    if (apiRequest.extraHeaders != null) {
        let keys = Object.keys(apiRequest.extraHeaders);
        for (let i = 0; i < keys.length; i++) {
            let key = keys[i];
            if (apiRequest.extraHeaders.hasOwnProperty(key)) {
                headers[key] = apiRequest.extraHeaders[key];
            }
        }
    }

    return headers;
}

function extractError(response: IAPIResponse) {
    /** first attempt to get the error is taken by the custom error */
    let error = APISettings.customCallBacks.errorDetectionFunction(response);

    /** second attempt from the standard http error statusText voice */
    if (response.raw && response.raw.statusText && error == null) {
        error = response.raw.statusText;
    }

    /** third attempt from the standard http error message voice */
    if (response.raw && response.raw.message && error == null) {
        error = response.raw.message;
    }

    return error ?? APISettings.generalErrorText;
}

function injectSpinningStyle() {
    if (document.getElementById('spinner_style_node') != null) {
        return;
    }

    let css =
        '@keyframes spin {    from {        transform: rotate(0deg);    }    to {        transform: rotate(360deg);    }}@-webkit-keyframes spin {    from {        -webkit-transform: rotate(0deg);    }    to {        -webkit-transform: rotate(360deg);    }}@-moz-keyframes spin {    from {        -moz-transform: rotate(0deg);    }    to {        -moz-transform: rotate(360deg);    }}@-ms-keyframes spin {    from {        -ms-transform: rotate(0deg);    }    to {        -ms-transform: rotate(360deg);    }}';
    let head = document.head || document.getElementsByTagName('head')[0];
    let style: any = document.createElement('style');

    head.appendChild(style);
    style.type = 'text/css';
    style.id = 'spinner_style_node';

    if (style.styleSheet) {
        // This is required for IE8 and below.
        style.styleSheet.cssText = css;
    } else {
        style.appendChild(document.createTextNode(css));
    }
}

function addSpinnerLabelText(spinnerLabel: string | null, target: any) {
    if (spinnerLabel != null) {
        let oldLabel = document.getElementById('spinner-label-text');
        if (oldLabel != null) {
            oldLabel.innerHTML = spinnerLabel + '...';
        } else {
            let label = document.createElement('div');
            label.innerHTML = spinnerLabel + '...';
            label.id = 'spinner-label-text';
            label.style.position = 'absolute';
            label.style.marginTop = '15em';
            // label.style.border = '1px solid rgba(0, 0, 0, 0.2)';
            label.style.padding = '0 1em';
            label.style.background = 'white';
            target.appendChild(label);
        }
    }
}

function setSpinnerStyle(style: string, spinner: any) {
    if (style === 'basic') {
        spinner.style.borderWidth = '0.5em';
        spinner.style.borderStyle = 'solid';
        spinner.style.borderColor = APISettings.spinnerColor + ' transparent transparent';
        spinner.style.width = 'min(30vw, 10em)';
        spinner.style.height = 'min(30vw, 10em)';
        spinner.style.borderRadius = '50%';
        spinner.style.animation = 'spin 0.5s linear infinite';
        return;
    }
    if (style === 'solid-dual') {
        spinner.style.borderWidth = '0.5em';
        spinner.style.borderStyle = 'solid';
        spinner.style.borderColor =
            APISettings.spinnerColor +
            '22 ' +
            APISettings.spinnerColor +
            '22 ' +
            APISettings.spinnerColor +
            ' ' +
            APISettings.spinnerColor +
            '22 ';
        spinner.style.width = 'min(30vw, 10em)';
        spinner.style.height = 'min(30vw, 10em)';
        spinner.style.borderRadius = '50%';
        spinner.style.animation = 'spin 0.5s linear infinite';
        return;
    }
    if (style === 'solid-fade') {
        spinner.style.borderWidth = '0.5em';
        spinner.style.borderStyle = 'solid';
        spinner.style.borderColor =
            APISettings.spinnerColor +
            '66 ' +
            APISettings.spinnerColor +
            'AA ' +
            APISettings.spinnerColor +
            ' transparent';
        spinner.style.width = 'min(30vw, 10em)';
        spinner.style.height = 'min(30vw, 10em)';
        spinner.style.borderRadius = '50%';
        spinner.style.animation = 'spin 0.5s linear infinite';
        return;
    }
    if (style === 'dashed-fade') {
        spinner.style.borderWidth = '0.5em';
        spinner.style.borderStyle = 'dashed';
        spinner.style.borderColor =
            APISettings.spinnerColor +
            '66 ' +
            APISettings.spinnerColor +
            'AA ' +
            APISettings.spinnerColor +
            ' transparent';
        spinner.style.width = 'min(30vw, 10em)';
        spinner.style.height = 'min(30vw, 10em)';
        spinner.style.borderRadius = '50%';
        spinner.style.animation = 'spin 0.5s linear infinite';
        return;
    }
    if (style === 'dotted-fade') {
        spinner.style.borderWidth = '0.5em';
        spinner.style.borderStyle = 'dotted';
        spinner.style.borderColor =
            APISettings.spinnerColor +
            '66 ' +
            APISettings.spinnerColor +
            'AA ' +
            APISettings.spinnerColor +
            ' transparent';
        spinner.style.width = 'min(30vw, 10em)';
        spinner.style.height = 'min(30vw, 10em)';
        spinner.style.borderRadius = '50%';
        spinner.style.animation = 'spin 0.5s linear infinite';
        return;
    }
}

let aboutToRemoveSpinner = false;
function toggleSpinner(toggle: boolean, spinnerLabel: string | null = null, removeInstantly: boolean = false) {
    if (spinnerLabel == null && document.getElementById('spinner-label-text') == null) {
        spinnerLabel = APISettings.defaultSpinnerLabel;
    }

    injectSpinningStyle();

    if (toggle) {
        aboutToRemoveSpinner = false;
        let oldWrap = document.getElementById('spinner-wrap');
        if (oldWrap == null) {
            let wrapper = document.createElement('div');
            let spinner = document.createElement('div');

            wrapper.id = 'spinner-wrap';

            setSpinnerStyle(APISettings.spinnerGlobalStyle, spinner);

            wrapper.style.position = 'fixed';
            wrapper.style.display = 'flex';
            wrapper.style.alignItems = 'center';
            wrapper.style.justifyContent = 'center';
            wrapper.style.top = '0';
            wrapper.style.left = '0';
            wrapper.style.width = '100vw';
            wrapper.style.height = '100vh';
            wrapper.style.zIndex = '4000';
            wrapper.style.background = 'rgba(255,255,255,0.7)';

            wrapper.appendChild(spinner);

            addSpinnerLabelText(spinnerLabel, wrapper);

            document.body.appendChild(wrapper);
        } else {
            addSpinnerLabelText(spinnerLabel, oldWrap);
        }
    } else {
        aboutToRemoveSpinner = true;

        let timeout = removeInstantly ? 1 : 500;

        setTimeout(() => {
            if (aboutToRemoveSpinner) {
                while (1) {
                    let wrapper: any = document.getElementById('spinner-wrap');
                    if (wrapper != null) {
                        wrapper.parentNode.removeChild(wrapper);
                    } else {
                        break;
                    }
                }
            }
        }, timeout);
    }
}

function openToastSuccess(message: string) {
    openToast(message, '#a5d45a');
}
function openToastWarning(message: string) {
    openToast(message, '#ded34c');
}

var errorToastDone = false;
function openToastError(message: string) {
    if (errorToastDone) {
        return;
    }
    errorToastDone = true;

    if (message === 'Failed to fetch') {
        message = 'The server did not respond';
    }

    openToast(message, '#e82727', APISettings.errorToastDuration);
    setTimeout(() => {
        errorToastDone = false;
    }, 200);
}

function openToastDefault(text: string, color: string, forceDuration: number = -1) {
    let spl = text.split('<split />');

    let title = spl.length > 1 ? spl[0] : '';
    let message = spl.length > 1 ? spl[1] : spl[0];

    let randId = '_' + Math.random().toString(36).substring(2, 9);

    let holder: any = document.getElementById('custom-toast-holder');
    if (holder == null) {
        holder = document.createElement('div');
        holder.id = 'custom-toast-holder';

        holder.style.position = 'fixed';
        holder.style.zIndex = '10001';
        holder.style.alignItems = 'center';
        holder.style.top = '1em';
        holder.style.right = '2em';

        document.body.appendChild(holder);
    }

    if (holder.children.length > 5) {
        holder.removeChild(holder.firstChild);
    }

    let t = document.createElement('div');

    t.id = randId;

    t.className = 'custom-toast';
    t.style.maxHeight = '15em';
    t.style.userSelect = 'none';
    t.style.overflow = 'hidden';
    t.style.transition = 'all 0.5s ease-out';
    t.style.padding = '0.5em';
    t.style.borderRadius = '0.5em';
    t.style.textAlign = 'center';
    t.style.margin = '0.5em auto';
    t.style.right = '-35em';
    t.style.position = 'relative';
    t.style.background = color;
    t.style.minWidth = 'min(18em, 40vw)';
    t.style.maxWidth = '30em';
    t.style.boxShadow = '0.2em 0.2em 0.2em rgba(0,0,0,0.3)';
    t.style.color = '#FFF';
    t.style.textShadow =
        'rgb(0 0 0 / 20%) 1px 0px 0px, rgb(0 0 0 / 20%) 0px -1px 0px, rgb(0 0 0 / 20%) 0px 1px 0px, rgb(0 0 0 / 20%) -1px 0px 0px';
    t.style.fontWeight = 'bold';

    let cls = document.createElement('div');
    cls.innerHTML = 'X';
    cls.style.float = 'right';
    cls.style.cursor = 'pointer';
    cls.style.marginTop = '-0.4em';
    cls.style.transform = 'scaleX(1.3)';

    cls.setAttribute(
        'onclick',
        "let w = this.parentNode; w.style.right = '-35em';w.style.maxHeight = '0';w.style.padding = '0';w.style.margin = '0';",
    );

    let rows = message.split(';').length;

    let msg = document.createElement('div');
    msg.innerHTML = title + message.split(';').join('<br />');
    msg.style.fontSize = '1.1em';
    msg.style.minHeight = '3em';
    msg.style.display = 'flex';
    msg.style.alignItems = 'center';
    msg.style.justifyContent = 'center';
    msg.style.textAlign = 'left';

    t.appendChild(cls);
    t.appendChild(msg);

    holder.appendChild(t);

    setTimeout(() => {
        t.style.right = '0';
    }, 50);

    let duration = 1000 + 400 * rows + (message.length <= 50 ? message.length : 50) * 100;

    if (duration > APISettings.maxToastMessageDuration) {
        duration = APISettings.maxToastMessageDuration;
    }

    if (forceDuration > 0) {
        duration = forceDuration;
    }

    setTimeout(() => {
        t.style.right = '-35em';
    }, duration);

    setTimeout(() => {
        t.style.maxHeight = '0';
        t.style.padding = '0';
        t.style.margin = '0';
    }, duration + 100);

    setTimeout(() => {
        let toast: any = document.getElementById(randId);
        if (toast != null) {
            toast.parentNode.removeChild(toast);
        }
    }, duration + 1000);
}

function openToast(text: string, color: string, forceDuration: number = -1) {
    if (APISettings.useExternalErrorToasts) {
        let type = 'info';

        if (color === '#a5d45a') {
            type = 'success';
        }
        if (color === '#e82727') {
            type = 'error';
        }

        let event: CustomEvent = new CustomEvent('api-toast-result', {
            detail: { text: text, type: type },
        });
        document.dispatchEvent(event);
    } else {
        openToastDefault(text, color, forceDuration);
    }
}

function getMobileToast(message: string, position: string = 'top', type: string = 'small') {
    let w = document.createElement('div');

    let toast: any = document.createElement('div');
    toast.style.background = 'black';
    toast.style.opacity = '0';
    toast.style.padding = '0.5em 2em';
    toast.style.fontSize = 'min(2vw,1em)';
    toast.style.margin = '0 auto';
    toast.style.position = 'fixed';
    toast.style.color = 'white';
    toast.style.transition = 'opacity 0.5s ease-out';
    toast.style.zIndex = '1000';

    let positionOffset = '2em';
    if (type === 'small') {
        toast.style.minWidth = '20vw';
        toast.style.maxWidth = '40vw';
        toast.style.textAlign = 'center';
        toast.style.borderRadius = '0.5em';
    }
    if (type === 'wide') {
        positionOffset = '0';
        toast.style.minWidth = 'calc(100vw - 4em)'; // 100vw - 2* side padding
        toast.style.maxWidth = 'calc(100vw - 4em)';
    }

    if (position === 'top') {
        toast.style.top = positionOffset;
    }
    if (position === 'bottom') {
        toast.style.bottom = positionOffset;
    }

    toast.innerHTML = message;

    w.id = 'toast-element-wrap';
    w.style.width = '100%';
    w.style.display = 'flex';
    w.style.flexDirection = 'column';
    w.style.alignItems = 'center';

    w.appendChild(toast);

    document.body.appendChild(w);

    let life = message.length * 120 + 2000;

    if (life > APISettings.maxToastMessageDuration * 2) {
        life = APISettings.maxToastMessageDuration * 2;
    }

    setTimeout(() => {
        toast.style.opacity = 1;
    }, 50);

    setTimeout(() => {
        toast.style.opacity = 0;
    }, life);

    setTimeout(() => {
        let e = document.getElementById('toast-element-wrap');
        if (e != null) {
            document.body.removeChild(e);
        }
    }, life + 300);
}

function getPopUp(titleText: string, innerHtml: string) {
    let inkDrop = document.createElement('div');
    let popup = document.createElement('div');
    let header = document.createElement('div');
    let title = document.createElement('div');
    let exit = document.createElement('div');
    let content = document.createElement('div');

    inkDrop.id = 'popup-inkDrop-base';
    inkDrop.style.position = 'fixed';
    inkDrop.style.top = '0';
    inkDrop.style.left = '0';
    inkDrop.style.width = '100vw';
    inkDrop.style.height = '100vh';
    inkDrop.style.display = 'flex';
    inkDrop.style.alignItems = 'center';
    inkDrop.style.justifyContent = 'space-around';
    inkDrop.style.zIndex = '3000';
    inkDrop.style.background = 'rgba(0,0,0,0.7)';

    popup.style.fontSize = 'min(2vw, 1em)';
    popup.style.width = '90%';
    popup.style.border = '1px solid black';
    popup.style.padding = '1em';
    popup.style.background = 'white';
    popup.style.borderRadius = '1em';

    header.style.borderBottom = '1px solid rgba(0,0,0,0.2)';
    header.style.marginBottom = '1em';
    header.style.display = 'flex';
    header.style.justifyContent = 'space-between';

    title.style.fontWeight = 'bold';

    exit.style.fontSize = 'min(4vw,1.5em)';
    exit.style.fontFamily = 'inherit';
    exit.style.lineHeight = '0.7em';
    exit.style.border = '1px solid rgba(0,0,0,0.3)';
    exit.style.borderRadius = '50%';
    exit.style.textAlign = 'center';
    exit.style.width = '1em';
    exit.style.height = '1em';
    exit.style.textAlign = '1em';
    exit.style.cursor = 'pointer';
    exit.style.position = 'relative';
    exit.style.top = '-0.2em';

    content.style.overflowY = 'auto';
    content.style.maxHeight = '65vh';
    content.style.padding = '1em';
    content.style.border = '1px solid rgba(0,0,0,0.2)';
    content.style.whiteSpace = 'pre-wrap';

    title.innerHTML = titleText;

    exit.innerHTML = 'x';
    exit.onclick = function () {
        let i: any = document.getElementById('popup-inkDrop-base');
        document.body.removeChild(i);
    };

    content.innerHTML = innerHtml;

    inkDrop.appendChild(popup);
    popup.appendChild(header);
    header.appendChild(title);
    header.appendChild(exit);
    popup.appendChild(content);

    document.body.appendChild(inkDrop);
}

import './detail.scss';

import { useState } from 'react';
import { LoaderFunctionArgs, useParams, useSearchParams } from 'react-router-dom';

import { ModalDialog } from '@Eni/docware-fe-master';
import { Icon } from '@fluentui/react';

import { useStartWorkflow } from '@/features/detail/api/start-workflow';
import { DeAuthorizationModal } from '@/features/detail/components/de-authorization-modal';
import { QueryClient, useQueryClient } from '@tanstack/react-query';
import DocumentDetailColumn from '../../components/document-detail-column/DocumentDetailColumn';
import { SpudLetterManagementToolbar } from '../../components/spudletter-management-toolbar/SpudLetterManagementToolbar';
import { getLetterQueryOptions, useLetter } from '../../features/detail/api/get-letter';
import { getWorkflowQueryOptions, useWorkflow } from '../../features/detail/api/get-workflow';
import { ApprovalWorkflowModal } from '../../features/detail/components/approval-workflow-modal';
import { CollaborationWorkflowModal } from '../../features/detail/components/collaboration-workflow-modal';
import DistributeModal from '../../features/detail/components/distribute-modal';
import { useDocumentHead } from '../../hooks/use-document-head';
import { KeyValuePair } from '../../models/ApiTypes';
import { useSigners, WorkflowSigner } from '../../models/SpudLetterDto';
import { SpudLetterStatus } from '../../models/SpudLetterStatus';
import { APISettings } from '../../services/internal/ajax-service';
import { NavbarState } from '../../stores/toolbar/toolbar-state';
import { useUserContext } from '../../stores/user-context';
import { DOCUMENT_URLS } from '../../utils/api-urls';
import { CommonModalButtons } from '../../utils/ModalUtils';

export interface DetailState {
    wellCode: string;
    wellName: string;
    tight: boolean;
    updateDate: Date;
    status: SpudLetterStatus;
    creationDate: Date;
    ownership: string;
    signers: WorkflowSigner[];
    referenceSystem: string;
    coordinates: {
        latitude: string;
        longitude: string;
    };
    locked: boolean;
    documentId: string | null;
    documentName: string;
    countryCode: string;
    coordinateReferenceSystem: KeyValuePair;
}

export const detailLoader =
    (queryClient: QueryClient) =>
    async ({ params }: LoaderFunctionArgs) => {
        const wellCode = params.wellCode as string;

        const letterQueryOptions = getLetterQueryOptions(wellCode);
        const workflowQueryOptions = getWorkflowQueryOptions(wellCode);

        const promises = [
            queryClient.ensureQueryData(letterQueryOptions),
            queryClient.ensureQueryData(workflowQueryOptions),
        ] as const;

        const [letterQuery, workflowQuery] = await Promise.all(promises);

        return {
            letterQuery,
            workflowQuery,
        };
    };

export const DetailRoute = () => {
    const params = useParams();
    const wellCode = params.wellCode as string;

    const letterQuery = useLetter({ wellCode });
    const workflowQuery = useWorkflow({ wellCode });

    const spud = letterQuery.data;
    const workflow = workflowQuery.data;

    useDocumentHead({ title: `${wellCode} - Preview` });

    const [URLSearchParams, setUrlSearchParams] = useSearchParams();
    const isApprovalRequest = URLSearchParams.get('action') === 'approvalRequest';
    const isReviewRequest = URLSearchParams.get('action') === 'reviewRequest';
    const isDeAuthorizationRequest = URLSearchParams.get('action') === 'de-authorize';
    const [isSent, setIsSent] = useState(false);

    const { user: loggedUser } = useUserContext();

    const detailState: DetailState = {
        ...spud,
        tight: spud.xwareTight || spud.userTight,
        creationDate: new Date(spud.creationDate),
        updateDate: new Date(spud.updateDate),
        locked: spud.locked && spud.lockedBy !== loggedUser!.id,
        countryCode: spud.country.countryCode,
        documentId: workflow === null ? null : workflow.documentId,
    };

    const startWorkflowMutation = useStartWorkflow({
        wellCode,
        mutationConfig: {
            onSuccess: () => {
                setIsSent(true);
            },
        },
    });

    const closeModals = () => {
        setUrlSearchParams('');
        setIsSent(false);
    };

    const {
        areaManager,
        areaManagerDeputy,
        explorationManager,
        explorationManagerDeputy,
        projectManager,
        projectManagerDeputy,
    } = useSigners(detailState.signers);

    return (
        <div className="spud-letter-detail-wrap">
            <div className="page-wrap spud-letter-detail-main-wrap">
                <div className="spud-letter-detail-left-section">
                    <SpudLetterManagementToolbar
                        type={NavbarState.SpudLetterDetail}
                        currentFile={{
                            wellCode: detailState.wellCode,
                            locked: detailState.locked,
                            status: detailState.status,
                            docwareDocumentId: detailState.documentId,
                            documentName: detailState.documentName,
                            ownership: detailState.ownership,
                            signers: detailState.signers,
                        }}
                    />

                    <div className="pdf-renderer-wrap">
                        <iframe
                            width="100%"
                            height="100%"
                            src={`${APISettings.baseUrl}${DOCUMENT_URLS.MAINFILE_PREVIEW.replace(
                                '{wellCode}',
                                wellCode!,
                            )}`}
                            title={detailState.documentName}
                            // onLoad={loader.hide}
                            // onError={() => {
                            //     loader.hide();
                            //     WindowToast.error("There was an error while loading the Document");
                            // }}
                        ></iframe>
                    </div>
                </div>

                <nav className="spud-letter-detail-right-section">
                    <DocumentDetailColumn state={detailState} />
                </nav>
            </div>

            <DistributeModal countryCode={detailState.countryCode} ownership={detailState.ownership} />

            <CollaborationWorkflowModal
                areaManager={areaManager}
                areaManagerDeputy={areaManagerDeputy}
                enableModal={isReviewRequest && !isSent}
                closeModal={closeModals}
                send={() => startWorkflowMutation.mutate(wellCode)}
            />

            <ApprovalWorkflowModal
                detailState={detailState}
                explorationManager={explorationManager}
                explorationManagerDeputy={explorationManagerDeputy}
                projectManager={projectManager}
                projectManagerDeputy={projectManagerDeputy}
                enableModal={isApprovalRequest && !isSent}
                closeModal={closeModals}
                send={() => startWorkflowMutation.mutate(wellCode)}
            />

            {(isApprovalRequest || isReviewRequest) && (
                <ModalDialog
                    modalTitle={`Send Spud Letter for ${isApprovalRequest ? 'Approval' : 'Review'}`}
                    modalMessage={'Spud Letter sent successfully'}
                    modalInnerComponent={
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <Icon iconName="successful-send-svg" />
                        </div>
                    }
                    enableModal={isSent}
                    modalButtons={CommonModalButtons.okOnlyButtons(closeModals)}
                    onAbort={closeModals}
                />
            )}

            <DeAuthorizationModal
                key={detailState.wellCode + ' - ' + detailState.status}
                closeModal={closeModals}
                enableModal={isDeAuthorizationRequest}
                detailState={detailState}
            />
        </div>
    );
};

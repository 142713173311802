import { SelectionMode } from '@fluentui/react';

import GenericList from '../generic-list/GenericList';
import ModalDialog from '../modal-dialog/modal-dialog';
import { LatestExternalData, PendingChange } from '../pending-changes-manager/pending-changes-manager';
import { PendingChangesDialogColumns } from './PendingChangesDialogColumns';

export interface PendingChangesModalProps {
    pendingChanges: PendingChange[];
    setPendingChanges: (pendingChanges?: PendingChange[]) => void;
    show: boolean;
    onAccept: () => void;
    onAbort: () => void;
}

const PendingChangesModal = (props: PendingChangesModalProps) => {
    const { pendingChanges, setPendingChanges, show } = props;

    const selectItems = (selectedRows: PendingChange[]) => {
        //update pending changes with selected
        setPendingChanges(
            pendingChanges.map((change) => {
                let isSelected = selectedRows.some((row) => row.fieldName === change.fieldName);
                return {
                    ...change,
                    selected: isSelected,
                };
            }),
        );
    };

    return (
        <ModalDialog
            modalTitle={'Some fields were updated on external systems'}
            modalMessage={'All the selected fields will be updated, do you want to continue?'}
            enableModal={show}
            modalButtons={[
                { onClick: props.onAbort, label: 'Cancel' },
                { onClick: props.onAccept, label: 'Update', disabled: !pendingChanges.some((x) => x.selected) },
            ]}
            modalInnerComponent={
                <div className="pending-changes-list-wrapper">
                    <GenericList
                        columns={PendingChangesDialogColumns}
                        items={pendingChanges}
                        onSelectionChanged={selectItems}
                        selectionMode={SelectionMode.multiple}
                    />
                </div>
            }
            onAbort={props.onAbort}
            onAccept={props.onAccept}
            large
        />
    );
};

export default PendingChangesModal;

import { Stack } from '@fluentui/react';
import ModalDialog from '../../../components/modal-dialog/modal-dialog';
import { WorkflowSigner } from '../../../models/SpudLetterDto';
import { CommonModalButtons } from '../../../utils/ModalUtils';
import { SignerWithLabel } from './signer-with-label';

type CollaborationWorkflowModalModalProps = {
    areaManager: WorkflowSigner;
    areaManagerDeputy: WorkflowSigner;
    enableModal: boolean;
    closeModal: () => void;
    send: () => void;
};

export const CollaborationWorkflowModal = ({
    areaManager,
    areaManagerDeputy,
    enableModal,
    closeModal,
    send,
}: CollaborationWorkflowModalModalProps) => {
    let message = 'The Area Manager will have to review the Spud Letter';

    let content = (
        <Stack>
            <SignerWithLabel label={'Area Manager'} signer={areaManager} />
            <SignerWithLabel label={'Area Manager Deputy'} signer={areaManagerDeputy} />
        </Stack>
    );

    const buttons = CommonModalButtons.confirmCancelButtons(send, closeModal);
    const onAbort = closeModal;

    return (
        <ModalDialog
            modalTitle="Send Spud Letter for Approval"
            modalMessage={message}
            modalInnerComponent={content}
            enableModal={enableModal}
            modalButtons={buttons}
            onAbort={onAbort}
        />
    );
};

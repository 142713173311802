import './modal-dialog.scss';

import { getTheme, IIconProps, mergeStyleSets, Modal, Spinner } from '@fluentui/react';
import { IButtonStyles, IconButton } from '@fluentui/react/lib/Button';

import LabelButton from '../label-button/label-button';

export interface ModalDialogButtonInfo {
    onClick?: () => void;
    label: string;
    disabled?: boolean;
}

export interface IModalDialogProps {
    enableModal: boolean;
    modalButtons?: ModalDialogButtonInfo[];
    modalTitle?: string;
    modalMessage?: string;
    modalInnerComponent?: any;
    onAccept?: () => void;
    onAbort?: () => void;
    loadingButtons?: boolean;
    large?: boolean;
}

//this is a copy of ModalDialog of fe-master \
//duplicated because of bug where  dropdowns are not shown correctly
//TODO check if works again
export const ModalDialog = (props: IModalDialogProps) => {
    let buttons = props.modalButtons ?? [
        { onClick: props.onAbort, label: 'Cancel' },
        { onClick: props.onAccept, label: 'Accept' },
    ];

    let title = props.modalTitle ?? 'Are you sure?';
    let abortFunction = props.onAbort ?? (() => {});

    let isPrdAssistant = false;
    let isDrive = false;
    let isSpudLetter = true;

    return (
        <div>
            <Modal
                titleAriaId={title}
                isOpen={props.enableModal}
                onDismiss={abortFunction}
                isBlocking={true}
                containerClassName={'modal-dialog-main-wrap'}
                dragOptions={undefined}
            >
                <div className={contentStyles.header}>
                    <span className="modal-dialog-title">{title}</span>
                    <IconButton
                        styles={iconButtonStyles}
                        iconProps={cancelIcon}
                        ariaLabel="Close popup modal"
                        onClick={abortFunction}
                    />
                </div>
                <div className={contentStyles.body}>
                    {props.modalMessage && <div className="modal-dialog-message">{props.modalMessage}</div>}
                    {props.modalInnerComponent && (
                        <div
                            className={`modal-dialog-inner-component ${props.large && 'modal-dialog-inner-wrap-large'}`}
                        >
                            {props.modalInnerComponent}
                        </div>
                    )}
                    <div className="modal-dialog-button-main-wrap">
                        <div></div>
                        {props.loadingButtons === true && (
                            <div>
                                <Spinner label={'Processing...'} labelPosition={'right'} />
                            </div>
                        )}
                        {props.loadingButtons !== true && (
                            <div className="modal-dialog-button-wrap">
                                {buttons.map((x: ModalDialogButtonInfo, i: number) => {
                                    return (
                                        <span key={i} className="modal-dialog-button-inner-wrap">
                                            <LabelButton
                                                disabled={x.disabled}
                                                greenSolid={i === buttons.length - 1 && isPrdAssistant}
                                                orangeSolid={i === buttons.length - 1 && isDrive}
                                                blueSolid={i === buttons.length - 1 && isSpudLetter}
                                                whiteOutlined={i < buttons.length - 1}
                                                onClick={x.onClick ?? (() => {})}
                                                text={x.label}
                                            />
                                        </span>
                                    );
                                })}
                            </div>
                        )}
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default ModalDialog;

const cancelIcon: IIconProps = { iconName: 'Cancel' };

const theme = getTheme();
const contentStyles = mergeStyleSets({
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
    },
    header: [
        {
            flex: '1 1 auto',
            color: theme.palette.neutralPrimary,
            display: 'flex',
            alignItems: 'center',
            fontSize: '20px',
            fontWeight: '600',
            padding: '12px 12px 14px 24px',
        },
    ],
    body: {
        flex: '4 4 auto',
        padding: '0 24px 24px 24px',
        fontWeight: '400',
        fontSize: '14px',
        overflowY: 'hidden',
        selectors: {
            p: { margin: '14px 0' },
            'p:first-child': { marginTop: 0 },
            'p:last-child': { marginBottom: 0 },
        },
    },
});
const iconButtonStyles: Partial<IButtonStyles> = {
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
};

import { Stack } from '@fluentui/react';

export interface ReviewItemProps {
    title: string;
    value?: string | JSX.Element;
    fullWidth?: boolean;
}

export interface ReviewItemRowProps {
    items: {
        title: string;
        value: string | JSX.Element;
    }[];
    // title: string;
    // items: string[] | JSX.Element[];
    fullWidth?: boolean;
}

export const ReviewItemRow = (props: ReviewItemRowProps) => {
    return (
        <Stack horizontal>
            {props.items.map((x) => (
                <ReviewItem title={x.title} value={x.value} fullWidth={props.items.length === 1} key={x.title} />
            ))}
        </Stack>
    );
};

export const ReviewItem = (props: ReviewItemProps) => {
    let { title, value } = props;
    if (!value || value === '') {
        value = '--';
    }

    return (
        <div className={`review-item ${props.fullWidth ? '' : 'w-25'}`}>
            <div className="review-title">{title}</div>
            <div className="review-value">{value}</div>
        </div>
    );
};

import { ModalDialogButtonInfo } from '@Eni/docware-fe-master';

export const CommonModalButtons = {
    yesNoButtons: (confirm: () => void, cancel: () => void) =>
        [
            {
                label: 'No',
                onClick: cancel,
            },
            {
                label: 'Yes',
                onClick: confirm,
            },
        ] as ModalDialogButtonInfo[],

    confirmCancelButtons: (confirm: () => void, cancel: () => void, confirmDisabled: boolean = false) =>
        [
            {
                label: 'Cancel',
                onClick: cancel,
            },
            {
                label: 'Confirm',
                disabled: confirmDisabled,
                onClick: confirm,
            },
        ] as ModalDialogButtonInfo[],

    okOnlyButtons: (submit: () => void) =>
        [
            {
                label: 'Ok',
                onClick: submit,
            },
        ] as ModalDialogButtonInfo[],

    deleteCancelSave: (onDelete: () => void, cancel: () => void, save: () => void, saveDisabled?: boolean) =>
        [
            {
                label: 'Delete',
                onClick: onDelete,
            },
            {
                label: 'Cancel',
                onClick: cancel,
            },
            {
                label: 'Save',
                disabled: saveDisabled ?? false,
                onClick: save,
            },
        ] as ModalDialogButtonInfo[],
};

import { INBOUND_URLS } from '../../utils/api-urls';
import { AjaxService, createEmptyRequest, IAPIRequest, IAPIResponse } from '../internal/ajax-service';

const InboundController = {
    /** esempio di chiamata GET */
    getCountries: async (callback?: (response: any) => void) => {
        let request: IAPIRequest = createEmptyRequest();
        request.url = `${INBOUND_URLS.COUNTRIES}`;
        request.method = 'GET';
        let response: IAPIResponse = await AjaxService.call(request);
        if (callback) {
            callback(response);
        }
        return response;
    },
    getArisFields: async (countryCode: string, callback?: (response: any) => void) => {
        let request: IAPIRequest = createEmptyRequest();
        request.url = `${INBOUND_URLS.ARIS_FIELDS}?countryCode=${countryCode}`;
        request.method = 'GET';
        let response: IAPIResponse = await AjaxService.call(request);
        if (callback) {
            callback(response);
        }
        return response;
    },
    getProspects: async (countryCode: string, callback?: (response: any) => void) => {
        let request: IAPIRequest = createEmptyRequest();
        request.url = `${INBOUND_URLS.PROSPECTS}?countryCode=${countryCode}`;
        request.method = 'GET';
        let response: IAPIResponse = await AjaxService.call(request);
        if (callback) {
            callback(response);
        }
        return response;
    },
    getTargets: async (prospectId: string, callback?: (response: any) => void) => {
        let request: IAPIRequest = createEmptyRequest();
        request.url = `${INBOUND_URLS.TARGETS}?prospectId=${prospectId}`;
        let response: IAPIResponse = await AjaxService.call(request);
        if (callback) {
            callback(response);
        }
        return response;
    },

    getRigContractors: async (wellId: string, callback?: (response: any) => void) => {
        let request: IAPIRequest = createEmptyRequest();
        request.url = INBOUND_URLS.RIG_CONTRACTORS.replace('{wellId}', wellId);
        let response: IAPIResponse = await AjaxService.call(request);
        if (callback) {
            callback(response);
        }
        return response;
    },

    getProspectData: async (
        wellCode: string,
        arisFieldId?: string,
        prospectId?: string,
        callback?: (response: any) => void,
    ) => {
        let request: IAPIRequest = createEmptyRequest();
        if (arisFieldId)
            request.url = INBOUND_URLS.PROSPECT_DATA_WITH_ARIS.replace('{wellCode}', wellCode).replace(
                '{arisFieldId}',
                arisFieldId,
            );
        if (prospectId)
            request.url = INBOUND_URLS.PROSPECT_DATA_WITH_PROSPECT.replace('{wellCode}', wellCode).replace(
                '{prospectId}',
                prospectId,
            );
        let response: IAPIResponse = await AjaxService.call(request);
        if (callback) {
            callback(response);
        }
        return response;
    },

    getCRS: async (search: string, callback?: (response: any) => void) => {
        let request: IAPIRequest = createEmptyRequest();
        if (search === '') {
            request.url = INBOUND_URLS.CRS;
        } else {
            request.url = `${INBOUND_URLS.CRS}?search=${search}`;
        }
        let response: IAPIResponse = await AjaxService.call(request);
        if (callback) {
            callback(response);
        }
        return response;
    },
    getHydrocarbonPhases: async (callback?: (response: any) => void) => {
        let request: IAPIRequest = createEmptyRequest();
        request.url = INBOUND_URLS.HYDROCARBON_PHASES;
        let response: IAPIResponse = await AjaxService.call(request);
        if (callback) {
            callback(response);
        }
        return response;
    },
    getReservoirFields: async (country: string, callback?: (response: any) => void) => {
        let request: IAPIRequest = createEmptyRequest();
        request.url = INBOUND_URLS.RESERVOIR_FIELDS.replace('{country}', country);
        let response: IAPIResponse = await AjaxService.call(request);
        if (callback) {
            callback(response);
        }
        return response;
    },
    getReservoirLevel: async (reservoirFieldId: string, callback?: (response: any) => void) => {
        let request: IAPIRequest = createEmptyRequest();
        request.url = INBOUND_URLS.RESERVOIR_LEVELS.replace('{reservoirFieldId}', reservoirFieldId);
        let response: IAPIResponse = await AjaxService.call(request);
        if (callback) {
            callback(response);
        }
        return response;
    },
    getCountryReservoirLevels: async (
        arisFieldId?: string,
        countryCode?: string,
        callback?: (response: any) => void,
    ) => {
        let request: IAPIRequest = createEmptyRequest();
        let url = INBOUND_URLS.COUNTRY_RESERVOIR_LEVELS;
        if (arisFieldId !== undefined) url += `?arisFieldId=${arisFieldId}`;
        if (countryCode !== undefined) url += `?countryCode=${countryCode}`;
        request.url = url;
        let response: IAPIResponse = await AjaxService.call(request);
        if (callback) {
            callback(response);
        }
        return response;
    },
};

export default InboundController;

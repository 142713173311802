import { Label, Persona } from '@fluentui/react';
import { WorkflowSigner } from '../../../models/SpudLetterDto';

type SignerWithLabelProps = {
    signer: WorkflowSigner;
    label: string;
};

export const SignerWithLabel = ({ signer, label }: SignerWithLabelProps) => {
    if (!signer) return null;
    return (
        <>
            <Label>{label}</Label>
            <Persona key={signer.id} text={signer.name} secondaryText={signer.email} />
        </>
    );
};

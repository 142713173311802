import { AjaxService, createEmptyRequest, IAPIRequest, IAPIResponse } from '../services/internal/ajax-service';

const fetchData = async <T>(
    url: string,
    method: 'GET' | 'POST' | 'PATCH' | 'PUT' | 'DELETE' | 'HEAD',
    payload?: any,
    options: Options = defaultOptions,
) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = url;
    request.method = method;
    if (!!payload) request.payload = payload;
    request.skipResponseJsonParse = options.skipResponseJsonParse;
    let response: IAPIResponse = await AjaxService.call(request);
    if (response.error !== null) throw new Error('Error retrieving data!', { cause: response.raw.status });
    return response.payload as T;
};

type Options = {
    skipResponseJsonParse: boolean;
};

const defaultOptions: Options = {
    skipResponseJsonParse: false,
};

export const api = {
    async get<T>(url: string, options?: Options) {
        return await fetchData<T>(url, 'GET', options);
    },
    async post<T>(url: string, payload?: any, options?: Options) {
        return await fetchData<T>(url, 'POST', payload, options);
    },
    async patch<T>(url: string, payload?: any, options?: Options) {
        return await fetchData<T>(url, 'PATCH', payload, options);
    },
    async put<T>(url: string, payload?: any, options?: Options) {
        return await fetchData<T>(url, 'PUT', payload, options);
    },
    async delete<T>(url: string, payload?: any, options?: Options) {
        return await fetchData<T>(url, 'DELETE', payload, options);
    },
    async head<T>(url: string, options?: Options) {
        return await fetchData<T>(url, 'HEAD', options);
    },
};

import { useQuery } from '@tanstack/react-query';
import { api } from '../../../lib/api-client';
import { QueryConfig } from '../../../lib/react-query';
import { SpudLetterItem } from '../../../models/SpudLetterItem';
import { NUMBER_OF_SPUD_LETTERS_IN_HOMEPAGE } from '../../../utils/Constants';

export const getLetters = async ({ searchText }): Promise<SpudLetterItem[]> => {
    const payload = {
        search: searchText,
        limit: NUMBER_OF_SPUD_LETTERS_IN_HOMEPAGE,
        offset: 0,
    };
    return api.post(`/spudletter/getlist`, payload);
};

export const getLettersQueryOptions = (searchText: string) => {
    return {
        queryKey: ['letters', { searchText }],
        queryFn: () => getLetters({ searchText }),
    };
};

type UseLettersOptions = {
    searchText: string;
    queryConfig?: QueryConfig<typeof getLettersQueryOptions>;
};

export const useLetters = ({ searchText, queryConfig }: UseLettersOptions) => {
    return useQuery({
        ...getLettersQueryOptions(searchText),
        ...queryConfig,
    });
};

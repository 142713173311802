import './StatusBubble.scss';

import Icons from '../../utils/FabricIconsOutlet';
import { Icon } from '@fluentui/react';

export const capitalizeText = (text: string) => {
    if (!text || text === '') {
        return text;
    }
    return text.charAt(0).toLocaleUpperCase() + text.substring(1);
};

const statusNameicon = [
    { name: 'draft', iconName: 'PageEdit' },
    { name: 'review', iconName: 'UserSync' },
    { name: 'reviewedDraft', iconName: 'Completed' },
    { name: 'pending', iconName: 'ReminderTime' },
    { name: 'signed', iconName: 'PenWorkspace' },
];

export const getStatusLabel = (status: number, capitalize = false) => {
    if (status === null) return '';
    let name = statusNameicon[status].name;

    if (capitalize) {
        name = capitalizeText(name);
    }
    return name;
};

export const getStatusIcon = (status: number) => {
    if (status === null) return '';
    let iconName = statusNameicon[status].iconName;
    return <Icon iconName={iconName} />;
};

export interface StatusBubbleProps {
    documentStatus: number;
    isOldVersion: boolean;
}

const StatusBubble = (props: StatusBubbleProps) => {
    let statusLabel = getStatusLabel(props.documentStatus);
    return (
        <div style={{ opacity: props.isOldVersion ? '0.5' : '1' }} className={'status-bubble-main-wrap add-border'}>
            <div className={'status-bubble-inner status-bubble-' + statusLabel}>
                <div className="status-bubble-icon">{getStatusIcon(props.documentStatus)}</div>
                <div className="status-bubble-text">{capitalizeText(statusLabel)}</div>
            </div>
        </div>
    );
};

export default StatusBubble;

import { useNavigate } from 'react-router-dom';
import AppRoutes from '../utils/AppRoutes';
import LabelButton from './label-button/label-button';
import { getTheme, Icon, mergeStyles, mergeStyleSets } from '@fluentui/react';

export const UserErrorPage = ({ title, message }) => {
    const navigate = useNavigate();
    return (
        <div className={styles.wrapper}>
            <Icon iconName="Error" className={iconClass} />
            <span className={styles.title}>{title}</span>
            <span className={styles.message}>{message}</span>

            <div className={styles.buttons}>
                <LabelButton text={'Home'} onClick={() => navigate(AppRoutes.HOME)} />
                <LabelButton text={'Reload'} onClick={() => navigate('.', { replace: true })} />
            </div>
        </div>
    );
};

const theme = getTheme();
const styles = mergeStyleSets({
    wrapper: {
        width: '100%',
        height: '100%',
        minHeight: '600px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '1rem',
    },
    title: {
        fontWeight: 'bold',
        fontSize: '3em',
    },
    message: {
        fontSize: '1.2em',
    },

    buttons: {
        display: 'flex',
        gap: '2em',
    },
    error: {
        padding: '1em',
        fontStyle: 'italic',
    },
});

const iconClass = mergeStyles({
    fontSize: 50,
    height: 50,
    width: 50,
    margin: '0 25px',
});

export const DEFAULT_LOCALE = 'it-IT';
export const DEFAULT_LOCALE_DATE_OPTIONS: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
};
export const DEFAULT_LOCALE_DATE_TIME_OPTIONS: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
};
export const MAX_TIME_EDITING = 1000 * 60 * 55; //55 minutes
export const MAX_TIME_NOTICE = 1000 * 60 * 5; //5 minutes
export const BMT_PHASE_TO_XWARE_PHASE = {
    'OIL-GAS': 'GAS - OIL',
    OIL: 'OIL',
    GAS: 'GAS',
    'GAS-CONDENSATE': 'GAS', //TODO + details gas condensate?
};
export const NUMBER_OF_SPUD_LETTERS_IN_HOMEPAGE = 20;

import { api } from '@/lib/api-client';
import { MutationConfig } from '@/lib/react-query';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export const distributeSpudletter = async (wellCode: string): Promise<any> => {
    return api.post(`/spudletter/${wellCode}/distribute`);
};

type UseDistributeSpudletter = {
    mutationConfig?: MutationConfig<typeof distributeSpudletter>;
};

export const useDistributeSpudletter = ({ mutationConfig }: UseDistributeSpudletter = {}) => {
    const queryClient = useQueryClient();

    const { onSuccess, onError, ...restConfig } = mutationConfig || {};

    return useMutation({
        onSuccess: (data, ...args) => {
            onSuccess?.(data, ...args);
        },
        onError: (error, ...args) => {
            onError?.(error, ...args);
        },
        ...restConfig,
        mutationFn: distributeSpudletter,
    });
};

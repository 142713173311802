import { CountryReservoirLevel, IArisField, Prospect, Rig } from '../../models/ApiTypes';
import { IUser } from '../../models/IUser';
import { SpudLetterDto } from '../../models/SpudLetterDto';

export interface MissingDataDialog {
    show: boolean;
    fields: (keyof SpudLetterDto)[];
}

export interface FormState {
    errors: string[];
    arisFields: IArisField[];
    users: IUser[];

    missingDataDialog: MissingDataDialog;
    prospects: Prospect[];
    rigs: Rig[];
    reservoirOptions: CountryReservoirLevel[];
}

export const DEFAULT_FORM_STATE: FormState = {
    errors: [],
    users: [],

    arisFields: [],
    prospects: [],
    rigs: [],
    reservoirOptions: [],

    missingDataDialog: {
        show: false,
        fields: [],
    },
};

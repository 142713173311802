import { useQuery } from '@tanstack/react-query';
import { QueryConfig } from '../../../lib/react-query';
import { AjaxService, createEmptyRequest, IAPIRequest, IAPIResponse } from '../../../services/internal/ajax-service';
import { HydrocarbonPhase } from '../../../models/ApiTypes';

export const getHydrocarbonPhases = async (): Promise<HydrocarbonPhase[]> => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = '/inbound/hydrocarbon-phases';
    let response: IAPIResponse = await AjaxService.call(request);
    return response.payload;
};

export const getHydrocarbonPhasesQueryOptions = () => {
    return {
        queryKey: ['hydrocarbon-phases'],
        queryFn: () => getHydrocarbonPhases(),
    };
};

type UseHydrocarbonPhasesOptions = {
    queryConfig?: QueryConfig<typeof getHydrocarbonPhasesQueryOptions>;
};

export const useHydrocarbonPhases = ({ queryConfig }: UseHydrocarbonPhasesOptions = {}) => {
    return useQuery({
        ...getHydrocarbonPhasesQueryOptions(),
        ...queryConfig,
        placeholderData: [],
        staleTime: Infinity,
    });
};

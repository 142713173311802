import { INPUT_OPTIONS_URLS } from '../../utils/api-urls';
import { InputInfo } from '../../models/Options';
import { createEmptyRequest, AjaxService, IAPIRequest, IAPIResponse } from '../internal/ajax-service';

const InputOptionsController = {
    getInputOptions: async (callback?: (response: any) => void) => {
        let request: IAPIRequest = createEmptyRequest();
        request.url = INPUT_OPTIONS_URLS.BASE_URL;
        let response: IAPIResponse = await AjaxService.call(request);
        if (callback) {
            callback(response);
        }
        return response;
    },
    setInputOption: async (body: InputInfo, callback?: (response: any) => void) => {
        let request: IAPIRequest = createEmptyRequest();
        request.url = INPUT_OPTIONS_URLS.SET_INPUT_OPTIONS;
        request.method = 'POST';
        request.payload = body;
        request.successMessage = 'Record updated.';
        let response: IAPIResponse = await AjaxService.call(request);
        if (callback) {
            callback(response);
        }
        return response;
    },
    deleteInputOption: async (body: InputInfo, callback?: (response: any) => void) => {
        let request: IAPIRequest = createEmptyRequest();
        request.url = INPUT_OPTIONS_URLS.BASE_URL;
        request.method = 'DELETE';
        request.payload = body;
        request.successMessage = 'Record deleted.';
        let response: IAPIResponse = await AjaxService.call(request);
        if (callback) {
            callback(response);
        }
        return response;
    },
    getFormationAge: async (selectedIds: string[], callback?: (response: any) => void) => {
        let request: IAPIRequest = createEmptyRequest();
        request.url = INPUT_OPTIONS_URLS.GET_FORMATION_AGE;
        request.method = 'POST';
        request.payload = selectedIds;
        request.skipResponseJsonParse = true;
        let response: IAPIResponse = await AjaxService.call(request);
        if (callback) {
            callback(response);
        }
        return response;
    },
};

export default InputOptionsController;

import {Banner} from '@Eni/docware-fe-master';
import {IconButton, ITag} from '@fluentui/react';
import {useEffect, useState} from 'react';
import {IValue} from '../../Models/IValue';
import {getInputInfos} from '../../Reducers/Generic/GenericAction';
import {AjaxService, IAPIResponse} from '../../Services/AjaxService';
import ApiService from '../../Services/ApiService';
import {normalizeBoId} from '../../Utils/StringUtils';
import {FluentUIDecorator, FluentUIDecoratorTypes} from '../FluentUIDecorator/FluentUIDecorator';
import { IBoRow } from './Model';
import {HierarchyLibrary, IBOSearch} from '../../Models/ILibrary';

interface BOsPickerRowProps {
  metadataOptions: any[];
  defaultMetadataKey: IBoRow;
  countries: string[];
  libraries?: HierarchyLibrary[];
  professionalAreaIds?: string[];
  tightBos: string[];
  tightBosText?: JSX.Element | string;
  onDeleteRow: (index: number) => void;
  onValuesChange: (source: any, val: ITag[]) => void;
  values: IValue[];
  index: number;
  verticalDisplay?: boolean;
  lockOption: string;
}

const BOsPickerRow = (props: BOsPickerRowProps) => {
  const [metaDataKey, setMetaDataKey] = useState<any>(null);
  const [resetValues, setResetValues] = useState<boolean>(false);

  useEffect(() => {
    setMetaDataKey(props.defaultMetadataKey);
    setResetValues(true);
    setTimeout(() => {
      try {
        setResetValues(false);
      } catch (e) {}
    }, 1);
  }, [props.defaultMetadataKey]);

  const resolveSuggestions = (filter: string, selectedItems?: ITag[]): ITag[] | Promise<ITag[]> => {
    if (filter.length === 0) {
      return [];
    }

    return new Promise<ITag[]>((resolve, reject) => {
      if (metaDataKey == null) {
        return;
      }
      let queryObject = {
        integrationId: metaDataKey.data.source,
        search: filter,
        limit: 20,
        offset: 0,
        queryColumn: 'name',
        countryCodes: (props.countries || []).join(';'),
        libraryIds: (props.libraries || []).map((l) => l.id),
        professionalAreaIds: (props.professionalAreaIds || []),
      };
      ApiService.IntegrationsController.getBOValues(queryObject, (response: IAPIResponse) => {
        if (response.error == null) {
          const boList: any[] = response.payload;
          let tags = boList.map((item: any) => {
            let name = item.name;
            if (['xware_wells', 'generic_wells'].includes(metaDataKey.data.source)) {
              name += ' - ' + normalizeBoId(item.id);
            }
            return {key: item.id, name: name};
          });
          resolve(tags.filter((t) => !(selectedItems || []).some((s) => s.key === t.key)).sort((a, b) => (a.name > b.name ? 1 : -1)));
        } else {
          if (response.raw.status === 403) {
            AjaxService.openToastError(response.error);
          }
          resolve([]);
        }
      });
    });
  };

  const getYourLineBos = () => {
    let yourTights = [];
    let yourSelections = props.values.map((v) => {
      return v.name;
    });
    for (let i = 0; i < props.tightBos.length; i++) {
      let str: string = props.tightBos[i];
      if (yourSelections.includes(str)) {
        yourTights.push(str);
      }
    }

    return yourTights;
  };

  const getBosRowItem = (bOsOptions: any[], index: number) => {
    let tightBos = getYourLineBos();
    return (
      <div className="bos-picker-bo-row-outer">
        <div key={index} className={'flex-column-on-small-screen ' + (props.verticalDisplay ? '' : 'bos-picker-bo-row ')}>
          {(window as any)['show-bo-source'] === true && <div className="show-bo-source-label">{metaDataKey?.data?.source}</div>}
          <div className={'fill-width-on-small-screen ' + (props.verticalDisplay ? '' : 'bos-picker-bo-row-item')}>
            <FluentUIDecorator
              className="bos-picker-width-full"
              label={index === 0 || props.verticalDisplay ? 'Business Object Type' : null}
              info={index === 0 || props.verticalDisplay ? getInputInfos('new-document', 'bo-type') : null}
              required={true}
              fluentComponent={FluentUIDecoratorTypes.Dropdown({
                disabled: props.lockOption !== '',
                selectedKey: metaDataKey ? metaDataKey.key : null,
                options: metaDataKey ? bOsOptions.concat([metaDataKey]) : bOsOptions,
                onChange: (e: any, o: any) => {
                  setMetaDataKey(o);
                  /** change BO -> reset its value to empty */
                  props.onValuesChange(o, []);
                },
              })}
            />
          </div>
          <div className="bos-picker-spacer hide-on-small-screen"></div>
          <div className={'fill-width-on-small-screen ' + (props.verticalDisplay ? '' : 'bos-picker-bo-row-item')}>
            {!resetValues && (
              <FluentUIDecorator
                className="bos-picker-width-full"
                label={index === 0 || props.verticalDisplay ? 'Business Object Values' : null}
                info={index === 0 || props.verticalDisplay ? getInputInfos('new-document', 'bo-value') : null}
                required={true}
                fluentComponent={FluentUIDecoratorTypes.TagPicker({
                  disabled: metaDataKey == null || props.lockOption !== '',
                  selectedItems: props.values.map((v) => {
                    return {key: v.id, name: v.name};
                  }),
                  onResolveSuggestions: resolveSuggestions,
                  onChange: (b) => {
                    props.onValuesChange(
                      metaDataKey,
                      (b ?? []).map((b) => {
                        return {key: b.key as string, name: b.name};
                      })
                    );
                  },
                })}
              />
            )}
            {resetValues && (
              <FluentUIDecorator
                className="bos-picker-width-full"
                label={index === 0 ? 'Values' : null}
                info={index === 0 ? getInputInfos('new-document', 'bo-value') : null}
                required={true}
                fluentComponent={FluentUIDecoratorTypes.TagPicker({
                  disabled: metaDataKey == null,
                  selectedItems: [],
                  onResolveSuggestions: null,
                  onChange: (b) => {},
                })}
              />
            )}
          </div>
          <div className="bos-picker-bo-row-icon">
            <IconButton
              iconProps={{iconName: 'Delete'}}
              onClick={() => {
                props.onDeleteRow(index);
              }}
            />
          </div>
        </div>
        {tightBos.length > 0 && (
          <div style={{marginBottom: '1em'}}>
            <Banner
              type="error"
              message={
                <div>
                  {props.tightBosText} {tightBos.join(', ')}.
                </div>
              }
              enabled
            />
          </div>
        )}
        {props.lockOption !== '' && <div className="bos-picker-full-line-error">{props.lockOption}</div>}
      </div>
    );
  };

  return getBosRowItem(props.metadataOptions, props.index);
};

export default BOsPickerRow;

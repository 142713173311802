import { combineReducers } from 'redux';

import { formReducer } from './form/form-reducer';
import { FormState } from './form/form-state';
import { genericReducer } from './generic/generic-reducer';
import { GenericState } from './generic/generic-state';
import { toolbarReducer } from './toolbar/toolbar-reducer';
import { ToolbarState } from './toolbar/toolbar-state';
import attachmentsReducer from '../features/form/stores/attachments-slice';

const rootReducer = combineReducers({
    generic: genericReducer,
    form: formReducer,
    toolbar: toolbarReducer,
    attachments: attachmentsReducer,
});

export interface GlobalState {
    generic: GenericState;
    form: FormState;
    toolbar: ToolbarState;
}

export default rootReducer;

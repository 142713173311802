import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';

const keys = {
    dev: 'cc27afb2-fcc6-4f5c-ac3b-1a516a3f119f',
    test: '115f494b-e1e4-48fe-b0e9-a51c9f8e9fea',
    prod: 'cc27afb2-fcc6-4f5c-ac3b-1a516a3f119f',
};

const getInstrumentationKey = () => {
    if (window.location.origin.indexOf('sd-') !== -1) {
        return keys.dev;
    }

    if (window.location.origin.indexOf('st-') !== -1) {
        return keys.test;
    }

    return keys.prod;
};

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
    config: {
        instrumentationKey: getInstrumentationKey(),
        extensions: [reactPlugin],
        extensionConfig: {
            [reactPlugin.identifier]: { history: createBrowserHistory() },
        },
    },
});

const getTrackingComponent = (component: () => JSX.Element, name: string) => {
    //return withAITracking(reactPlugin, component, name);
    return component;
};

appInsights.loadAppInsights();

export { reactPlugin, appInsights, getTrackingComponent };

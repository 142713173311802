import {
    ToolbarActionType,
    SET_COUNTRIES,
    SET_NAVBAR_STATE,
    SET_SELECTED_FILES,
    SET_SHOW_ASSURANCE_SELECTOR,
    SET_SHOW_LOCKED_MODAL,
    SET_CURRENT_FILE,
    SET_SHOW_DISTRIBUTION_MODAL,
} from './toolbar-action';
import { DEFAULT_TOOLBAR_STATE, ToolbarState, NavbarState } from './toolbar-state';

export const toolbarReducer = (
    state: ToolbarState = DEFAULT_TOOLBAR_STATE,
    action: ToolbarActionType,
): ToolbarState => {
    switch (action.type) {
        case SET_NAVBAR_STATE:
            return {
                ...state,
                navbarState: action.payload,
            };
        case SET_SELECTED_FILES:
            return {
                ...state,
                selectedFiles: action.payload.selectedFiles,
                navbarState: action.payload.navbarState,
            };
        case SET_CURRENT_FILE:
            return {
                ...state,
                currentFile: action.payload,
            };
        case SET_SHOW_LOCKED_MODAL:
            return {
                ...state,
                showLockedModal: action.payload,
            };
        case SET_SHOW_DISTRIBUTION_MODAL:
            return {
                ...state,
                showDistributeModal: action.payload,
            };
        default:
            return state;
    }
};

import {WORKFLOWS_URLS} from '../../Constants/Urls';
import {IWorkflowItem, IWorkflowSignatureAction, WorkflowSignatureAction} from '../../Models/IWorkflowItem';
import {AjaxService, createEmptyRequest, IAPIRequest, IAPIResponse, silentLevels} from '../AjaxService';
import ApiService from '../ApiService';

const WorkflowController = {
  getById: async (documentId: string, callback?: (response: any) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = `${WORKFLOWS_URLS.GET_WORKFLOW_BY_ID}?documentId=${documentId}`;
    request.silentLevel = silentLevels.NO_UI_INTERACTIONS;
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  getWorkflowHistory: async (loadAmount: number, signType: WorkflowSignatureAction, callback?: (response: any) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = `${WORKFLOWS_URLS.GET_WF_HISTORY}?limit=${loadAmount}&signType=${signType}`;
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  getWorkflowHistoryWithAttachments: async (loadAmount: number, signType: WorkflowSignatureAction, callback?: (response: any) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = `${WORKFLOWS_URLS.GET_WF_HISTORY}?limit=${loadAmount}&signType=${signType}`;
    let responseGetWorkflowHistory: IAPIResponse = await AjaxService.call(request);

    if (responseGetWorkflowHistory.error == null) {
      const activities: IWorkflowItem[] = responseGetWorkflowHistory.payload;

      const documentIds = activities.map((x: IWorkflowItem) => x.documentId);
      const responseAttachments = await ApiService.DocumentController.testDocumentListAnyAttachments(documentIds);
      if (responseAttachments.error === null) {
        const response = responseAttachments;
        response.payload = {
          activities: activities,
          attachments: responseAttachments.payload,
        };
        if (callback) {
          callback(response);
        }
      }
    }
    return responseGetWorkflowHistory;
  },
  cancelWorkflow: async (workflowId: string, callback?: (response: any) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = `${WORKFLOWS_URLS.CANCEL_WORKFLOW_BY_ID}${workflowId}`;
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  inspectById: async (documentId: string, callback?: (response: any) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = `${WORKFLOWS_URLS.INSPECT_WORKFLOW_BY_ID}?documentId=${documentId}`;
    request.silentLevel = silentLevels.NO_UI_INTERACTIONS;
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  getAll: async (callback?: (response: any) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = `${WORKFLOWS_URLS.GET_ALL_WORKFLOWS}`;
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  insertWorkflowCreate: async (workflow: IWorkflowItem, callback?: (response: any) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = `${WORKFLOWS_URLS.INSERT_WORKFLOW_CREATE}`;
    request.method = 'POST';
    request.payload = workflow;
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  insertWorkflowUpdateDelegates: async (workflow: IWorkflowItem, callback?: (response: any) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = `${WORKFLOWS_URLS.INSERT_WORKFLOW_UPDATE_DELEGATES}`;
    request.method = 'POST';
    request.payload = workflow;
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  insertWorkflowSetSignature: async (workflowSignature: IWorkflowSignatureAction, callback?: (response: any) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = `${WORKFLOWS_URLS.INSERT_WORKFLOW_UPDATE_SIGNATURE}`;
    request.method = 'POST';
    request.payload = workflowSignature;
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
};

export default WorkflowController;

import { useQuery } from '@tanstack/react-query';
import { QueryConfig } from '../../../lib/react-query';
import { AjaxService, createEmptyRequest, IAPIRequest, IAPIResponse } from '../../../services/internal/ajax-service';
import { ErrorLog } from '../types/error-log';

export const getErrorLogs = async (): Promise<ErrorLog[]> => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = '/errorlogs';
    let response: IAPIResponse = await AjaxService.call(request);
    return response.payload;
};

export const getErrorLogsQueryOptions = () => {
    return {
        queryKey: ['error-logs'],
        queryFn: () => getErrorLogs(),
    };
};

type UseErrorLogsOptions = {
    queryConfig?: QueryConfig<typeof getErrorLogsQueryOptions>;
};

export const useErrorLogs = ({ queryConfig }: UseErrorLogsOptions = {}) => {
    return useQuery({
        ...getErrorLogsQueryOptions(),
        ...queryConfig,
    });
};

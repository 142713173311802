import { SPUD_LETTER_URLS } from '../../utils/api-urls';
import { BaseQueryObject } from '../../models/ApiTypes';
import { InboundSpudLetterDto } from '../../models/InboundSpudLetterDto';
import { NotifyMissingDataDto } from '../../models/NotifyMissingDataDto';
import { AjaxService, createEmptyRequest, IAPIRequest, IAPIResponse } from '../internal/ajax-service';

export interface DriveWorkflowDto {
    wellCode: string;
    // signers: Signers[];
    // message: string;
}

export interface ReviewWorkflowDto {
    wellCode: string;
}

export interface Signers {
    order: number;
    users: string[];
}

const SpudLetterController = {
    getSpudLetterList: async (payload: BaseQueryObject, callback?: (response: any) => void) => {
        let request: IAPIRequest = createEmptyRequest();
        request.url = SPUD_LETTER_URLS.GET_LIST;
        request.method = 'POST';
        request.payload = payload;
        let response: IAPIResponse = await AjaxService.call(request);
        if (callback) {
            callback(response);
        }
        return response;
    },

    getByWellCode: async (wellCode: string, callback?: (response: any) => void) => {
        let request: IAPIRequest = createEmptyRequest();
        request.url = SPUD_LETTER_URLS.BY_WELLCODE.replace('{wellCode}', wellCode);
        let response: IAPIResponse = await AjaxService.call(request);
        if (callback) {
            callback(response);
        }
        return response;
    },

    canAccessWellCode: async (wellCode: string, callback?: (response: any) => void) => {
        let request: IAPIRequest = createEmptyRequest();
        request.url = SPUD_LETTER_URLS.PERMISSIONS.replace('{wellCode}', wellCode);
        request.method = 'HEAD';
        let response: IAPIResponse = await AjaxService.call(request);
        if (callback) {
            callback(response);
        }
        return response;
    },

    spudLetterExists: async (wellCode: string, callback?: (response: any) => void) => {
        let request: IAPIRequest = createEmptyRequest();
        request.url = SPUD_LETTER_URLS.BY_WELLCODE.replace('{wellCode}', wellCode);
        request.method = 'HEAD';
        let response: IAPIResponse = await AjaxService.call(request);
        if (callback) {
            callback(response);
        }
        return response;
    },

    deleteSpudLetter: async (wellCode: string, callback?: (response: any) => void) => {
        let request: IAPIRequest = createEmptyRequest();
        request.url = SPUD_LETTER_URLS.BY_WELLCODE.replace('{wellCode}', wellCode);
        request.method = 'DELETE';
        let response: IAPIResponse = await AjaxService.call(request);
        if (callback) {
            callback(response);
        }
        return response;
    },
    createSpudLetter: async (wellCode: string, callback?: (response: any) => void) => {
        let request: IAPIRequest = createEmptyRequest();
        request.url = SPUD_LETTER_URLS.CREATE.replace('{wellCode}', wellCode);
        request.method = 'POST';
        let response: IAPIResponse = await AjaxService.call(request);
        if (callback) {
            callback(response);
        }
        return response;
    },

    update: async (payload: InboundSpudLetterDto, callback?: (response: any) => void) => {
        let request: IAPIRequest = createEmptyRequest();
        request.url = SPUD_LETTER_URLS.BY_WELLCODE.replace('{wellCode}', payload.wellCode);
        request.method = 'PUT';
        request.payload = payload;
        let response: IAPIResponse = await AjaxService.call(request);
        if (callback) {
            callback(response);
        }
        return response;
    },

    sendForApproval: async (payload: DriveWorkflowDto, callback?: (response: any) => void) => {
        let request: IAPIRequest = createEmptyRequest();
        request.url = SPUD_LETTER_URLS.SEND_FOR_APPROVAL;
        request.method = 'POST';
        request.payload = payload;
        let response: IAPIResponse = await AjaxService.call(request);
        if (callback) {
            callback(response);
        }
        return response;
    },

    sendForReview: async (payload: DriveWorkflowDto, callback?: (response: any) => void) => {
        let request: IAPIRequest = createEmptyRequest();
        request.url = SPUD_LETTER_URLS.SEND_FOR_REVIEW;
        request.method = 'POST';
        request.payload = payload;
        let response: IAPIResponse = await AjaxService.call(request);
        if (callback) {
            callback(response);
        }
        return response;
    },

    getInvalidFieldsForApproval: async (wellCode: string, callback?: (response: any) => void) => {
        let request: IAPIRequest = createEmptyRequest();
        request.url = SPUD_LETTER_URLS.GET_INVALID_FIELDS.replace('{wellCode}', wellCode);
        request.method = 'GET';
        let response: IAPIResponse = await AjaxService.call(request);
        if (callback) {
            callback(response);
        }
        return response;
    },

    distributeSpudletter: async (wellCode: string, callback?: (response: any) => void) => {
        let request: IAPIRequest = createEmptyRequest();
        request.url = SPUD_LETTER_URLS.DISTRIBUTE.replace('{wellCode}', wellCode);
        request.method = 'POST';
        let response: IAPIResponse = await AjaxService.call(request);
        if (callback) {
            callback(response);
        }
        return response;
    },
    notifyMissingData: async (wellCode: string, missingFields: string[], callback?: (response: any) => void) => {
        let request: IAPIRequest = createEmptyRequest();
        request.url = SPUD_LETTER_URLS.NOTIFY_MISSING_DATA.replace('{wellCode}', wellCode);
        request.method = 'POST';
        request.payload = missingFields;
        let response: IAPIResponse = await AjaxService.call(request);
        if (callback) {
            callback(response);
        }
        return response;
    },

    lock: async (wellCode: string, callback?: (response: any) => void) => {
        let request: IAPIRequest = createEmptyRequest();
        request.url = SPUD_LETTER_URLS.LOCK.replace('{wellCode}', wellCode);
        request.method = 'POST';
        let response: IAPIResponse = await AjaxService.call(request);
        if (callback) {
            callback(response);
        }
        return response;
    },
    unlock: async (wellCode: string, callback?: (response: any) => void) => {
        let request: IAPIRequest = createEmptyRequest();
        request.url = SPUD_LETTER_URLS.UNLOCK.replace('{wellCode}', wellCode);
        request.method = 'POST';
        let response: IAPIResponse = await AjaxService.call(request);
        if (callback) {
            callback(response);
        }
        return response;
    },

    applyChanges: async (wellCode: string, appliedFields: string[], callback?: (response: any) => void) => {
        let request: IAPIRequest = createEmptyRequest();
        request.url = SPUD_LETTER_URLS.APPLY_CHANGES.replace('{wellCode}', wellCode);
        request.method = 'POST';
        request.payload = appliedFields;
        let response: IAPIResponse = await AjaxService.call(request);
        if (callback) {
            callback(response);
        }
        return response;
    },
};

export default SpudLetterController;

import { match } from 'ts-pattern';

import { LabelButton } from '@Eni/docware-fe-master';
import GeneralInformation from '../../features/form/components/general-information/general-information';
import Notes from '../../features/form/components/notes/notes';
import ReservoirInformation from '../../features/form/components/reservoir-field-information/reservoir-field-information';
import Review from '../../features/form/components/review/review';
import WellInformation from '../../features/form/components/well-information/well-information';
import { FormMode } from '../../utils/FormMode';
import UserRoles from '../user-roles/UserRoles';
import { FormContainer } from '../../features/form/components/form-container';
import { FormSections } from '../../utils/FormSections';

interface NavButtonsProps {
    onBack?: () => void;
    onNext?: () => void;
    nextButton?: JSX.Element;
}
const NavButtons = ({ onBack, onNext, nextButton }: NavButtonsProps) => {
    return (
        <div className="nav-button-zone">
            <div>
                {onBack !== undefined && <LabelButton text="Back" icon="ChromeBack" whiteOutlined onClick={onBack} />}
            </div>
            {!!nextButton && nextButton}
            {!nextButton && onNext !== undefined && (
                <LabelButton text="Next" icon="ChromeBackMirrored" orangeSolid onClick={onNext} />
            )}
        </div>
    );
};

export interface FormRouteContainerProps {
    onSaveAndPreview: () => void;
    currentStep: number;
    mode: FormMode;
    increaseStep: () => void;
    decreaseStep: () => void;
    onEditFromReview: (step: number) => void;
}

/**
 * Component that sets up routes for form pages
 */
const FormRouteContainer = (props: FormRouteContainerProps) => {
    const routes = [
        <FormContainer title={FormSections.WELL_CODE_INFO.title}>
            <GeneralInformation />
        </FormContainer>,
        <FormContainer title={FormSections.RESERVOIR_INFO.title}>
            <ReservoirInformation />
        </FormContainer>,
        <FormContainer title={FormSections.WELL_INFO.title}>
            <WellInformation />
        </FormContainer>,
        <FormContainer title={FormSections.NOTES.title}>
            <Notes />
        </FormContainer>,
        <FormContainer title={FormSections.USER_ROLES.title}>
            <UserRoles />
        </FormContainer>,
        <Review onEditFromReview={props.onEditFromReview} />,
    ];

    if (props.mode === FormMode.View) {
        return routes[5];
    }

    let Buttons = match(props.currentStep)
        .with(0, () => <NavButtons onNext={props.increaseStep} />)
        .with(1, () => <NavButtons onBack={props.decreaseStep} onNext={props.increaseStep} />)
        .with(2, () => <NavButtons onBack={props.decreaseStep} onNext={props.increaseStep} />)
        .with(3, () => <NavButtons onBack={props.decreaseStep} onNext={props.increaseStep} />)
        .with(4, () => <NavButtons onBack={props.decreaseStep} onNext={props.increaseStep} />)
        .with(5, () => (
            <NavButtons
                onBack={props.decreaseStep}
                nextButton={<LabelButton text="Save and Preview" orangeSolid onClick={props.onSaveAndPreview} />}
            />
        ))
        .run();

    return (
        <>
            {routes[props.currentStep]}
            {Buttons}
        </>
    );
};

export default FormRouteContainer;

interface IFormSection {
    title: string;
    page: number;
    percentage: number;
}

export const FormSections: { [key: string]: IFormSection } = {
    WELL_CODE_INFO: {
        title: 'Well Code Information',
        page: 0,
        percentage: 0,
    },
    GENERAL_INFO: {
        title: 'General Information',
        page: 0,
        percentage: 0,
    },
    RESERVOIR_INFO: {
        title: 'Prospect / Reservoir Field Information',
        page: 1,
        percentage: 25,
    },
    ESTIMATED_TARGET: {
        title: 'Estimated Target',
        page: 1,
        percentage: 25,
    },
    WELL_INFO: {
        title: 'Well Information',
        page: 2,
        percentage: 50,
    },
    GEO_WELLHEAD_INFO: {
        title: 'Geographic Wellhead Coordinates',
        page: 2,
        percentage: 50,
    },
    NOTES: {
        title: 'Notes',
        page: 3,
        percentage: 75,
    },
    USER_ROLES: {
        title: 'User Roles',
        page: 4,
        percentage: 90,
    },
    REVIEW: {
        title: 'Review',
        page: 5,
        percentage: 100,
    },
};

import { Icon, Stack } from '@fluentui/react';

export interface DropdownClearCaretProps {
    condition: boolean;
    onClear: () => void;
}

const DropdownClearCaret = (props: DropdownClearCaretProps) => {
    return (
        <Stack horizontal verticalAlign={'center'}>
            {props.condition && (
                <Icon
                    iconName={'Cancel'}
                    className={'ms-Dropdown-cancel'}
                    styles={{
                        root: {
                            color: 'rgb(96, 94, 92)',
                            paddingRight: '.7em',
                            '&:hover': {
                                fontWeight: 800,
                            },
                        },
                    }}
                    onClick={(event) => {
                        event.stopPropagation();
                        props.onClear();
                    }}
                />
            )}
            <Icon
                iconName={'ChevronDown'}
                className={'ms-Dropdown-caretDown'}
                styles={{
                    root: {
                        color: 'rgb(96, 94, 92)',
                        '&:hover': {
                            fontWeight: 800,
                        },
                    },
                }}
                onClick={(event) => (event.currentTarget.parentNode as any).onClick}
            />
        </Stack>
    );
};

export default DropdownClearCaret;

import { IContextualMenuProps } from '@fluentui/react';
import { DefaultButton } from '@fluentui/react/lib/Button';
import { useEffect, useState } from 'react';
import './label-button.scss';

export interface ILabelButtonProps {
    whiteOutlined?: boolean;
    redOutlined?: boolean;
    blueOutlined?: boolean;
    orangeSolid?: boolean;
    greenSolid?: boolean;
    darkSolid?: boolean;
    blueSolid?: boolean;
    darkBackground?: boolean;
    fitContent?: boolean;

    text: string;
    icon?: string;
    disabled?: boolean;
    onClick?: () => void;
    href?: string;
    target?: string;

    split?: boolean;
    menuProps?: IContextualMenuProps;
    onMenuClick?: () => void;

    fullWidth?: boolean;
}

export const LabelButton = (props: ILabelButtonProps) => {
    const [className, setClassName] = useState<string>('');

    useEffect(() => {
        let relevantWrapClass = '';

        if (!props.disabled) {
            if (props.whiteOutlined) {
                relevantWrapClass = 'label-button-wrap-white-outlined';
            }
            if (props.redOutlined) {
                relevantWrapClass = 'label-button-wrap-red-outlined';
            }
            if (props.blueOutlined) {
                relevantWrapClass = 'label-button-wrap-blue-outlined';
            }
            if (props.darkSolid) {
                relevantWrapClass = 'label-button-wrap-dark-solid';
            }
            if (props.orangeSolid) {
                relevantWrapClass = 'label-button-wrap-orange-solid';
            }
            if (props.greenSolid) {
                relevantWrapClass = 'label-button-wrap-green-solid';
            }
            if (props.blueSolid) {
                relevantWrapClass = 'label-button-wrap-blue-solid';
            }

            if (relevantWrapClass === '') {
                relevantWrapClass = 'label-button-wrap-white-outlined';
            }

            if (props.darkBackground) {
                relevantWrapClass += ' force-white-text';
            }

            if (props.fullWidth) {
                relevantWrapClass += ' width-100';
            } //else {
            //   relevantWrapClass += " fit-content ";
            // }
        }
        setClassName(relevantWrapClass);
    }, [props.disabled]);

    return (
        <div className={className}>
            <DefaultButton
                iconProps={props.icon ? { iconName: props.icon } : {}}
                text={props.text}
                onClick={props.onClick ? props.onClick : () => {}}
                disabled={props.disabled}
                href={props.href}
                target={props.target}
                split={props.split}
                menuProps={props.menuProps}
                onMenuClick={props.onMenuClick ? props.onMenuClick : () => {}}
                styles={
                    props.fullWidth ? { root: { width: '100%' }, splitButtonContainer: { width: '100%' } } : undefined
                }
            />
        </div>
    );
};

export default LabelButton;

import { IChoiceGroupOption, IDropdownOption, ITag } from '@fluentui/react';
import { KeyValuePair } from './ApiTypes';
import { ITargetItem } from './ITargetItem';
import { SpudLetterStatus } from './SpudLetterStatus';
import { MissingDataNotification } from './IFormData';
import { SpudLetterDto, WorkflowSigner } from './SpudLetterDto';
import { CoordinateFormatType } from './CoordinateFormatType';

export interface IGeneralInfoState {
    place: string;
    date: Date | undefined;
    from: string;
    wellCode: string;
    wellName: string;
    referenceSystem: IChoiceGroupOption | null;
    ownership: IChoiceGroupOption | null;
    country: ITag;
    blockName: string;
    operator: string;
    participatingInterest: string;
    workingInterest: string;
    partnerships: string;
    eniOperated: boolean;
    onshoreOffshore: IChoiceGroupOption | null;
}
export interface IWellInfoState {
    spudDate: Date | undefined;
    rig: string;
    rigContractor: string;
    productionType: IDropdownOption;
    path: IDropdownOption;
    waterDepth: string;
    rotaryTable: string;
    coordinateReferenceSystem: ITag;
    coordinateAdditionalInfo: string;
    coordinateFormat: CoordinateFormatType;
    latDirection: string;
    lonDirection: string;
    latitude: string;
    longitude: string;
    plannedTVDSS: string;
    plannedMD: string;
    afeCostType: IDropdownOption;
    afeCostValue: string;
    afeDays: string;
    wcei: IDropdownOption;
}
export interface IReservoirInfoState {
    arisField: ITag;
    prospect: ITag;
    hiip: string;
    pos: string;
    trapType: IDropdownOption;
    formationAge: string;
    lithology: string;
    reservoirField: IDropdownOption;
    reservoirLevel: IDropdownOption[];
    hydrocarbonPhase: string;
    estimatedTargets: ITargetItem[];
}
export interface INotesState {
    notes: string;
}

/**
 *
 * object that contains the union of all the individual states of every form page, plus some more
 */
export interface FormData extends IGeneralInfoState, IWellInfoState, IReservoirInfoState, INotesState {
    spudLetterId: string;
    status: SpudLetterStatus | null;
    creationDate: Date;
    updateDate: Date;
    xwareTight: boolean;
    locked?: boolean;
    lockedBy?: string;
    missingDataNotifications: MissingDataNotification[];
    signers: WorkflowSigner[];
    documentName: string;
    userTight: boolean;
}

export interface TightDto {
    isTight: boolean;
    isModifiable: boolean;
}

export const InitialState: FormData = {
    spudLetterId: '',
    documentName: '',
    status: null,
    creationDate: undefined,
    updateDate: undefined,
    missingDataNotifications: [],
    signers: [],
    xwareTight: false,
    userTight: false,
    place: '',
    date: undefined,
    from: '',
    wellCode: '',
    wellName: '',
    referenceSystem: null,
    ownership: null,
    country: undefined,
    blockName: '',
    operator: '',
    participatingInterest: '0',
    workingInterest: '0',
    partnerships: '',
    eniOperated: true,
    onshoreOffshore: null,
    arisField: null,
    prospect: null,
    hiip: '',
    pos: '',
    trapType: null,
    formationAge: '',
    lithology: '',
    reservoirField: null,
    reservoirLevel: [],
    hydrocarbonPhase: '',
    estimatedTargets: [],
    spudDate: undefined,
    rig: '',
    rigContractor: '',
    productionType: null,
    path: null,
    waterDepth: '',
    rotaryTable: '',
    coordinateReferenceSystem: null,
    coordinateAdditionalInfo: '',
    coordinateFormat: CoordinateFormatType.DMS,
    latitude: '',
    longitude: '',
    latDirection: 'N',
    lonDirection: 'E',
    plannedTVDSS: '',
    plannedMD: '',
    afeCostType: null,
    afeCostValue: '',
    afeDays: '',
    wcei: null,
    notes: '',
};

export const propertyToFieldNameMap: { [key in keyof Partial<SpudLetterDto>]: string } = {
    xwareTight: 'Tight',
    userTight: 'Tight',
    place: 'Place',
    date: 'Date',
    from: 'From',
    wellCode: 'Well Code',
    wellName: 'Well Name',
    referenceSystem: 'Reference System',
    ownership: 'Ownership',
    country: 'Country',
    blockName: 'Block Name / License',
    operator: 'Operator (Eni Shares)',
    partnerships: 'Partnership (Participating Interest)',
    eniOperated: 'Eni Operated',
    onshoreOffshore: 'Onshore/Offshore',
    arisField: 'Aris Field name',
    prospect: 'Prospect',
    estimatedTargets: 'Estimated Target',
    hiip: 'HIIP [Mboa]',
    pos: 'POS [%]',
    trapType: 'Trap Type',
    formationAge: 'Formation Age',
    lithology: 'Lithology ',
    reservoirField: 'Reservoir Field',
    reservoirLevel: 'Reservoir Level(s)',
    hydrocarbonPhase: 'Estimated main fluid',
    // mainFluid: 'Fluid details',
    // estimatedTargets: [],
    spudDate: 'Spud date',
    rig: 'Rig',
    rigContractor: 'Rig Contractor',
    productionType: 'Production Type',
    path: 'Path',
    waterDepth: 'Water Depth/Ground Level',
    rotaryTable: 'Rotary Table',
    // selectedCoordinateFormat: 'Format',
    // selectedCoordinateType: 'Coordinates type',
    // latitude: 'Latitude',
    // longitude: 'Longitude ',
    coordinateReferenceSystem: 'Coordinate Reference System',
    coordinateAdditionalInfo: '',

    plannedTVDSS: 'Planned Total Depth/TVDSS',
    plannedMD: 'Planned Total Depth/MD',
    afeCostType: 'AFE Cost ',
    afeCostValue: '',
    afeDays: 'AFE Days',
    wcei: 'WCEI',
    notes: 'Notes',
};

import { DATA_OWNER_URLS } from '../../utils/api-urls';
import { DataOwner } from '../../models/DataOwner';
import { AjaxService, createEmptyRequest, IAPIRequest, IAPIResponse } from '../internal/ajax-service';

const DataOwnerController = {
    getDataOwners: async (callback?: (response: any) => void) => {
        let request: IAPIRequest = createEmptyRequest();
        request.url = DATA_OWNER_URLS.GET_DATA_OWNERS;
        let response: IAPIResponse = await AjaxService.call(request);
        if (callback) {
            callback(response);
        }
        return response;
    },
    insertDataOwner: async (dataOwner: DataOwner, callback?: (response: any) => void) => {
        let request: IAPIRequest = createEmptyRequest();
        request.url = DATA_OWNER_URLS.DATA_OWNER;
        request.method = 'POST';
        request.payload = dataOwner;
        let response: IAPIResponse = await AjaxService.call(request);
        if (callback) {
            callback(response);
        }
        return response;
    },
    updateDataOwner: async (dataOwner: DataOwner, callback?: (response: any) => void) => {
        let request: IAPIRequest = createEmptyRequest();
        request.url = DATA_OWNER_URLS.DATA_OWNER;
        request.method = 'PUT';
        request.payload = dataOwner;
        let response: IAPIResponse = await AjaxService.call(request);
        if (callback) {
            callback(response);
        }
        return response;
    },
    deleteDataOwner: async (dataOwnerId: string, callback?: (response: any) => void) => {
        let request: IAPIRequest = createEmptyRequest();
        request.url = DATA_OWNER_URLS.DELETE_DATA_OWNER.replace('{dataOwnerId}', dataOwnerId);
        request.method = 'DELETE';
        let response: IAPIResponse = await AjaxService.call(request);
        if (callback) {
            callback(response);
        }
        return response;
    },
};

export default DataOwnerController;

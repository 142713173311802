import ErrorPage from '@/components/error-page/error-page';
import Loader from '@/components/loader/loader-component';
import { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Outlet } from 'react-router-dom';

export function Root() {
    return (
        <Suspense fallback={<Loader display={true} />}>
            <ErrorBoundary fallback={<ErrorPage title={''} message={''} page={'Application'} />}>
                <div style={{ overflow: 'hidden' }}>
                    <Outlet />
                </div>
            </ErrorBoundary>
        </Suspense>
    );
}

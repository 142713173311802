import { applyMiddleware, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducer from './root-reducer';

const composeEnhancers = composeWithDevTools({ name: 'Docware SpudLetter' });
const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunkMiddleware)));

// const store = configureStore({
//   reducer: {
//     // generic: genericReducer,
//     form: formReducer,
//     // toolbar: toolbarReducer,
//     attachments: attachmentsReducer
//   },
//   // enhancers: composeEnhancers(applyMiddleware(thunkMiddleware))
// })

export type AppState = ReturnType<typeof rootReducer>;
export default store;

// develop only
if (window.location.href.indexOf('localhost') !== -1) {
    let w: any = window;
    w.store = store;
}

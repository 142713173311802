import {Banner, LabelButton} from '@Eni/docware-fe-master';
import {IconButton, IDropdown, IDropdownOption, IPersonaProps, ITag} from '@fluentui/react';
import {createRef, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useSearchParams} from 'react-router-dom';
import {DocumentStatusLs} from '../../Constants/Enums';
import {IBoValue, IMetadataValue} from '../../Models/IDocument';
import {
  HierarchyDocType,
  HierarchyItem,
  HierarchyLibrary,
  HierarchyProfessionalArea,
  IBoLibrary,
  IBOAggregated,
  ILibrary,
  IMetadata,
  MetadataType,
} from '../../Models/ILibrary';
import {IOptionDto} from '../../Models/IOptionDto';
import {DocumentListAction} from '../../Reducers/DocumentSearch/DocumentSearchActions';
import {getInputInfos} from '../../Reducers/Generic/GenericAction';
import {GlobalState} from '../../Reducers/RootReducer';
import {IAPIResponse} from '../../Services/AjaxService';
import ApiService from '../../Services/ApiService';
import AppRoutes, {DOCUMENT_SEARCH} from '../../Utils/AppRoutes';
import {createMetadataValue} from '../../Utils/DocumentUtils';
import {IntegrationUtils} from '../../Utils/IntegrationUtils';
import {injectValue} from '../../Utils/Methods';
import URIformatter from '../../Utils/URIformatter';
import AggregatedBosHistorySearch from '../AggregatedBosHistorySearch/AggregatedBosHistorySearch';
import BOsPicker from '../BOsPicker/BOsPicker';
import {FluentUIDecorator, FluentUIDecoratorTypes} from '../FluentUIDecorator/FluentUIDecorator';
import {handleRenderTitle} from '../ToolBar/ToolBar';
import store from '../../Reducers/Store';
import './AdvancedSearchInputTable.scss';
import {EXP_ASSURANCE_NAME, SEARCH_BASE_PAGINATION_SIZE} from '../../Constants/Constants';
import {IValue} from '../../Models/IValue';
import { AnchorToApi, AnchorToApiType, getAnchorToApiTypeSpec } from '../AnchorToApi/AnchorToApi';
import {TagUtils} from '../../Utils/TagUtils';
import {getMasterData} from '../MasterDataPicker/MasterDataPicker';
import {removeDuplicatesFromArrayOfObject} from '../../Utils/DictUtils';
import {getSplittedBosMultipleAreasSearch, getUserCountries} from '../../Utils/NewDocumentUtils';
import { BOsObject } from '../../Models/BusinessObject';
import ExpAssuranceCascadeBoPicker, { EXPASSURANCE_CASCADE_BOS_INTEGRATION_KEYS } from '../ExpAssuranceCascadeBoPicker/ExpAssuranceCascadeBoPicker';
import useFeature from '../FeatureFlag/useFeature';
import {MASTER_DATA_TERRITORIAL_MODAL_FF_KEY, EXP_ASSURANCE_SEARCH_BO_CASCADE_FF_KEY, AUTHORS_FREE_SEARCH_ADVANCED_SEARCH_FF_KEY} from '../../Constants/FeatureFlags';

const BO_SEARCH_KEY = 'bo-search'
const BO_AGGREGATE_KEY = 'bo-aggregated'

interface SelectOption {
  key: string;
  text: string;
}

const getUrlParamFromString = (url: string, param: string) => {
  let urlSpl = url.split('?');
  if (urlSpl.length > 1) {
    let args = urlSpl[1];
    let argsSpl = args.split('&');
    for (let i = 0; i < argsSpl.length; i++) {
      let argSpl = argsSpl[i].split('=');
      if (argSpl.length > 1) {
        if (argSpl[0] === param) {
          return argSpl[1];
        }
      }
    }
  }
  return null;
};

const ReverseEvaluateSingleItem = (item: any, index: number) => {
  if (!item) {
    return null;
  }

  if (typeof item === 'object' && !Array.isArray(item) && item !== null) {
    if (item['name']) {
      item['text'] = item['name'];
    }
    if (item['id']) {
      item['key'] = item['id'];
    }

    let key = item.key;
    let name = item.name;

    if (key) {
      return {key: key, name: name};
    } else {
      return {key: index, name: name};
    }
  } else {
    return {key: index, name: item};
  }
};

const ReverseEvaluateFilterArray = (filterArray: any[] | null | undefined) => {
  if (!filterArray) {
    return [];
  }

  let evaluated: any = [];
  for (let i = 0; i < filterArray.length; i++) {
    let item: any = filterArray[i];
    evaluated.push(ReverseEvaluateSingleItem(item, i));
  }

  return evaluated;
};

const EvaluateSingleItem = (item: any) => {
  if (!item) {
    return null;
  }

  if (item['text']) {
    item['name'] = item['text'];
  }
  if (item['key']) {
    item['id'] = item['key'];
  }

  let id = item.id;
  let name = item.name;

  if (id) {
    return {id: id, name: name};
  } else {
    return name;
  }
};

const EvaluateFilterArray = (filterArray: any[] | null) => {
  if (!filterArray) {
    return null;
  }

  let evaluated: any = [];
  for (let i = 0; i < filterArray.length; i++) {
    let item: any = filterArray[i];
    evaluated.push(EvaluateSingleItem(item));
  }

  return evaluated;
};

const getEmptyBody = () => {
  let limit = store.getState().documentSearch.resultsLimit;
  let emptyBody = {
    search: '',
    limit: limit,
    offset: 0,
    queryColumn: 'name',
    document: {
      countries: [],
      permissions: [],
      documentTypeIds: [],
      documentSubTypeIds: [],
      relatedDocuments: [],
    },
  };
  return emptyBody;
};

export const getBasicSearchBody = (searchText: string) => {
  let currentBody = getUrlParamFromString(window.location.search, 'searchBody');
  let body: any = getEmptyBody();

  if (currentBody) {
    body = JSON.parse(URIformatter.decode(decodeURI(currentBody)));
  }
  body['search'] = searchText ?? '';

  return body;
};

interface AdvancedSearchInputTableProps {
  onSubmit: (data: any) => void;
  activeLibrary?: ILibrary;
  showTextSearch?: boolean;
}

let filtersMap_global_hook: any = {};

export const AdvancedSearchInputTable = (props: AdvancedSearchInputTableProps) => {
  const loggedUser = useSelector((state: GlobalState) => state.user.currentUser);

  const generic = useSelector((state: GlobalState) => state.generic);
  const hierarchyLibrariesWhereUserCanAccess = useSelector<GlobalState, HierarchyLibrary[]>((state) => state.generic.libraryHierachyModelUserCanAccess);
  const dispatch = useDispatch();
  const [inited, setInited] = useState<boolean>(false);
  const [cleanInput, setCleanInput] = useState<boolean>(false);
  const [filtersMap, setFiltersMap] = useState<any>({});
  const [suggestedTags, setSuggestedTags] = useState<string[]>([]);
  const [tightCountries, setTightCountries] = useState<string[]>([]);
  const [tightBos, setTightBos] = useState<string[]>([]);

  const [params] = useSearchParams();
  const searchBody: string = URIformatter.decode(params.get('searchBody') ?? '');
  const permissions = useSelector((state: GlobalState) => state.user.permissionsByActions);
  const ProfAreaDropDownRef = createRef<IDropdown>();
  const DocTypeDropDownRef = createRef<IDropdown>();
  const DocSubTypeDropDownRef = createRef<IDropdown>();
  const expAssuranceCascadeBoIsActive = useFeature(EXP_ASSURANCE_SEARCH_BO_CASCADE_FF_KEY);
  const authorsFreeSearchAdvancedSearchIsActive = useFeature(AUTHORS_FREE_SEARCH_ADVANCED_SEARCH_FF_KEY);

  let matchLibId: string = props.activeLibrary ? props.activeLibrary.id : 'any';
  let areas: IDropdownOption[] = [{key: 'All', text: 'All Professional Areas', data: null}];
  hierarchyLibrariesWhereUserCanAccess
    .filter((x: HierarchyLibrary) => x.id === matchLibId || matchLibId === 'any')
    .forEach((library: HierarchyLibrary) => {
      if ( loggedUser.isSuperAdmin || loggedUser.UserLibraries.includes(library.name) ) {
        areas = areas.concat(
          library.professionalAreas.map((area: HierarchyProfessionalArea) => {
            return {key: area.id, text: area.name, data: library};
          })
        );
      }
    });

  const getTagsFromDB = async (filterText: string) => {
    if (filterText.length > 0) {
      ApiService.TagsController.getTags(filterText, (response: IAPIResponse) => {
        if (response.error == null) {
          setSuggestedTags(
            response.payload.map((x: any) => {
              return x.content;
            })
          );
        }
      });
    }
  };

  const filterSelectedTags = (filterText: string, tagList: ITag[] | undefined): ITag[] => {
    getTagsFromDB(filterText);

    let suggestedArray: ITag[] = suggestedTags.map((x: string, i: number) => {
      return {key: i, name: x} as ITag;
    });
    let list = [{key: -1, name: filterText} as ITag].concat(suggestedArray);
    list = list.filter(
      (x: any) =>
        x.name.toLowerCase().indexOf(filterText.toLocaleLowerCase()) !== -1 ||
        filterText.toLowerCase().indexOf(x.name.toLocaleLowerCase()) !== -1
    );
    let exclude = tagList ?? [];
    let asNames: string[] = exclude.map((x: ITag) => {
      return x.name;
    });
    return list.filter((x: ITag) => asNames.indexOf(x.name) === -1);
  };

  const cleanBosInputResults = (bOsTotal: any) => {
    let newBos: any = {};

    let keys: string[] = Object.keys(bOsTotal);
    for (let i = 0; i < keys.length; i++) {
      let value: any[] = bOsTotal[keys[i]];
      if (value && value.length > 0) {
        newBos[keys[i]] = value;
      }
    }
    return newBos;
  };

  const BuildAdvancedSearchBody = (filters: any) => {
    let bOs = filters[BO_SEARCH_KEY] ?? {};
    let bOsAggregated = filters[BO_AGGREGATE_KEY] ?? {};
    let bOsTotal = cleanBosInputResults({...bOs, ...bOsAggregated});
    let docTypes = filters['documentTypes'];
    let docSubTypes = filters['documentSubTypes'];

    let tags = filters['tags']
      ? filters['tags'].map((x: any) => {
          return x.name;
        })
      : null;

    let dynamicFields = [
      {
        name: 'document.professionalAreaIds',
        value: filters['professionalAreaIds']
          ? filters['professionalAreaIds']
              .filter((x) => x.key !== 'All')
              .map((x) => {
                return x.key;
              })
          : null,
      },
      {
        name: 'document.countries',
        value: EvaluateFilterArray(filters['countries'] ?? []),
      },
      {
        name: 'documentDateRange.from',
        value: filters['fromDate'] ? new Date(filters['fromDate']).toISOString() : null,
      },
      {
        name: 'documentDateRange.to',
        value: filters['toDate'] ? new Date(filters['toDate']).toISOString() : null,
      },
      {
        name: 'document.documentStatus',
        value: filters['status'] !== undefined ? filters['status'] : null,
      },
      {
        name: 'document.authors',
        value: EvaluateFilterArray(filters['authors'] ?? null),
      },
      {name: 'document.tags', value: tags},
      {name: 'document.documentCode', value: filters['documentCode']},
      {
        name: 'document.documentTypeIds',
        value: docTypes
          ? docTypes
              .filter((x) => x.key !== 'All')
              .map((x) => {
                return x.key;
              })
          : null,
      },
      {
        name: 'document.documentSubTypeIds',
        value: docSubTypes
          ? docSubTypes
              .filter((x) => x.key !== 'All')
              .map((x) => {
                return x.key;
              })
          : null,
      },
      {
        name: 'document.bOs',
        value: Object.keys(bOsTotal).length === 0 ? null : bOsTotal,
      },
    ];

    let baseObject: any = getBasicSearchBody(filters['searchText']);
    baseObject['sortBy'] = generic.sortSearchBy;
    baseObject['sortDesc'] = generic.sortDirectionDesc;

    const injectDeletion = (obj: any, path: string) => {
      let stepObj: any = {...obj};
      let splPath = path.split('.');
      for (let i = 0; i < splPath.length; i++) {
        let step = splPath[i];

        /** last step -> add value */
        if (i === splPath.length - 1) {
          delete stepObj[step];
        } else {
          if (!stepObj.hasOwnProperty(step)) {
            stepObj[step] = {};
          }
          stepObj = stepObj[step];
        }
      }

      return obj;
    };

    for (const d of dynamicFields) {
      /** add values if they are not to skip */
      if (d.value != null) {
        baseObject = injectValue(baseObject, d.name, d.value);
      } else {
        baseObject = injectDeletion(baseObject, d.name);
      }
    }

    let libraries = getSelectedLibraries();
    for (let i = 0; i < libraries.length; i++) {
      for (const m of libraries[i].metadata ?? []) {
        const value = filters?.metadata?.[m.name];
        if (value != null) {
          baseObject = injectValue(baseObject, `document.metadata.${m.name}`, value);
        } else {
          baseObject = injectDeletion(baseObject, `document.metadata.${m.name}`);
        }
      }
    }

    return baseObject;
  };

  const testHasNoIputs = (filters) => {
    let test = true;
    let testMap = {...filters};
    delete testMap['searchText'];
    let values = Object.values(testMap);
    for (let i = 0; i < values.length && test; i++) {
      let v = values[i];
      let vs = v === '' ? '' : JSON.stringify(v) ?? '';
      if (!(vs === '' || vs === '""' || vs === '[]' || vs === '{}' || v === 'null' || v === undefined)) {
        test = false;
      }
    }
    return test;
  };

  const checkTightBos = async (values: BOsObject) => {
    let results = [];
    let keys = Object.keys(values);

    for (let j = 0; j < keys.length; j++) {
      let key = keys[j];
      let kVals = values[key];
      for (let i = 0; i < kVals.length; i++) {
        let c: IValue = kVals[i];
        let res = await ApiService.DocumentController.checkIsTight(c.id, 'WELL');
        if (res.error === null && (res.payload === false || (window as any)['force-tight-test'])) {
          results.push(c.name);
        }
      }
    }
    setTightBos(results);
  };

  const checkTightCountries = async (countries: {key: string; name: string}[]) => {
    let results = [];
    for (let i = 0; i < countries.length; i++) {
      let c: {key: string; name: string} = countries[i];
      let res = await ApiService.DocumentController.checkIsTight(c.key, 'COUNTRY_CD');
      if (res.error === null && (res.payload === false || (window as any)['force-tight-test'])) {
        results.push(c.name);
      }
    }
    setTightCountries(results);
  };

  const initializeForm = (initNode: string) => {
    let betterAreas = [];
    let libsFromAreas = [];

    let asObj: any = !initNode ? getEmptyBody() : JSON.parse(initNode);
    if (asObj.document) {
      let areasToMatch = asObj.document.professionalAreaIds ?? [];

      for (let i = 0; i < areas.length; i++) {
        let testArea = areas[i];
        for (let j = 0; j < areasToMatch.length; j++) {
          let areaToMatch = areasToMatch[j];
          if (testArea.key === areaToMatch) {
            betterAreas.push({...testArea});
            libsFromAreas.push({...testArea.data});
          }
        }
      }
    }

    // if only one professional area is available, pre select it
    let areaList = areas.filter((x: any) => x.key !== 'All');
    if (areaList.length === 1 && betterAreas.length === 0) {
      betterAreas = areaList;
    }

    let initialState = {};
    let countries = ReverseEvaluateFilterArray(asObj.document.countries);
    let authors = ReverseEvaluateFilterArray(asObj.document.authors).map((x: {key: string; name: string}) => {
      return {key: x.key, text: x.name};
    });
    let bOs = asObj.document.bOs;
    let fromDate = asObj.documentDateRange ? asObj.documentDateRange.from : null;
    let toDate = asObj.documentDateRange ? asObj.documentDateRange.to : null;
    let docTypes = getDocumentTypesByKey(libsFromAreas, asObj.document.documentTypeIds);

    // try select the doc type single selection
    if (docTypes.length === 0) {
      docTypes = getDocumentTypeByMonoSelection(betterAreas);
    }

    let docSubTypes = getDocumentSubTypesByKey(libsFromAreas, asObj.document.documentTypeIds, asObj.document.documentSubTypeIds);

    // reduce options from duplicates
    docTypes = reduceDocumentTypesOptionList(docTypes);
    docSubTypes = reduceDocumentSubTypeOptionLinst(docSubTypes);

    let tags = ReverseEvaluateFilterArray(asObj.document.tags);
    let documentCode = asObj.document.documentCode;
    let status = asObj.document.documentStatus;
    if (libsFromAreas.length > 0 && bOs) {
      const splittedBos = getSplittedBosMultipleAreasSearch(libsFromAreas, bOs);
      onInitInputProvided(BO_SEARCH_KEY, splittedBos.search, initialState);
      onInitInputProvided(BO_AGGREGATE_KEY, splittedBos.aggregatedSearch, initialState);
    }

    if (fromDate) {
      onInitInputProvided('fromDate', new Date(fromDate).toISOString(), initialState);
    }
    if (toDate) {
      onInitInputProvided('toDate', new Date(toDate).toISOString(), initialState);
    }
    onInitInputProvided('professionalAreaIds', betterAreas, initialState);
    onInitInputProvided('tags', tags, initialState);
    onInitInputProvided('countries', countries, initialState);
    onInitInputProvided('documentTypes', docTypes, initialState);
    onInitInputProvided('documentCode', documentCode, initialState);
    onInitInputProvided('documentSubTypes', docSubTypes, initialState);
    onInitInputProvided('authors', authors, initialState);
    onInitInputProvided('status', status, initialState);
    onInitInputProvided('metadata', asObj.document.metadata, initialState);

    onInitInputProvided('searchText', asObj.search, initialState);

    setFiltersMap({...initialState});

    setInited(true);
  };

  const onInitInputProvided = (name: string, value: any, initialState: any) => {
    if (value !== undefined && value !== null) {
      initialState[name] = value;
    }
  };

  useEffect(() => {
    if (hierarchyLibrariesWhereUserCanAccess.length > 0) {
      initializeForm(searchBody);
    }
  }, [searchBody, hierarchyLibrariesWhereUserCanAccess]);

  useEffect(() => {
    if (filtersMap) {
      if (filtersMap.countries) {
        checkTightCountries(filtersMap.countries);
      }
      if (filtersMap[BO_SEARCH_KEY]) {
        checkTightBos(filtersMap[BO_SEARCH_KEY]);
      }
    }

    filtersMap_global_hook = {...filtersMap};
  }, [filtersMap]);

  const onInputRemoved = (inputName: string) => {
    let map = {...filtersMap};
    delete map[inputName];
    setFiltersMap(map);
  };

  const onInputProvided = (inputName: string, inputValue: any) => {
    let map = {...filtersMap};
    map[inputName] = inputValue;
    setFiltersMap(map);
  };

  /**
   * Set the metadata search attributes for select fields (i.e. fields with `id` and `name`)
   * @param inputName the code of the metadata
   * @param inputValue the value to be searched
   * @param type
   */
  const onMetadataSelectInputProvided = (inputName: string, inputValue: SelectOption, type: MetadataType) => {
    let map = {...filtersMap};
    if (!map.hasOwnProperty('metadata')) {
      map.metadata = {};
    }

    // Only if development library is selected select projectName if projectCode is selected and vice versa
    if (map?.professionalAreaIds?.filter((x) => x?.key === 'DPMS').length > 0) {
      if (inputName === 'projectName') {
        if (inputValue === undefined) {
          map.metadata['projectCode'] = undefined;
        } else {
          map.metadata['projectCode'] = createMetadataValue(MetadataType.TagPickerSingleSelect, {
            id: inputValue.text,
            name: inputValue.key,
          });
        }
      } else if (inputName === 'projectCode') {
        if (inputValue === undefined) {
          map.metadata['projectName'] = undefined;
        } else {
          map.metadata['projectName'] = createMetadataValue(MetadataType.TagPickerSingleSelect, {
            id: inputValue.text,
            name: inputValue.key,
          });
        }
      }
    }

    let value: IMetadataValue | null;
    if (inputValue) {
      value = createMetadataValue(type, {
        id: inputValue.key,
        name: inputValue.text,
      });
    }
    map.metadata[inputName] = value;
    setFiltersMap(map);
  };

  /**
   * Set the metadata search attributes for simple string values
   * @param inputName the code of the metadata
   * @param inputValue the value to be searched
   */
  const onMetadataNumberInputProvided = (inputName: string, inputValue: string) => {
    let map = {...filtersMap};
    if (!map.hasOwnProperty('metadata')) {
      map.metadata = {};
    }

    const value: IMetadataValue = createMetadataValue(MetadataType.AutoIncrement, inputValue);
    map.metadata[inputName] = value;
    setFiltersMap(map);
  };

  const onMetadataTextInputProvided = (inputName: string, inputValue: string) => {
    let map = {...filtersMap};
    if (!map.hasOwnProperty('metadata')) {
      map.metadata = {};
    }

    const value: IMetadataValue = createMetadataValue(MetadataType.Text, inputValue);
    map.metadata[inputName] = value;
    setFiltersMap(map);
  };

  const onInputAreaProvided = (inputName: string, inputValues: any[]) => {
    let map = {...filtersMap};
    map[inputName] = inputValues;
    delete map['documentTypes'];
    delete map['documentSubTypes'];
    //delete map['countries']; // user story: 11846

    for (let i = 0; i < inputValues.length; i++) {
      let inputValue: any = inputValues[i];
      if (inputValue.data) {
        let lib: HierarchyLibrary = inputValue.data;
        for (let j = 0; j < lib.professionalAreas.length; j++) {
          let area: HierarchyProfessionalArea = lib.professionalAreas[j];
          if (area.id === inputValue.key) {
            let docTypes: {key: string; text: string}[] = (area.documentTypes ?? []).map((x: {id: string; name: string}, i: number) => {
              return {key: x.id, text: x.name};
            });
            if (docTypes.length === 1 && inputValues.length === 1) {
              map['documentTypes'] = [docTypes[0]];
            }
          }
        }
      }
    }

    setFiltersMap(map);
    setCleanInput(true);
    setTimeout(() => {
      setCleanInput(false);
    }, 100);
  };

  const getInputValue = (inputName: string) => {
    let v = filtersMap[inputName];
    return v ?? null;
  };

  /**
   * Retrieve the search filter for the given metadata
   */
  const getMetadataInputValue = (inputName: string) => {
    let v = filtersMap?.metadata?.[inputName];
    return v ?? null;
  };

  const getInputValueKey = (inputName: string) => {
    let i = getInputValue(inputName);
    return i ? i.key : null;
  };
  const getInputValueKeys = (inputName: string) => {
    let i = getInputValue(inputName);
    return i
      ? i.map((x: any) => {
          return x.key;
        })
      : null;
  };

  const getSelectedLibraries = (): HierarchyLibrary[] => {
    let libraries: any[] = getInputValue('professionalAreaIds');
    return libraries
      ? (libraries
          .filter((x: any) => x.data)
          .map((x: any) => {
            return x.data;
          }) as HierarchyLibrary[])
      : [];
  };

  const getSelectedLibrariesExpAssuranceWithoutBosCascade = (): HierarchyLibrary[] => {
    const library = getSelectedLibraries().find(l => l.name === EXP_ASSURANCE_NAME);
    if(library){
      library.bOs = library.bOs.filter(b => !EXPASSURANCE_CASCADE_BOS_INTEGRATION_KEYS.includes(b.key))
      return [library]
    }
    return []
  };

  const getExpAssuranceBosWithoutBosCascade = (): BOsObject => {
    const allBos = getInputValue(BO_SEARCH_KEY) ?? {} as BOsObject
    const bosWithoutCascade = Object.keys(allBos)
      .filter(key => !EXPASSURANCE_CASCADE_BOS_INTEGRATION_KEYS.includes(key))
      .reduce((obj, key) => {
        obj[key] = allBos[key];
        return obj;
      }, {} as BOsObject);
    return bosWithoutCascade
  };

  const getCountriesStringArray = () => {
    let c = getInputValue('countries') ?? [];
    return c.map((x: any, i: number) => {
      return x.key;
    });
  };

  const performAdvancedSearch = () => {
    dispatch(DocumentListAction.setSearchLimit(SEARCH_BASE_PAGINATION_SIZE));
    let body = BuildAdvancedSearchBody(filtersMap_global_hook);
    body['shouldBeSimple'] = testHasNoIputs(filtersMap_global_hook);
    props.onSubmit(body);
  };

  useEffect(() => {
    if (window.location.href.includes(DOCUMENT_SEARCH)) {
      window.document.addEventListener('search-perform-request', performAdvancedSearch);
      return () => {
        window.document.removeEventListener('search-perform-request', performAdvancedSearch);
      };
    }
  }, []);

  const getDocumentTypeByMonoSelection = (libraries: any[]) => {
    if (libraries.length === 1) {
      let selectItem: {key: string; text: string; data: HierarchyLibrary} = libraries[0];
      let library: HierarchyLibrary = selectItem.data;
      let targetAreasMatch: HierarchyProfessionalArea[] = library.professionalAreas.filter(
        (x: HierarchyProfessionalArea) => x.id === selectItem.key
      );
      if (targetAreasMatch.length > 0) {
        let area: HierarchyProfessionalArea = targetAreasMatch[0];
        let docTypes = (area.documentTypes ?? []).map((x: HierarchyDocType, i: number) => {
          return {key: x.id, text: x.name};
        });

        if (docTypes.length === 1) {
          return [docTypes[0]];
        }
      }
    }

    return [];
  };

  const getDocumentTypesByKey = (libraries: HierarchyLibrary[], keys: string[]) => {
    let matchedDocumentTypes = [];
    if (libraries && keys && libraries.length > 0 && keys.length > 0) {
      for (let i = 0; i < libraries.length; i++) {
        let library: HierarchyLibrary = libraries[i];
        for (let j = 0; j < library.professionalAreas.length; j++) {
          let pArea: HierarchyProfessionalArea = library.professionalAreas[j];

          let docTypes = (pArea.documentTypes ?? []).map((x: HierarchyDocType, i: number) => {
            return {key: x.id, text: x.name};
          });

          let matched = docTypes.filter((x: {key: string; text: string}) => keys.includes(x.key));
          for (let k = 0; k < matched.length; k++) {
            matchedDocumentTypes.push(matched[k]);
          }
        }
      }
    }

    return matchedDocumentTypes;
  };

  const getDocumentSubTypesByKey = (libraries: HierarchyLibrary[], keys1: string[], keys2: string[]) => {
    let matchedSubTypes = [];
    if (libraries && libraries.length > 0 && keys1 && keys2 && keys1.length > 0 && keys2.length > 0) {
      for (let i = 0; i < libraries.length; i++) {
        let library: HierarchyLibrary = libraries[i];
        for (let j = 0; j < library.professionalAreas.length; j++) {
          let pArea: HierarchyProfessionalArea = library.professionalAreas[j];
          let docTypes: HierarchyDocType[] = pArea.documentTypes ?? [];
          let matched = docTypes.filter((x: any) => keys1.includes(x.id));
          if (matched.length > 0) {
            for (let m = 0; m < matched.length; m++) {
              let selectedDocType = matched[m];
              if (selectedDocType.subTypes) {
                let docSubTypes = selectedDocType.subTypes ?? [];
                let matched2 = docSubTypes
                  .filter((x: any) => keys2.includes(x.id))
                  .map((x: {id: string; name: string}, i: number) => {
                    return {key: x.id, text: x.name};
                  });
                if (matched2.length > 0) {
                  for (let k = 0; k < matched2.length; k++) {
                    if (matchedSubTypes.filter((h: any) => h.key === matched2[k].key).length === 0) {
                      matchedSubTypes.push(matched2[k]);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    return matchedSubTypes;
  };

  const getSelectedDocumentTypesItems = () => {
    let selectedLibraries: HierarchyLibrary[] = getSelectedLibraries();
    let selectedDocTypesKeys = getInputValueKeys('documentTypes');
    let selectedAreasIds = getInputValueKeys('professionalAreaIds');

    if (selectedDocTypesKeys === null) {
      return [];
    }

    let selectedDocTypes = [];
    for (let i = 0; i < selectedLibraries.length; i++) {
      for (let j = 0; j < selectedLibraries[i].professionalAreas.length; j++) {
        if (selectedAreasIds.includes(selectedLibraries[i].professionalAreas[j].id)) {
          for (let k = 0; k < selectedLibraries[i].professionalAreas[j].documentTypes.length; k++) {
            let docType: HierarchyDocType = selectedLibraries[i].professionalAreas[j].documentTypes[k];
            if (selectedDocTypesKeys.includes(docType.id)) {
              if (selectedDocTypes.filter((x: HierarchyDocType) => x.id === docType.id).length === 0) {
                selectedDocTypes.push(docType);
              }
            }
          }
        }
      }
    }

    return selectedDocTypes;
  };

  const getSelectedDocumentSubTypesItems = () => {
    let selectedDocumentTypes: HierarchyDocType[] = getSelectedDocumentTypesItems();
    let selectedDocSybTypesKeys = getInputValueKeys('documentSubTypes');

    if (selectedDocSybTypesKeys === null) {
      return [];
    }

    let selectedDocSubTypes: HierarchyItem[] = [];
    for (let i = 0; i < selectedDocumentTypes.length; i++) {
      for (let j = 0; j < selectedDocumentTypes[i].subTypes.length; j++) {
        let subType: HierarchyItem = selectedDocumentTypes[i].subTypes[j];
        if (selectedDocSybTypesKeys.includes(subType.id)) {
          if (selectedDocSubTypes.filter((x: HierarchyItem) => x.id === subType.id).length === 0) {
            selectedDocSubTypes.push(subType);
          }
        }
      }
    }
    return selectedDocSubTypes;
  };

  const getSelectedDocumentSubTypesKeys = () => {
    return getSelectedDocumentSubTypesItems().map((x: HierarchyItem) => {
      return x.id;
    });
  };

  const getSelectedDocumentTypesKeys = () => {
    return getSelectedDocumentTypesItems().map((x: HierarchyDocType) => {
      return x.id;
    });
  };

  const getAvailableDocumentSubTypes = () => {
    let selectedLibraries = getSelectedLibraries();
    let selectedAreasIds = getInputValueKeys('professionalAreaIds');
    let selectedDocTypesKeys: string[] = getSelectedDocumentTypesKeys();
    let documentSubTypes: {key: string; text: string}[] = [];
    let documentSubTypesArray: HierarchyItem[] = [];

    for (let i = 0; i < selectedLibraries.length; i++) {
      let selectedLibrary: HierarchyLibrary = selectedLibraries[i];
      for (let j = 0; j < selectedLibrary.professionalAreas.length; j++) {
        let area: HierarchyProfessionalArea = selectedLibrary.professionalAreas[j];

        if (selectedAreasIds.includes(area.id)) {
          for (let k = 0; k < area.documentTypes.length; k++) {
            let documentType: HierarchyDocType = area.documentTypes[k];

            if (selectedDocTypesKeys.includes(documentType.id)) {
              for (let h = 0; h < documentType.subTypes.length; h++) {
                documentSubTypesArray.push(documentType.subTypes[h]);
              }
            }
          }
        }
      }
    }

    for (let j = 0; j < documentSubTypesArray.length; j++) {
      let d: HierarchyItem = documentSubTypesArray[j];
      if (documentSubTypes.filter((x: {key: string; text: string}) => x.key === d.id).length === 0) {
        documentSubTypes.push({key: d.id, text: d.name});
      }
    }

    return documentSubTypes;
  };

  const getAvailableDocumentTypes = () => {
    const selectedLibraries = getSelectedLibraries();
    const selectedAreasIds = getInputValueKeys('professionalAreaIds');
    const documentTypes: {key: string; text: string}[] = [];
    const documentTypesArray: HierarchyDocType[] = [];
    for (let i = 0; i < selectedLibraries.length; i++) {
      const selectedLibrary: HierarchyLibrary = selectedLibraries[i];
      for (let j = 0; j < selectedLibrary.professionalAreas.length; j++) {
        const area: HierarchyProfessionalArea = selectedLibrary.professionalAreas[j];
        if (selectedAreasIds.includes(area.id)) {
          for (let k = 0; k < area.documentTypes.length; k++) {
            documentTypesArray.push(area.documentTypes[k]);
          }
        }
      }
    }

    for (let j = 0; j < documentTypesArray.length; j++) {
      const d: HierarchyDocType = documentTypesArray[j];
      if (documentTypes.filter((x: {key: string; text: string}) => x.key === d.id).length === 0) {
        documentTypes.push({key: d.id, text: d.name});
      }
    }

    return documentTypes;
  };

  const getBoAggregations = (libraries: HierarchyLibrary[]): IBOAggregated[] => {
    const aggregations = libraries.map(l => l.boAggregationsSearch).flat();
    return aggregations;
  };

  const selectedLibraries = getSelectedLibraries();
  const boAggregations = removeDuplicatesFromArrayOfObject(getBoAggregations(selectedLibraries), 'source');
  const availableDocTypes: {key: string; text: string}[] = getAvailableDocumentTypes();
  const availableDocSubTypes: {key: string; text: string}[] = getAvailableDocumentSubTypes();

  function renderMetadata(m: IMetadata, i: number) {
    let commonProps = {
      label: m.displayName,
      errorMessage: (window as any)['highlight-errors'] === 1 && m.required ? 'This field is required' : null,
      info: getInputInfos('new-document', m.displayName.replace(' ', '-').toLowerCase()),
      required: m.required,
    };

    let countedMetadatas: number = 0;
    let libraries = getSelectedLibraries();
    for (let i = 0; i < libraries.length; i++) {
      countedMetadatas += libraries[i].metadata.length;
    }

    let commonClass = 'metadata-input metadata-100';

    switch (m.type) {
      case MetadataType.Select:
        const selectVal: IOptionDto = filtersMap?.metadata?.[m.name]?.selectValue;
        return (
          <div className={commonClass} key={m.name}>
            <FluentUIDecorator
              {...commonProps}
              fluentComponent={FluentUIDecoratorTypes.Dropdown({
                options: m.values.map((x) => {
                  return {key: x.id, text: x.name};
                }),
                selectedKey: selectVal?.id,
                onChange: (_, o) => onMetadataSelectInputProvided(m.name, o as SelectOption, MetadataType.Select),
              })}
            />
          </div>
        );
      case MetadataType.MultiSelect:
        throw Error('Not implemented');
      case MetadataType.Text:
        const textVal: string = filtersMap?.metadata?.[m.name]?.stringValue;
        return (
          <div className={commonClass} key={m.name}>
            <FluentUIDecorator
              {...commonProps}
              fluentComponent={FluentUIDecoratorTypes.TextField({
                value: textVal,
                onChange: (_, o) => onMetadataTextInputProvided(m.name, o),
              })}
            />
          </div>
        );
      case MetadataType.Date:
        throw Error('Not implemented');
      case MetadataType.AutoIncrement:
        const numberVal: string = filtersMap?.metadata?.[m.name]?.numberValue;
        return (
          <div className={commonClass} key={m.name}>
            <FluentUIDecorator
              {...commonProps}
              fluentComponent={FluentUIDecoratorTypes.TextField({
                value: numberVal,
                onChange: (_, o) => onMetadataNumberInputProvided(m.name, o),
              })}
            />
          </div>
        );
      case MetadataType.TagPickerSingleSelect:
        const selectedVal: IOptionDto | null = filtersMap?.metadata?.[m.name]?.selectValue;
        return (
          <div className={commonClass} key={m.name}>
            <FluentUIDecorator
              {...commonProps}
              fluentComponent={FluentUIDecoratorTypes.TagPicker({
                selectedItems: selectedVal ? [TagUtils.optionToTag(selectedVal)] : undefined,
                onResolveSuggestions: (text, s): ITag[] => {
                  const selectedValues = s.map((x) => x.name.toLowerCase());
                  return m.values
                    .filter((x) => {
                      return x.name.toLowerCase().includes(text.toLowerCase()) && !selectedValues.includes(x.name.toLowerCase());
                    })
                    .map((x) => {
                      return {key: x.id, name: x.name};
                    });
                },
                onChange: (selectedValues: ITag[]) => {
                  let value: SelectOption;
                  switch (selectedValues.length) {
                    case 0:
                      value = undefined;
                      break;
                    case 1:
                      value = {
                        key: selectedValues[0].key.toString(),
                        text: selectedValues[0].name,
                      };
                      break;
                    case 2:
                      value = {
                        key: selectedValues[1].key.toString(),
                        text: selectedValues[1].name,
                      };
                      break;
                  }
                  onMetadataSelectInputProvided(m.name, value as SelectOption, MetadataType.TagPickerSingleSelect);
                },
              })}
            />
          </div>
        );
    }
  }

  let fakeBoLibraryTrick: IBoLibrary[] = selectedLibraries.flatMap(library => library.boSearch);
  if (fakeBoLibraryTrick.length > 0) {
    fakeBoLibraryTrick = removeDuplicatesFromArrayOfObject(fakeBoLibraryTrick, 'source');
  }


  const masterDataFromSelectedLibraries: IBoLibrary[] = removeDuplicatesFromArrayOfObject(
    selectedLibraries
      .filter((x) => x.masterDatas.length > 0)
      .map((x) => x.masterDatas)
      .flat(),
    'key'
  );

  const youAreOnSearch = window.location.pathname.indexOf(AppRoutes.DOCUMENT_SEARCH) !== -1;

  const reduceDocumentTypesOptionList = (list) => {
    const noDups = [];
    for (let i = 0; i < list.length; i++) {
      const t: any = list[i];
      if (noDups.filter((x: any) => x.key === t.key).length === 0) {
        noDups.push({...t});
      }
    }
    return noDups;
  };

  const reduceDocumentSubTypeOptionLinst = (list) => {
    const noDups = [];
    for (let i = 0; i < list.length; i++) {
      const t: any = list[i];
      if (noDups.filter((x: any) => x.key === t.key).length === 0) {
        noDups.push({...t});
      }
    }
    return noDups;
  };

  const documentTypesCompleteOptionList = (list: any[]) => {
    return [{key: 'All', text: 'All Document Types'}].concat(list);
  };

  const dynamicMetadatas: IMetadata[] = [];
  selectedLibraries.forEach((x: HierarchyLibrary, i: number) => {
    if (x.metadata) {
      x.metadata.forEach((y: IMetadata, j: number) => {
        if (y.showAdvancedFilterInFE && dynamicMetadatas.filter((k: IMetadata) => k.name === y.name).length === 0) {
          dynamicMetadatas.push(y);
        }
      });
    }
  });

  return (
    <div>
      {inited && (
        <div>
          <nav className={youAreOnSearch ? 'advanced-search-form-hold-wrap' : ''}>
            <div>
              <div className="advanced-search-main-title-wrap">
                <div className="advanced-search-main-title">Filter Results</div>
                {youAreOnSearch && (
                  <IconButton
                    style={{color: 'rgba(0,0,0,0.5)'}}
                    iconProps={{iconName: 'Cancel'}}
                    onClick={() => {
                      window.document.dispatchEvent(new Event('adv-search-filter-close'));
                    }}
                  />
                )}
              </div>

              <div style={{marginTop: '0.5em'}}>
                {props.showTextSearch && (
                  <FluentUIDecorator
                    label="Search in Drive"
                    info={getInputInfos('search-document', 'search-text')}
                    fluentComponent={FluentUIDecoratorTypes.TextField({
                      value: getInputValue('searchText'),
                      onChange: (e, currentValue) => onInputProvided('searchText', currentValue),
                    })}
                  />
                )}
                <FluentUIDecorator
                  label="Professional Areas"
                  denyComponentHardReset={true}
                  info={getInputInfos('new-document', 'area')}
                  fluentComponent={FluentUIDecoratorTypes.Dropdown({
                    options: areas,
                    onRenderTitle: handleRenderTitle,
                    componentRef: ProfAreaDropDownRef,
                    multiSelect: true,
                    placeholder: 'Select Professional Areas',
                    defaultSelectedKeys: getInputValueKeys('professionalAreaIds'),
                    onChange: (e, o: IDropdownOption) => {
                      if (o.key === 'All') {
                        (window as any)['profAreaSelectionWasAll'] = true;
                      } else {
                        (window as any)['profAreaSelectionWasAll'] = false;
                      }
                      setTimeout(() => {
                        if (
                          (window as any)['profAreaSelectionWasAll'] &&
                          ProfAreaDropDownRef.current.selectedOptions.filter((x) => x.key === 'All').length > 0
                        ) {
                          onInputAreaProvided('professionalAreaIds', areas);
                        } else if (
                          (window as any)['profAreaSelectionWasAll'] &&
                          ProfAreaDropDownRef.current.selectedOptions.filter((x) => x.key === 'All').length === 0
                        ) {
                          onInputAreaProvided('professionalAreaIds', []);
                        } else {
                          onInputAreaProvided(
                            'professionalAreaIds',
                            ProfAreaDropDownRef.current.selectedOptions.filter((x) => x.key !== 'All')
                          );
                        }
                      }, 300);
                    },
                  })}
                />
              </div>
              <div style={{marginTop: '0.5em'}}>
                {!cleanInput && (
                  <FluentUIDecorator
                    label="Countries"
                    info={getInputInfos('new-document', 'country')}
                    errorMessage={selectedLibraries.length > 0 ? null : 'You must select a Professional Area first.'}
                    fluentComponent={FluentUIDecoratorTypes.TagPicker({
                      onResolveSuggestions: (f, s) =>
                        getUserCountries(
                          f,
                          s,
                          permissions.countries,
                          selectedLibraries.map((x: HierarchyLibrary) => {
                            return x.name;
                          })
                        ),
                      inputProps: {placeholder: 'Select some countries'},
                      selectedItems: getInputValue('countries') ?? [],
                      onChange: (t) => {
                        onInputProvided('countries', t);
                      },
                    })}
                  />
                )}
                {cleanInput && (
                  <FluentUIDecorator
                    label="Countries"
                    info={getInputInfos('new-document', 'country')}
                    errorMessage={selectedLibraries.length > 0 ? null : 'You must select a Professional Area first.'}
                    fluentComponent={FluentUIDecoratorTypes.TagPicker({
                      onResolveSuggestions: () => {
                        return [];
                      },
                      inputProps: {placeholder: 'Select some countries'},
                      selectedItems: [],
                    })}
                  />
                )}
              </div>
              {/* {tightCountries.length > 0 && (
                <div style={{marginTop: '0.5em', marginBottom: '1em'}}>
                  <Banner
                    type="error"
                    message={
                      <div>
                        You selected <strong>one or more tight countries</strong> for which you don't have permissions on. You will not be
                        able to find any result with the following countries: {tightCountries.join(', ')}.
                      </div>
                    }
                    enabled
                  />
                </div>
              )} */}

              {(selectedLibraries.length > 1 || availableDocTypes.length > 1) && (
                <div style={{marginTop: '0.5em'}}>
                  <FluentUIDecorator
                    label={<AnchorToApi file={getAnchorToApiTypeSpec(AnchorToApiType.pdfTypeSubtype)} />}
                    denyComponentHardReset={true}
                    info={getInputInfos('new-document', 'document-type')}
                    errorMessage={selectedLibraries.length > 0 ? null : 'You must select a Professional Area first.'}
                    fluentComponent={FluentUIDecoratorTypes.Dropdown({
                      multiSelect: true,
                      onRenderTitle: handleRenderTitle,
                      componentRef: DocTypeDropDownRef,
                      options: documentTypesCompleteOptionList(reduceDocumentTypesOptionList(availableDocTypes)),
                      placeholder: 'Select a document type',
                      defaultSelectedKeys: getInputValueKeys('documentTypes'),
                      onChange: (e, o: IDropdownOption) => {
                        if (o.key === 'All') {
                          (window as any)['documentTypesWasAll'] = true;
                        } else {
                          (window as any)['documentTypesWasAll'] = false;
                        }
                        setTimeout(() => {
                          if (
                            (window as any)['documentTypesWasAll'] &&
                            DocTypeDropDownRef.current.selectedOptions.filter((x) => x.key === 'All').length > 0
                          ) {
                            onInputProvided(
                              'documentTypes',
                              documentTypesCompleteOptionList(reduceDocumentTypesOptionList(availableDocTypes))
                            );
                          } else if (
                            (window as any)['documentTypesWasAll'] &&
                            DocTypeDropDownRef.current.selectedOptions.filter((x) => x.key === 'All').length === 0
                          ) {
                            onInputProvided('documentTypes', []);
                          } else {
                            onInputProvided(
                              'documentTypes',
                              DocTypeDropDownRef.current.selectedOptions.filter((x) => x.key !== 'All')
                            );
                          }
                        }, 300);
                      },
                    })}
                  />
                </div>
              )}

              <div style={{marginTop: '0.5em'}}>
                <FluentUIDecorator
                  denyComponentHardReset={true}
                  label={
                    selectedLibraries.length > 1 || availableDocTypes.length > 1 ? (
                      'Document Sub-Type'
                    ) : (
                      <AnchorToApi file={getAnchorToApiTypeSpec(AnchorToApiType.pdfTypeSubtype)} />
                    )
                  }
                  info={getInputInfos(
                    'new-document',
                    selectedLibraries.length > 1 || availableDocTypes.length > 1 ? 'document-sub-type' : 'document-type'
                  )}
                  errorMessage={
                    selectedLibraries.length > 0
                      ? getSelectedDocumentTypesKeys().length > 0
                        ? null
                        : 'You must select a Document Type first.'
                      : 'You must select a Professional Area first.'
                  }
                  fluentComponent={FluentUIDecoratorTypes.Dropdown({
                    placeholder: availableDocTypes.length > 1 ? 'Select a document Sub-Type' : 'Select a document type',
                    defaultSelectedKeys: getInputValueKeys('documentSubTypes'),
                    multiSelect: true,
                    onRenderTitle: handleRenderTitle,
                    componentRef: DocSubTypeDropDownRef,
                    options: reduceDocumentSubTypeOptionLinst(availableDocSubTypes),
                    onDismiss: () => {
                      onInputProvided('documentSubTypes', DocSubTypeDropDownRef.current.selectedOptions);
                    },
                  })}
                />
              </div>

              {dynamicMetadatas.length > 0 && (
                <div>
                  <div className="advanced-search-separator" />
                  <h3>Metadata</h3>
                  {selectedLibraries.filter((x: HierarchyLibrary) => x.name === 'DSD').length > 0 && (
                    <div style={{marginTop: '0.5em'}}>
                      <FluentUIDecorator
                        label={'Document Code'}
                        info={getInputInfos('search-document', 'document-code')}
                        fluentComponent={FluentUIDecoratorTypes.TextField({
                          placeholder: 'Enter the document code',
                          value: getInputValue('documentCode'),
                          onChange: (e, o) => onInputProvided('documentCode', o),
                        })}
                      />
                    </div>
                  )}
                  {dynamicMetadatas.map((x: IMetadata, i: number) => {
                    return renderMetadata(x, i);
                  })}
                </div>
              )}

              <div className="advanced-search-separator" />

              <div style={{marginTop: '0.5em'}} className="advanced-search-row flex-column-on-small-screen">
                <div className="advanced-search-row-item">
                  <FluentUIDecorator
                    label="Document Date"
                    info={getInputInfos('advanced-search', 'publication-date')}
                    fluentComponent={FluentUIDecoratorTypes.DatePicker({
                      placeholder: 'From Date',
                      value: getInputValue('fromDate') ? new Date(getInputValue('fromDate')) : undefined,
                      onSelectDate: (d) => onInputProvided('fromDate', +(d || 0)),
                    })}
                  />
                </div>

                <div className="advanced-search-row-item">
                  <FluentUIDecorator
                    label="To Date"
                    noLabels
                    info={getInputInfos('advanced-search', 'from-date')}
                    fluentComponent={FluentUIDecoratorTypes.DatePicker({
                      placeholder: 'To Date',
                      value: getInputValue('toDate') ? new Date(getInputValue('toDate')) : undefined,
                      onSelectDate: (d) => onInputProvided('toDate', +(d || 0)),
                    })}
                  />
                </div>
              </div>

              <div className="advanced-search-separator" />

              <Banner
                type={'warning'}
                message={
                  <div>
                    You must select a <strong>Professional Area</strong> before being able to select any Business Object.
                  </div>
                }
                enabled={selectedLibraries.length === 0}
              />

              <Banner
                type={'warning'}
                message={
                  <div>
                    You have to select a <strong>Country</strong> before selecting any Business Object.
                  </div>
                }
                enabled={getInputValue('countries')?.length == 0}
              />

              <Banner
                type={'warning'}
                message={
                  <div>
                    Business Object cascade search is available only and exclusively when <strong>Exp Assurance & Control</strong> library is selected.
                  </div>
                }
                enabled={
                  areas.length > 0 && getSelectedLibraries().some((l) => l.name == EXP_ASSURANCE_NAME)
                }
              />

              {fakeBoLibraryTrick.length > 0 &&
                (expAssuranceCascadeBoIsActive &&
                getSelectedLibraries().length === 1 &&
                getSelectedLibraries()[0].name == EXP_ASSURANCE_NAME ? (
                  <>
                    <ExpAssuranceCascadeBoPicker
                      bosState={getInputValue(BO_SEARCH_KEY)}
                      countries={getCountriesStringArray()}
                      onChange={(bos: BOsObject) => {
                        const currentBos = {...getInputValue(BO_SEARCH_KEY)};
                        onInputProvided(BO_SEARCH_KEY, {...currentBos, ...bos});
                      }}
                    />
                    {/* 
                    
                    <div className="advanced-search-separator" />
                    <BOsPicker
                      hideNoBoSelectedMessage={true}
                      initialState={getExpAssuranceBosWithoutBosCascade()}
                      countries={getCountriesStringArray()}
                      libraries={getSelectedLibrariesExpAssuranceWithoutBosCascade()}
                      boFromselectedLibrary={(() =>
                        fakeBoLibraryTrick.filter((x) => !EXPASSURANCE_CASCADE_BOS_INTEGRATION_KEYS.includes(x.key)))()}
                      verticalDisplay
                      onChange={(bos) => {
                        const currentBos = {...getInputValue(BO_SEARCH_KEY), ...bos};
                        onInputProvided(BO_SEARCH_KEY, currentBos);
                      }}
                    />
                    */}
                  </>
                ) : (
                  <>
                    <div style={{marginTop: '2em'}}>
                      <BOsPicker
                        // tightBos={tightBos}
                        // tightBosText={
                        //   <div>
                        //     You selected <strong>one or more tight Business Objects</strong> for which you don't have permissions on. You will
                        //     not be able to find any result with the following Business Objects:
                        //   </div>
                        // }
                        professionalAreaIds={getInputValueKeys('professionalAreaIds') || []}
                        initialState={getInputValue(BO_SEARCH_KEY)}
                        countries={getCountriesStringArray()}
                        libraries={selectedLibraries}
                        boFromselectedLibrary={fakeBoLibraryTrick}
                        verticalDisplay
                        onChange={(bos) => {
                          const currentBos = {...getInputValue(BO_SEARCH_KEY), ...bos};
                          onInputProvided(BO_SEARCH_KEY, currentBos);
                        }}
                      />
                    </div>
                  </>
                ))}

              <div className="advanced-search-separator" />

              {boAggregations.map((item: IBOAggregated, i: number) => {
                const stored = {...filtersMap[BO_AGGREGATE_KEY]};
                let yourStored = null;
                if (stored) {
                  yourStored = stored[item.source];
                }
                return (
                  <div key={i} style={{marginTop: i === 0 ? '0' : '1em'}}>
                    <AggregatedBosHistorySearch
                      prefilled={yourStored}
                      emit={(bos: BOsObject) => {
                        onInputProvided(BO_AGGREGATE_KEY, bos);
                      }}
                      aggregation={item}
                      countries={getCountriesStringArray()}
                    />
                  </div>
                );
              })}

              {masterDataFromSelectedLibraries.length > 0 && (
                <div>
                  <div className="advanced-search-separator" />
                  <h3>Master Data</h3>
                  {getMasterData(
                    masterDataFromSelectedLibraries,
                    getCountriesStringArray(),
                    getInputValue(BO_SEARCH_KEY),
                    (
                      firstMasterIntegrationId: string,
                      firstMaster: IBoValue[],
                      secondMasterIntegrationId: string,
                      secondMaster: IBoValue[]
                    ) => {
                      const currentBos = {...getInputValue(BO_SEARCH_KEY)};
                      currentBos[firstMasterIntegrationId] = firstMaster;
                      currentBos[secondMasterIntegrationId] = secondMaster;
                      onInputProvided(BO_SEARCH_KEY, currentBos);
                    },
                    true
                  )}
                </div>
              )}

              <div style={{marginTop: '0.5em'}} className="advanced-search-row flex-column-on-small-screen">
                <div className="advanced-search-row-item">
                  <FluentUIDecorator
                    label="Tags"
                    info={getInputInfos('new-document', 'tags')}
                    fluentComponent={FluentUIDecoratorTypes.TagPicker({
                      onResolveSuggestions: filterSelectedTags,
                      inputProps: {placeholder: 'Add custom tags'},
                      selectedItems: getInputValue('tags') ?? [],
                      onChange: (t) => {
                        setSuggestedTags([]);
                        if (t) {
                          for (let i = 0; i < t.length; i++) {
                            t[i].key = i;
                          }
                        }
                        onInputProvided('tags', t);
                      },
                    })}
                  />
                </div>
                <div className="advanced-search-row-item-separator" />
                <div className="advanced-search-row-item">
                  <FluentUIDecorator
                    label="Authors"
                    info={getInputInfos('search-document', 'authors')}
                    fluentComponent={FluentUIDecoratorTypes.PeoplePicker({
                      inputProps: {placeholder: 'Search authors'},
                      selectedUsers: getInputValue('authors') ?? [],
                      onFilterChanged: (
                        filterText: string,
                        currentPersonas: IPersonaProps[] | undefined,
                        limitResults?: number | undefined
                      ) => {
                        return IntegrationUtils.resolvePeoplePickerVoices(filterText, currentPersonas, undefined, authorsFreeSearchAdvancedSearchIsActive);
                      },
                      onPeopleChanged: (p) => onInputProvided('authors', p),
                    })}
                  />
                </div>
              </div>

              <FluentUIDecorator
                label="Select a status"
                info={getInputInfos('search-document', 'status')}
                fluentComponent={FluentUIDecoratorTypes.Dropdown({
                  options: DocumentStatusLs.map((x: string, i: number) => {
                    return {key: i, text: x};
                  }),
                  selectedKey: getInputValue('status'),
                  onChange: (e: any, o: IDropdownOption) => {
                    onInputProvided('status', o.key);
                  },
                  placeholder: 'Select a status',
                })}
              />
            </div>
          </nav>
          <div className={'advanced-search-buttons-row'}>
            <LabelButton
              text="Clear Filters"
              icon="Delete"
              onClick={() => {
                setFiltersMap({});
                filtersMap_global_hook = {};
                performAdvancedSearch();
              }}
            />
            <LabelButton text="Apply Filters" icon="Filter" orangeSolid onClick={performAdvancedSearch} />
          </div>
        </div>
      )}
    </div>
  );
};

export default AdvancedSearchInputTable;

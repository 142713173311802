import './error-page.scss';

import { useNavigate } from 'react-router-dom';

import { getTheme, Icon, mergeStyles, mergeStyleSets } from '@fluentui/react';

import LabelButton from '../label-button/label-button';
import AppRoutes from '../../utils/AppRoutes';

interface ErrorPageProps {
    title: string;
    message: string;
    page: string;
}

const ErrorPage = ({ title, message, page }: ErrorPageProps) => {
    const navigate = useNavigate();
    return (
        <div className={styles.wrapper}>
            <Icon iconName="Error" className={iconClass} />
            <span className={styles.title}>Oops! Something went wrong.</span>
            <span className={styles.message}>
                We're sorry for the inconvenience. Please try again or return to the homepage.
            </span>

            <span className={styles.error}>
                Error message for page {page}: {message}
            </span>

            <div className={styles.buttons}>
                <LabelButton text={'Home'} onClick={() => navigate(AppRoutes.HOME)} />
                <LabelButton text={'Reload'} onClick={() => navigate(0)} />
            </div>
        </div>
    );
};

export default ErrorPage;

const theme = getTheme();
const styles = mergeStyleSets({
    wrapper: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    title: {
        fontWeight: 'bold',
        fontSize: '3em',
    },
    message: {
        fontSize: '1.2em',
    },

    buttons: {
        display: 'flex',
        gap: '2em',
    },
    error: {
        padding: '1em',
        fontStyle: 'italic',
    },
});

const iconClass = mergeStyles({
    fontSize: 50,
    height: 50,
    width: 50,
    margin: '0 25px',
});

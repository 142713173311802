import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Persona, Spinner, Stack } from '@fluentui/react';

import { useParams } from 'react-router-dom';
import ModalDialog from '@/components/modal-dialog/modal-dialog';
import Suspense from '@/components/suspense/suspense-component';
import { MailRecipient } from '@/models/MailRecipient';
import ApiService from '@/services/api-service';
import { IAPIResponse } from '@/services/internal/ajax-service';
import { useLoaderContext } from '@/stores/loader-context';
import { GlobalState } from '@/stores/root-reducer';
import { ToolbarActions } from '@/stores/toolbar/toolbar-action';
import { CommonModalButtons } from '@/utils/ModalUtils';
import WindowToast from '@/utils/window-toast';
import { useDistributeSpudletter } from '../api/distribute';

export interface DistributeModalProps {
    countryCode: string;
    ownership: string;
}

const DistributeModal = (props: DistributeModalProps) => {
    const params = useParams();
    const wellCode = params.wellCode as string;

    const showDistributionModal = useSelector<GlobalState, boolean>((state) => state.toolbar.showDistributeModal);

    const distributeMutation = useDistributeSpudletter({
        mutationConfig: {
            onSuccess: () => {
                WindowToast.success('Spud Letter shared successfully');
                closeModal();
            },
            onError: (error) => {
                switch (error.cause) {
                    case 400:
                        WindowToast.error(error.message);
                        break;
                    case 403:
                        WindowToast.error('You must be admin to do this');
                        break;
                    default:
                        WindowToast.error('Error while sharing Spud Letter');
                        break;
                }
            },
        },
    });

    const dispatch = useDispatch();
    const loader = useLoaderContext();

    const closeModal = () => {
        dispatch(ToolbarActions.setShowDistributionModal(false));
    };

    const [recipients, setRecipients] = useState<MailRecipient[]>([]);

    useEffect(() => {
        if (!props.countryCode || !props.ownership) return;
        loader.show();
        ApiService.RecipientController.getCountryOwnershipRecipients(props.countryCode, props.ownership)
            .then((response: IAPIResponse) => {
                if (response.error !== null) throw 'There was an error retrieving the recipients';
                setRecipients(response.payload);
            })
            .catch(WindowToast.error)
            .finally(() => {
                closeModal();
                loader.hide();
            });
    }, [props.countryCode, props.ownership]);

    const onAccept = () => {
        distributeMutation.mutate(wellCode);
    };

    const modalButtons = CommonModalButtons.confirmCancelButtons(onAccept, closeModal, recipients.length === 0);

    return (
        <ModalDialog
            modalTitle="Distribute Spud Letter"
            enableModal={showDistributionModal}
            modalMessage="Every Recipient associated with this Country and Well Ownership will receive this Spud Letter"
            modalInnerComponent={
                <Suspense fallback={<Spinner label="Loading..." />} isLoading={loader.isLoading}>
                    {recipients.length == 0 && <span>No matching recipient was found</span>}
                    <Stack>
                        {recipients.map((x) => (
                            <Persona key={x.id} text={x.name} secondaryText={x.email} />
                        ))}
                    </Stack>
                </Suspense>
            }
            modalButtons={modalButtons}
            onAbort={closeModal}
            onAccept={onAccept}
        />
    );
};

export default DistributeModal;

import { AuthenticationHelper, UserMenu } from '@Eni/docware-fe-master';
import ApiService from '../../services/api-service';
import { IAPIResponse } from '../../services/internal/ajax-service';
import { useUserContext } from '../../stores/user-context';
import Icons from '../../utils/FabricIconsOutlet';
import './user-menu.scss';

interface MenuItemProps {
    title: string;
    value: string;
}

const MenuItem = (props: MenuItemProps) => {
    return (
        <div className="user-menu-under-zone-block user-menu-row">
            <div className="user-menu-row-item">{props.title}:</div>
            <div className="user-menu-row-item">{props.value !== '' ? props.value : '--'}</div>
        </div>
    );
};

const SpudLetterUserMenu = () => {
    const { user } = useUserContext();

    const logout = () => {
        ApiService.UserController.logout((response: IAPIResponse) => {
            if (response.error === null) {
                AuthenticationHelper.logout();

                let kwownVersion = localStorage.getItem('known-version');

                localStorage.clear();

                // clear all cookies
                document.cookie.split(';').forEach(function (c) {
                    document.cookie = c
                        .replace(/^ +/, '')
                        .replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/');
                });

                localStorage.setItem('known-version', kwownVersion ?? '0');
                localStorage.setItem('do-not-start-the-login', 'true');

                let redirect = '';
                if (response.payload && response.payload.url) {
                    redirect = response.payload.url + '?post_logout_redirect_uri=';
                }

                redirect += window.location.origin;
                window.location.href = redirect;
            }
        });
    };

    let menuContent = (
        <div>
            <div className="user-menu-under-zone">
                <MenuItem title={'Name'} value={user?.name} />
                <MenuItem title={'UserId'} value={user?.id} />
                <MenuItem title={'Email'} value={user?.email} />
            </div>

            <div className="user-menu-under-zone-block">
                <div className="user-menu-under-zone-block-icon" onClick={logout}>
                    {Icons.getIcon('ErrorBadge')}
                    <div style={{ marginRight: '0.5em' }}></div>
                    <div>Logout</div>
                </div>
            </div>
        </div>
    );

    return (
        <div>
            <UserMenu content={menuContent} />
        </div>
    );
};

export default SpudLetterUserMenu;

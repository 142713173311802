import { CountryReservoirLevel, IArisField, Prospect, Rig } from '../../models/ApiTypes';
import { IUser } from '../../models/IUser';
import { MissingDataDialog } from './form-state';

export const SET_MISSING_DATA_DIALOG = 'SET_MISSING_DATA_DIALOG';

export const SET_ARIS_FIELDS = 'SET_ARIS_FIELDS';
export const SET_PROSPECTS = 'SET_PROSPECTS';
export const SET_RIGS = 'SET_RIGS';
export const SET_RESERVOIR_OPTIONS = 'SET_RESERVOIR_OPTIONS';
export const SET_COUNTRY_OPTIONS = 'SET_COUNTRY_OPTIONS';
export const SET_USERS = 'SET_USERS';

interface SetMissingDataDialogActionType {
    type: typeof SET_MISSING_DATA_DIALOG;
    payload: MissingDataDialog;
}
interface SetArisFieldsActionType {
    type: typeof SET_ARIS_FIELDS;
    payload: IArisField[];
}
interface SetProspectsActionType {
    type: typeof SET_PROSPECTS;
    payload: Prospect[];
}
interface SetRigsActionType {
    type: typeof SET_RIGS;
    payload: Rig[];
}
interface SetReservoirOptionsActionType {
    type: typeof SET_RESERVOIR_OPTIONS;
    payload: CountryReservoirLevel[];
}
interface SetUsersActionType {
    type: typeof SET_USERS;
    payload: IUser[];
}

export type FormActionTypes =
    | SetMissingDataDialogActionType
    | SetArisFieldsActionType
    | SetProspectsActionType
    | SetRigsActionType
    | SetReservoirOptionsActionType
    | SetUsersActionType;

const setMissingDataDialog = (data: MissingDataDialog): SetMissingDataDialogActionType => {
    return { type: SET_MISSING_DATA_DIALOG, payload: data };
};
const setArisFields = (data: IArisField[]): SetArisFieldsActionType => {
    return { type: SET_ARIS_FIELDS, payload: data };
};
const setProspects = (data: Prospect[]): SetProspectsActionType => {
    return { type: SET_PROSPECTS, payload: data };
};
const setRigs = (data: Rig[]): SetRigsActionType => {
    return { type: SET_RIGS, payload: data };
};
const setReservoirOptions = (data: CountryReservoirLevel[]): SetReservoirOptionsActionType => {
    return { type: SET_RESERVOIR_OPTIONS, payload: data };
};

const setUsers = (countries: IUser[]): SetUsersActionType => {
    return { type: SET_USERS, payload: countries };
};

export const FormActions = {
    setMissingDataDialog,
    setArisFields,
    setProspects,
    setRigs,
    setReservoirOptions,
    setUsers,
};

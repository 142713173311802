import { registerIcons } from '@fluentui/react';

const setupSvg = () => {
    registerIcons({
        icons: {
            'desert-svg': (
                <svg width="226" height="226" viewBox="0 0 226 226" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M89.19 191.777C124.673 191.777 153.439 163.012 153.439 127.529C153.439 92.0451 124.673 63.28 89.19 63.28C53.7065 63.28 24.9414 92.0451 24.9414 127.529C24.9414 163.012 53.7065 191.777 89.19 191.777Z"
                        fill="#F3F2F1"
                    />
                    <path
                        d="M82.9739 103.557H66.6696C61.746 103.557 57.791 99.6015 57.791 94.678C57.791 89.7544 61.746 85.7994 66.6696 85.7994H82.9739C90.0767 85.7994 95.8074 80.0687 95.8074 72.9658V58.9215C95.8074 53.998 99.7624 50.043 104.686 50.043C109.61 50.043 113.565 53.998 113.565 58.9215V72.9658C113.565 89.8351 99.8432 103.557 82.9739 103.557Z"
                        fill="#E1DFDD"
                    />
                    <path
                        d="M61.8271 126.964H48.0249C33.0121 126.964 20.8242 114.776 20.8242 99.7629V87.8979C20.8242 82.9743 24.7792 79.0193 29.7028 79.0193C34.6264 79.0193 38.5814 82.9743 38.5814 87.8979V99.7629C38.5814 105.009 42.8592 109.206 48.0249 109.206H61.8271C66.7506 109.206 70.7056 113.161 70.7056 118.085C70.7056 123.009 66.6699 126.964 61.8271 126.964Z"
                        fill="#E1DFDD"
                    />
                    <path
                        d="M66.6699 186.692C60.6163 186.692 55.7734 181.849 55.7734 175.796V39.9536C55.7734 33.9 60.6163 29.0571 66.6699 29.0571C72.7234 29.0571 77.5663 33.9 77.5663 39.9536V175.796C77.5663 181.849 72.6427 186.692 66.6699 186.692Z"
                        fill="#E1DFDD"
                    />
                    <g opacity="0.5">
                        <path
                            opacity="0.5"
                            d="M77.5663 39.9536V64.3293C69.737 65.7821 62.3113 68.6071 55.7734 72.6428V39.9536C55.7734 33.9 60.6163 29.0571 66.6699 29.0571C72.6427 29.0571 77.5663 33.9807 77.5663 39.9536Z"
                            fill="#F3F2F1"
                        />
                    </g>
                    <path
                        d="M180.477 184.755H134.47C135.923 183.544 136.811 181.768 136.811 179.751C136.811 176.199 133.905 173.293 130.354 173.293H20.8243C17.2729 173.293 14.3672 176.199 14.3672 179.751C14.3672 183.302 17.2729 186.208 20.8243 186.208H51.4958C50.0429 187.338 49.0743 188.952 49.0743 190.808C49.0743 194.198 52.1415 196.862 56.0158 196.862H180.477C184.271 196.862 187.419 194.118 187.419 190.808C187.419 187.499 184.351 184.755 180.477 184.755Z"
                        fill="#FFE5B9"
                    />
                    <path
                        d="M133.824 187.499C151.298 187.499 165.464 173.334 165.464 155.859C165.464 138.385 151.298 124.219 133.824 124.219C116.349 124.219 102.184 138.385 102.184 155.859C102.184 173.334 116.349 187.499 133.824 187.499Z"
                        fill="#F3A945"
                    />
                    <path
                        d="M135.762 193.23C135.359 193.23 134.874 192.988 134.713 192.584C134.39 192.019 134.632 191.293 135.197 190.97C135.278 190.97 142.058 187.257 142.462 180.154C142.542 179.509 143.027 178.944 143.753 179.024C144.399 179.105 144.964 179.67 144.883 180.316C144.318 188.871 136.65 192.988 136.327 193.149C136.166 193.149 136.004 193.23 135.762 193.23Z"
                        fill="#FFD300"
                    />
                    <path
                        d="M125.752 191.858C125.51 191.858 125.187 191.777 124.945 191.535C124.461 191.131 124.38 190.324 124.784 189.84C125.026 189.517 130.918 182.334 123.492 172.002C123.089 171.437 123.25 170.711 123.735 170.307C124.3 169.904 125.026 170.065 125.43 170.549C129.869 176.764 129.95 182.011 129.304 185.401C128.577 189.114 126.721 191.293 126.64 191.374C126.398 191.696 126.075 191.858 125.752 191.858Z"
                        fill="#FFD300"
                    />
                    <path
                        d="M134.389 185.481C132.694 185.481 130.434 184.836 127.609 182.576C127.125 182.172 127.044 181.365 127.448 180.881C127.851 180.396 128.659 180.316 129.143 180.719C133.663 184.432 136.004 182.737 136.004 182.737C136.488 182.334 137.295 182.414 137.699 182.899C138.102 183.383 138.021 184.19 137.537 184.594C137.456 184.594 136.407 185.481 134.389 185.481Z"
                        fill="#FFD300"
                    />
                    <path
                        d="M120.668 180.558C120.103 180.558 119.619 180.154 119.457 179.589C119.457 179.589 118.973 177.329 117.116 176.119C115.825 175.231 114.13 175.069 112.031 175.554C111.386 175.715 110.74 175.312 110.579 174.666C110.417 174.02 110.821 173.374 111.466 173.213C114.211 172.567 116.551 172.89 118.489 174.182C121.233 175.957 121.798 179.105 121.879 179.267C121.959 179.912 121.556 180.558 120.91 180.639C120.829 180.558 120.749 180.558 120.668 180.558Z"
                        fill="#FFD300"
                    />
                    <path
                        d="M111.466 185.723C111.062 185.723 110.659 185.562 110.497 185.158C110.336 184.997 107.269 180.316 108.479 175.553C109.771 170.388 109.287 166.917 107.107 164.899C106.623 164.415 106.542 163.688 107.027 163.204C107.511 162.72 108.237 162.639 108.722 163.123C111.708 165.787 112.354 169.984 110.901 176.118C110.013 179.831 112.596 183.786 112.596 183.786C112.999 184.351 112.838 185.078 112.273 185.481C111.869 185.643 111.708 185.723 111.466 185.723Z"
                        fill="#FFD300"
                    />
                    <path
                        d="M135.116 178.863C134.793 178.863 134.47 178.701 134.228 178.459C133.743 177.975 133.824 177.168 134.308 176.764C134.308 176.764 136.891 174.343 138.344 170.307C139.797 166.352 143.268 164.092 144.882 163.446C145.689 163.124 146.254 163.124 146.738 163.366C147.303 163.689 147.546 164.415 147.303 164.98C147.061 165.464 146.577 165.706 146.012 165.626C145.124 165.868 141.896 167.805 140.604 171.195C138.909 175.715 136.084 178.459 135.923 178.54C135.681 178.782 135.358 178.863 135.116 178.863Z"
                        fill="#FFD300"
                    />
                    <path
                        d="M150.775 177.733C150.371 177.733 150.048 177.571 149.806 177.248C149.645 177.006 145.528 171.841 138.99 173.616C138.345 173.778 137.699 173.374 137.538 172.728C137.376 172.083 137.78 171.437 138.425 171.276C146.578 169.096 151.582 175.473 151.743 175.796C152.147 176.361 152.066 177.087 151.501 177.491C151.259 177.652 151.017 177.733 150.775 177.733Z"
                        fill="#FFD300"
                    />
                    <path
                        d="M159.331 183.06C159.008 183.06 158.766 182.979 158.524 182.737C158.282 182.495 152.551 177.249 154.165 171.76C154.327 171.114 155.053 170.711 155.699 170.953C156.344 171.114 156.748 171.841 156.506 172.486C155.295 176.522 160.138 180.961 160.219 180.961C160.703 181.446 160.784 182.172 160.299 182.656C159.977 182.899 159.654 183.06 159.331 183.06Z"
                        fill="#FFD300"
                    />
                    <path
                        d="M159.087 173.051C155.535 173.051 151.984 171.841 149.805 167.966C149.482 167.401 149.724 166.675 150.289 166.352C150.854 166.029 151.58 166.271 151.903 166.836C155.697 173.697 165.463 169.258 165.625 169.258C166.19 169.016 166.916 169.258 167.239 169.823C167.481 170.469 167.239 171.114 166.674 171.437C164.818 172.244 161.993 173.051 159.087 173.051Z"
                        fill="#FFD300"
                    />
                    <path
                        d="M155.698 164.899C155.617 164.899 155.617 164.899 155.537 164.899C155.295 164.899 148.999 164.092 144.64 158.119C143.349 156.344 142.461 154.568 141.654 153.034C139.878 149.564 138.506 146.981 134.228 145.931C133.582 145.77 133.179 145.124 133.34 144.479C133.502 143.833 134.147 143.429 134.793 143.591C140.12 144.882 141.896 148.272 143.752 151.904C144.56 153.438 145.367 155.052 146.577 156.666C150.29 161.751 155.779 162.478 155.779 162.478C156.425 162.559 156.909 163.124 156.828 163.85C156.828 164.496 156.344 164.899 155.698 164.899Z"
                        fill="#FFD300"
                    />
                    <path
                        d="M148.271 155.94C146.496 155.94 144.559 155.536 142.541 154.326C141.976 154.003 141.814 153.196 142.137 152.631C142.46 152.066 143.267 151.904 143.832 152.227C148.836 155.294 154.164 151.662 154.244 151.662C154.809 151.259 155.536 151.42 155.939 151.985C156.343 152.55 156.181 153.276 155.616 153.68C155.374 153.841 152.226 155.94 148.271 155.94Z"
                        fill="#FFD300"
                    />
                    <path
                        d="M167.321 165.142C167.079 165.142 166.756 165.061 166.514 164.819C166.514 164.819 162.963 161.913 161.026 163.527C160.541 163.931 159.734 163.85 159.331 163.366C158.927 162.882 159.008 162.074 159.492 161.671C162.963 158.765 167.886 162.801 168.048 162.962C168.532 163.366 168.613 164.173 168.209 164.657C168.048 164.98 167.725 165.142 167.321 165.142Z"
                        fill="#FFD300"
                    />
                    <path
                        d="M158.442 158.604C157.877 158.604 157.312 158.2 157.231 157.554C157.15 156.909 157.554 156.263 158.2 156.182C166.11 154.891 168.692 148.272 168.692 148.272C168.935 147.626 169.661 147.304 170.226 147.546C170.872 147.788 171.195 148.514 170.952 149.079C170.791 149.402 167.885 157.07 158.522 158.604C158.603 158.604 158.522 158.604 158.442 158.604Z"
                        fill="#FFD300"
                    />
                    <path
                        d="M156.585 148.433C156.262 148.433 155.939 148.272 155.697 148.03C155.213 147.546 155.293 146.819 155.778 146.335C155.858 146.254 159.652 142.541 159.652 137.779C159.652 134.954 158.28 132.291 155.616 129.788C155.132 129.304 155.132 128.578 155.535 128.093C156.02 127.609 156.746 127.609 157.23 128.013C160.378 130.999 161.993 134.228 161.993 137.779C161.993 143.591 157.553 147.868 157.392 148.111C157.15 148.353 156.827 148.433 156.585 148.433Z"
                        fill="#FFD300"
                    />
                    <path
                        d="M156.586 139.232C153.922 139.232 150.452 138.829 148.272 136.811C147.788 136.326 147.788 135.6 148.192 135.116C148.676 134.631 149.402 134.631 149.887 135.035C152.147 137.134 157.635 136.891 159.492 136.569C160.137 136.488 160.783 136.891 160.864 137.618C160.945 138.264 160.541 138.909 159.815 138.99C159.815 139.071 158.442 139.232 156.586 139.232Z"
                        fill="#FFD300"
                    />
                    <path
                        d="M146.9 149.564C146.577 149.564 146.254 149.402 146.012 149.16C145.527 148.676 145.527 147.949 146.012 147.465C148.191 145.366 147.222 141.815 147.222 141.734C147.061 141.089 147.384 140.443 148.03 140.281C148.675 140.12 149.321 140.443 149.482 141.089C149.563 141.331 150.855 146.093 147.626 149.16C147.545 149.483 147.222 149.564 146.9 149.564Z"
                        fill="#FFD300"
                    />
                    <path
                        d="M131.644 172.567C131.563 172.567 131.483 172.567 131.402 172.567C130.756 172.406 130.353 171.76 130.433 171.114C130.433 171.114 130.837 168.854 129.626 167.482C128.819 166.594 127.286 166.191 125.187 166.433C124.541 166.514 123.896 166.029 123.896 165.383C123.815 164.738 124.299 164.092 124.945 164.092C127.851 163.769 130.111 164.415 131.483 165.949C133.501 168.209 132.855 171.518 132.855 171.679C132.774 172.164 132.209 172.567 131.644 172.567Z"
                        fill="#FFD300"
                    />
                    <path
                        d="M135.035 157.151C133.744 157.151 132.371 156.747 131.08 155.617L130.838 155.375C127.206 152.147 125.349 150.613 122.766 151.824C122.201 152.147 121.475 151.904 121.152 151.259C120.829 150.694 121.071 149.967 121.717 149.644C125.834 147.627 128.901 150.371 132.452 153.519L132.694 153.761C135.035 155.779 137.618 153.842 137.779 153.761C138.344 153.357 139.071 153.438 139.474 154.003C139.878 154.568 139.797 155.294 139.232 155.698C138.344 156.424 136.811 157.151 135.035 157.151Z"
                        fill="#FFD300"
                    />
                    <path
                        d="M123.573 159.491C123.331 159.491 123.17 159.411 122.927 159.33C122.362 159.007 122.201 158.281 122.524 157.635C123.573 155.94 127.125 150.451 130.192 152.308C130.757 152.631 130.999 153.357 130.595 154.003C130.272 154.568 129.546 154.81 128.9 154.406C128.335 154.084 126.156 156.101 124.461 158.926C124.38 159.249 123.977 159.491 123.573 159.491Z"
                        fill="#FFD300"
                    />
                    <path
                        d="M119.78 167.402C119.619 167.402 119.377 167.321 119.215 167.24C118.812 167.079 110.337 162.559 112.678 156.586C113.081 155.537 113.485 154.649 113.888 153.922C115.099 151.259 115.503 150.452 114.05 147.788C113.727 147.223 113.969 146.497 114.534 146.174C115.099 145.851 115.825 146.093 116.148 146.658C118.166 150.452 117.359 152.147 116.068 154.972C115.745 155.698 115.341 156.505 114.938 157.554C113.404 161.429 120.265 165.061 120.265 165.142C120.83 165.464 121.072 166.191 120.83 166.756C120.588 167.159 120.184 167.402 119.78 167.402Z"
                        fill="#FFD300"
                    />
                    <path
                        d="M99.1174 165.303C99.0367 165.303 98.956 165.303 98.8753 165.303C98.2296 165.141 97.826 164.496 97.9067 163.85C97.9874 163.608 99.1981 157.877 103.88 155.536C106.785 154.083 110.256 154.245 114.211 156.021C114.857 156.263 115.099 156.989 114.857 157.635C114.615 158.281 113.888 158.523 113.242 158.281C109.933 156.828 107.189 156.666 105.01 157.796C101.377 159.653 100.247 164.415 100.247 164.496C100.167 164.899 99.6824 165.303 99.1174 165.303Z"
                        fill="#FFD300"
                    />
                    <path
                        d="M106.381 150.855C105.735 150.855 105.251 150.371 105.17 149.725C105.009 146.577 108.156 144.479 110.981 142.542C112.111 141.815 113.161 141.089 113.968 140.362C114.775 139.636 115.098 138.829 115.098 137.86C115.017 136.811 114.452 135.923 114.21 135.762C113.564 135.519 113.322 134.874 113.564 134.228C113.806 133.582 114.533 133.34 115.179 133.502C116.228 133.905 117.196 135.439 117.519 136.972C117.842 138.909 117.196 140.766 115.663 142.057C114.775 142.864 113.564 143.672 112.434 144.398C110.094 146.012 107.591 147.627 107.672 149.402C107.591 150.209 107.107 150.774 106.381 150.855Z"
                        fill="#FFD300"
                    />
                    <path
                        d="M111.063 145.124C110.498 145.124 110.013 144.721 109.852 144.156C109.852 144.075 108.803 139.555 105.816 139.232C102.184 138.829 101.538 141.25 101.538 141.25C101.377 141.896 100.731 142.299 100.086 142.138C99.4398 141.976 99.0363 141.331 99.1977 140.685C99.6013 139.151 101.538 136.407 106.058 136.891C110.901 137.376 112.193 143.51 112.273 143.752C112.435 144.398 111.951 145.044 111.305 145.205C111.224 145.124 111.143 145.124 111.063 145.124Z"
                        fill="#FFD300"
                    />
                    <path
                        d="M140.04 133.501C139.798 133.501 139.475 133.421 139.314 133.259C132.211 127.448 123.897 132.371 123.574 132.533C123.009 132.856 122.283 132.694 121.879 132.129C121.557 131.564 121.718 130.838 122.283 130.434C122.364 130.354 132.372 124.461 140.767 131.322C141.251 131.726 141.332 132.533 140.928 133.017C140.686 133.34 140.363 133.501 140.04 133.501Z"
                        fill="#FFD300"
                    />
                    <path
                        d="M132.29 130.112C131.886 130.112 131.483 129.869 131.241 129.547C128.416 124.865 130.353 121.072 131.806 119.699C132.29 119.215 133.097 119.296 133.501 119.78C133.985 120.264 133.904 120.991 133.42 121.475C133.259 121.556 130.756 124.139 133.259 128.255C133.581 128.82 133.42 129.547 132.855 129.95C132.694 130.112 132.451 130.112 132.29 130.112Z"
                        fill="#FFD300"
                    />
                    <path
                        d="M123.332 145.205C122.606 145.205 122.121 145.124 122.041 145.124C121.395 145.044 120.911 144.479 120.991 143.752C121.072 143.026 121.637 142.622 122.363 142.703C122.363 142.703 126.399 143.107 128.74 141.25C129.708 140.443 130.273 139.394 130.354 137.941C130.435 137.295 131 136.811 131.646 136.811C132.291 136.892 132.776 137.457 132.776 138.102C132.614 140.201 131.726 141.896 130.273 143.107C128.094 144.882 125.027 145.205 123.332 145.205Z"
                        fill="#FFD300"
                    />
                    <path
                        d="M137.859 140.281C136.568 140.281 135.438 139.878 134.55 139.313C130.918 137.053 125.106 139.716 125.026 139.716C124.38 140.039 123.734 139.716 123.411 139.151C123.089 138.506 123.411 137.86 123.976 137.537C124.299 137.376 130.999 134.309 135.761 137.214C137.698 138.425 139.716 137.86 141.976 135.519C142.218 135.277 142.299 135.116 142.541 135.035C143.106 134.712 143.832 134.954 144.155 135.519C144.397 136.084 144.316 136.649 143.832 137.053C143.832 137.053 143.751 137.134 143.751 137.214C141.572 139.474 139.554 140.281 137.859 140.281Z"
                        fill="#FFD300"
                    />
                    <path
                        d="M147.143 132.613C146.901 132.613 146.659 132.533 146.417 132.371C145.609 131.806 138.83 126.318 139.314 121.313C139.395 120.668 139.959 120.183 140.605 120.264C141.251 120.345 141.735 120.91 141.654 121.556C141.332 124.703 146.013 129.062 147.789 130.434C148.354 130.838 148.434 131.564 148.031 132.129C147.789 132.452 147.466 132.613 147.143 132.613Z"
                        fill="#FFD300"
                    />
                    <path
                        d="M137.053 166.352C136.972 166.352 136.972 166.352 137.053 166.352C136.326 166.352 135.842 165.787 135.842 165.141L136.003 160.621C136.003 159.976 136.568 159.411 137.214 159.411C137.86 159.411 138.425 159.976 138.425 160.621L138.263 165.141C138.183 165.868 137.698 166.352 137.053 166.352Z"
                        fill="#FFD300"
                    />
                    <path
                        d="M118.328 134.228C117.924 134.228 117.52 134.066 117.278 133.663C116.955 133.179 114.13 128.739 115.18 125.753C115.583 124.704 116.39 123.896 117.52 123.493C118.166 123.251 118.812 123.654 119.054 124.219C119.296 124.865 118.893 125.511 118.328 125.753C117.763 125.914 117.601 126.237 117.44 126.479C116.955 127.932 118.489 131.08 119.296 132.291C119.7 132.856 119.538 133.582 118.973 133.986C118.812 134.147 118.57 134.228 118.328 134.228Z"
                        fill="#FFD300"
                    />
                    <path
                        d="M101.7 174.504C101.458 174.504 101.216 174.424 101.054 174.343C100.489 174.02 100.328 173.213 100.651 172.648L103.557 168.047C103.879 167.482 104.687 167.321 105.252 167.644C105.817 167.966 105.978 168.774 105.655 169.339L102.749 173.939C102.507 174.343 102.104 174.504 101.7 174.504Z"
                        fill="#FFD300"
                    />
                    <path
                        d="M150.289 188.871C150.208 188.871 150.208 188.871 150.289 188.871C149.563 188.791 149.078 188.226 149.078 187.58C149.159 185.562 149.24 183.141 149.159 182.253C149.159 182.253 149.159 182.253 149.078 182.172C148.675 181.688 148.675 180.881 149.159 180.477C149.563 180.154 150.128 180.074 150.612 180.235C151.258 180.558 151.903 180.881 151.419 187.741C151.419 188.387 150.935 188.871 150.289 188.871Z"
                        fill="#FFD300"
                    />
                    <path
                        d="M210.826 159.734C210.261 159.734 209.777 159.411 209.696 158.846C208.97 156.424 207.84 154.084 206.306 152.066C205.903 151.501 205.983 150.774 206.548 150.371C207.113 149.967 207.84 150.048 208.243 150.613C210.019 152.873 211.23 155.456 212.037 158.2C212.198 158.846 211.876 159.491 211.23 159.653C211.068 159.734 210.907 159.734 210.826 159.734ZM201.221 147.223C200.979 147.223 200.818 147.142 200.576 147.061C198.396 145.77 195.975 144.882 193.473 144.479C192.827 144.398 192.343 143.752 192.504 143.106C192.585 142.461 193.231 141.976 193.876 142.138C196.701 142.622 199.365 143.591 201.867 145.044C202.432 145.366 202.593 146.093 202.271 146.739C202.109 146.981 201.706 147.223 201.221 147.223ZM185.643 144.236H177.572C176.926 144.236 176.361 143.671 176.361 143.026C176.361 142.38 176.926 141.815 177.572 141.815H185.643C186.289 141.815 186.854 142.38 186.854 143.026C186.854 143.671 186.37 144.236 185.643 144.236Z"
                        fill="#C8C6C4"
                    />
                    <path
                        d="M187.338 137.537H185.643C184.997 137.537 184.432 136.972 184.432 136.326C184.432 135.681 184.997 135.116 185.643 135.116H187.338C187.983 135.116 188.548 135.681 188.548 136.326C188.548 136.972 187.983 137.537 187.338 137.537ZM177.571 137.537H169.5C168.854 137.537 168.289 136.972 168.289 136.326C168.289 135.681 168.854 135.116 169.5 135.116H177.571C178.217 135.116 178.782 135.681 178.782 136.326C178.782 136.972 178.298 137.537 177.571 137.537Z"
                        fill="#C8C6C4"
                    />
                    <g opacity="0.5">
                        <path
                            opacity="0.5"
                            d="M113.566 58.9215V68.0423C107.996 65.7823 102.024 64.2487 95.8086 63.603V58.9215C95.8086 53.998 99.7636 50.043 104.687 50.043C109.611 50.043 113.566 54.0787 113.566 58.9215Z"
                            fill="#F3F2F1"
                        />
                    </g>
                    <g opacity="0.5">
                        <path
                            opacity="0.5"
                            d="M38.5013 87.817V87.9785C32.2863 95.8885 27.9277 105.413 25.9906 115.744C22.6813 111.224 20.7441 105.736 20.7441 99.682V87.817C20.7441 82.8935 24.6991 78.9385 29.6227 78.9385C34.5463 78.9385 38.5013 82.9742 38.5013 87.817Z"
                            fill="#F3F2F1"
                        />
                    </g>
                    <path
                        d="M210.826 159.734C210.261 159.734 209.777 159.411 209.696 158.846C208.97 156.424 207.84 154.084 206.306 152.066C205.903 151.501 205.983 150.774 206.548 150.371C207.113 149.967 207.84 150.048 208.243 150.613C210.019 152.873 211.23 155.456 212.037 158.2C212.198 158.846 211.876 159.491 211.23 159.653C211.068 159.734 210.907 159.734 210.826 159.734ZM201.221 147.223C200.979 147.223 200.818 147.142 200.576 147.061C198.396 145.77 195.975 144.882 193.473 144.479C192.827 144.398 192.343 143.752 192.504 143.106C192.585 142.461 193.231 141.976 193.876 142.138C196.701 142.622 199.365 143.591 201.867 145.044C202.432 145.366 202.593 146.093 202.271 146.739C202.109 146.981 201.706 147.223 201.221 147.223ZM185.643 144.236H177.572C176.926 144.236 176.361 143.671 176.361 143.026C176.361 142.38 176.926 141.815 177.572 141.815H185.643C186.289 141.815 186.854 142.38 186.854 143.026C186.854 143.671 186.37 144.236 185.643 144.236Z"
                        fill="#C8C6C4"
                    />
                </svg>
            ),
            'successful-send-svg': (
                <svg width="117" height="118" viewBox="0 0 117 118" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M94.1118 51.1285C97.2562 51.1285 100.254 51.8597 102.887 53.1029L95.2087 7.32661C94.7699 4.84036 92.4299 3.15848 89.9437 3.59723L11.1149 16.7597C8.62866 17.1985 6.94678 19.5385 7.38553 22.0247L15.3562 69.8485C15.7949 72.3347 18.1349 74.0166 20.6212 73.5779L74.8799 64.5104C77.8049 56.686 85.2637 51.1285 94.1118 51.1285Z"
                        fill="url(#paint0_radial_1438_111327)"
                    />
                    <path
                        d="M94.1117 92.0785C82.7774 92.0785 73.6367 82.9379 73.6367 71.6035C73.6367 69.1904 74.0755 66.9235 74.8067 64.8029L43.0705 57.3441C40.6574 56.7591 38.2442 58.2947 37.6592 60.7079L29.4692 95.5885C28.8842 98.0016 30.4199 100.415 32.833 101L91.5524 114.747C93.9655 115.332 96.3786 113.797 96.9636 111.383L101.863 90.4697C99.4499 91.5666 96.8174 92.0785 94.1117 92.0785Z"
                        fill="url(#paint1_radial_1438_111327)"
                    />
                    <path
                        d="M57.2567 47.6185L93.4536 4.40161C92.503 3.67036 91.2598 3.37786 90.0167 3.59724L11.1148 16.7597C9.8717 16.9791 8.84795 17.6372 8.1167 18.661L57.2567 47.6185Z"
                        fill="#FFE5B9"
                    />
                    <path
                        d="M73.6369 71.6035C73.6369 69.1904 74.0757 66.9235 74.8069 64.8029L43.0707 57.3441C41.8275 57.0516 40.6575 57.271 39.6338 57.9291L65.9588 93.1754L79.56 86.0822C75.9038 82.426 73.6369 77.3072 73.6369 71.6035Z"
                        fill="#E1DFDD"
                    />
                    <path
                        d="M94.1117 87.8372C103.077 87.8372 110.345 80.5691 110.345 71.6035C110.345 62.6378 103.077 55.3697 94.1117 55.3697C85.146 55.3697 77.8779 62.6378 77.8779 71.6035C77.8779 80.5691 85.146 87.8372 94.1117 87.8372Z"
                        fill="#3E8EED"
                    />
                    <path
                        d="M92.7221 79.3547L85.6289 73.5047C84.8246 72.8466 84.7514 71.6766 85.4096 70.9454C86.0677 70.141 87.2377 70.0679 87.9689 70.726L92.2833 74.3091L99.9614 65.3147C100.62 64.5104 101.79 64.4372 102.521 65.0954C103.325 65.7535 103.398 66.9235 102.74 67.6547L92.7221 79.3547Z"
                        fill="white"
                    />
                    <defs>
                        <radialGradient
                            id="paint0_radial_1438_111327"
                            cx="0"
                            cy="0"
                            r="1"
                            gradientUnits="userSpaceOnUse"
                            gradientTransform="translate(48.268 15.424) scale(58.6173 58.6173)"
                        >
                            <stop offset="0.4613" stop-color="#FFD590" />
                            <stop offset="0.8485" stop-color="#FFE5B9" />
                        </radialGradient>
                        <radialGradient
                            id="paint1_radial_1438_111327"
                            cx="0"
                            cy="0"
                            r="1"
                            gradientUnits="userSpaceOnUse"
                            gradientTransform="translate(74.0766 62.7227) scale(58.1502 58.1502)"
                        >
                            <stop offset="0.3536" stop-color="#C7C5C3" />
                            <stop offset="0.7103" stop-color="#E1DFDD" />
                        </radialGradient>
                    </defs>
                </svg>
            ),
        },
    });
};

export default setupSvg;

import { createBrowserRouter, Navigate } from 'react-router-dom';

import { QueryClient } from '@tanstack/react-query';
import { ProtectedRoute } from '../components/protected-route';
import { AppLayout, appLayoutLoader } from '../features/app/components/app-layout';
import { Root } from './routes';
import { usersLoader } from './routes/admin/users';
import { detailLoader } from './routes/detail';
import { FormLayoutErrorElement, formLoader } from './routes/form';
import { homeLoader } from './routes/home';
import { errorLogsLoader } from './routes/dev/error-logs';
import { emailLogsLoader } from './routes/dev/email-logs';
import ErrorPage from '@/components/error-page/error-page';

export const createRouter = (queryClient: QueryClient) =>
    createBrowserRouter(
        [
            {
                path: '/',
                element: <Root />,
                errorElement: <ErrorPage title={''} message={''} page={'Application'} />,
                children: [
                    {
                        path: '',
                        element: (
                            <ProtectedRoute>
                                <AppLayout />
                            </ProtectedRoute>
                        ),
                        loader: appLayoutLoader(queryClient),
                        children: [
                            {
                                index: true,
                                lazy: async () => {
                                    const { HomeRoute } = await import('./routes/home');
                                    return { Component: HomeRoute };
                                },
                                // loader: homeLoader(queryClient),
                            },
                            {
                                path: 'detail/:wellCode',
                                lazy: async () => {
                                    const { DetailRoute } = await import('./routes/detail');
                                    return { Component: DetailRoute };
                                },
                                loader: detailLoader(queryClient),
                            },
                            {
                                path: 'form/:wellCode',
                                lazy: async () => {
                                    const { FormLayoutRoute } = await import('./routes/form');
                                    return { Component: FormLayoutRoute };
                                },
                                loader: formLoader(queryClient),
                                errorElement: <FormLayoutErrorElement />,
                            },
                            {
                                path: 'admin',
                                lazy: async () => {
                                    const { AdminRoute } = await import('./routes/admin');
                                    return { Component: AdminRoute };
                                },
                                children: [
                                    {
                                        path: '',
                                        lazy: async () => {
                                            const { UsersRoute } = await import('./routes/admin/users');
                                            return { Component: UsersRoute };
                                        },
                                    },
                                    {
                                        path: 'users',
                                        lazy: async () => {
                                            const { UsersRoute } = await import('./routes/admin/users');
                                            return { Component: UsersRoute };
                                        },
                                    },
                                    {
                                        path: 'areas',
                                        lazy: async () => {
                                            const { AreasRoute } = await import('./routes/admin/areas');
                                            return { Component: AreasRoute };
                                        },
                                    },
                                    {
                                        path: 'recipients',
                                        lazy: async () => {
                                            const { RecipientsRoute } = await import('./routes/admin/recipients');
                                            return { Component: RecipientsRoute };
                                        },
                                    },
                                    {
                                        path: 'data-owners',
                                        lazy: async () => {
                                            const { DataOwnersRoute } = await import('./routes/admin/data-owners');
                                            return { Component: DataOwnersRoute };
                                        },
                                    },
                                ],
                            },
                            {
                                path: 'dev',
                                lazy: async () => {
                                    const { DevRoute } = await import('./routes/dev');
                                    return { Component: DevRoute };
                                },
                                children: [
                                    {
                                        index: true,
                                        element: <Navigate to={'error-logs'} replace />,
                                    },
                                    {
                                        path: 'error-logs',
                                        lazy: async () => {
                                            const { ErrorLogsRoute } = await import('./routes/dev/error-logs');
                                            return { Component: ErrorLogsRoute };
                                        },
                                        loader: errorLogsLoader(queryClient),
                                    },
                                    {
                                        path: 'email-logs',
                                        lazy: async () => {
                                            const { EmailLogsRoute } = await import('./routes/dev/email-logs');
                                            return { Component: EmailLogsRoute };
                                        },
                                        loader: emailLogsLoader(queryClient),
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        path: 'login',
                        lazy: async () => {
                            const { LoginRoute } = await import('./routes/login');
                            return { Component: LoginRoute };
                        },
                    },

                    {
                        path: 'unauthorized',
                        lazy: async () => {
                            const { UnauthorizedRoute } = await import('./routes/unauthorized');
                            return { Component: UnauthorizedRoute };
                        },
                    },
                    {
                        path: '*',
                        lazy: async () => {
                            const { NotFoundRoute } = await import('./routes/not-found');
                            return { Component: NotFoundRoute };
                        },
                    },
                ],
            },
        ],
        { basename: '/spud-letter' },
    );
